import { DRAWER_WIDTH } from '../configs';

const AppBarStyles = (isClient: any) => ({
  width: { md: isClient ? '100%' : `calc(100% - ${DRAWER_WIDTH}px)`, },
  ml: { md: `${DRAWER_WIDTH}px`, },
  backgroundColor: '#fff',
  boxShadow: '0px 2px 4px rgba(65, 64, 69, 0.2)'
});

const NavLinkStyles = ({ isActive }: any) => ({
  width: '100%',
  boxShadow: isActive ? '2px 0 0 darkblue inset' : undefined,
  paddingLeft: '1rem',
  color: isActive ? 'darkblue' : 'black',
  textDecoration: 'none',
  backgroundColor: isActive ? '#daeaf7' : undefined,
});

const LogoutButtonStyles = {
  ml: 2
};

const ChildrenContainerStyles = {
  flexGrow: 1,
  mt: 5,
  p: 3,
  width: { lg: `calc(100% - ${DRAWER_WIDTH}px)`, },
  backgroundColor: '#f3f5f9',
  minHeight: '100vh',
  height: '100%'
};

const NavBarMenuTypographyStyles = {
  color: 'rgba(0, 0, 0, 0.37)',
  pl: 1
};

const NavBarArrowStyles = {
  color: 'rgba(0, 0, 0, 0.54)'
};

const MobileDrawerStyles = (isOpen: boolean) => ({
  width: isOpen ? DRAWER_WIDTH : 0,
  flexShrink: { md: 0 },
});

const DrawerHeader = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
};

const ToolbarStyle = {
  display: 'flex',
  justifyContent: 'space-between'
};

export {
  NavLinkStyles,
  AppBarStyles,
  LogoutButtonStyles,
  ChildrenContainerStyles,
  NavBarMenuTypographyStyles,
  NavBarArrowStyles,
  MobileDrawerStyles,
  DrawerHeader,
  ToolbarStyle
};
