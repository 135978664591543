import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableMUI from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Clear as ClearIcon, Done as DoneIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';

// arow label
import TableSortLabel from '@mui/material/TableSortLabel';
// pagination
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';

import Paper from '@mui/material/Paper';

import { mapColumnSettingKey } from 'utils';
import { UserState } from 'enums/user-state.enum';
import TableMenuButton from './TableMenuButton';
import { ORDER_BY } from './utils/constants';

function Table({
  rows,
  page,
  totalRowsCount,
  limit,
  sortBy,
  onSortFieldChange,
  onPageChange,
  setOrder,
  tableColumnsSettings,
  order,
  menuItemsSettings,
  setActiveRowId,
  whiteSpace,
}: any) {
  const [activeSortLabel, setActiveSortLabel] = useState(sortBy);
  const { t } = useTranslation(['common']);

  const onSortingTableClick = (key: any) => {
    setActiveSortLabel(key);

    onSortFieldChange(key);
  };

  const handleChangePage = (event: any, newPage: any) => {
    onPageChange(newPage);
  };

  const getCellValue = (row: any, columnSetting: any) => {
    if ((columnSetting.key === 'createdAt' ||
        columnSetting.key === 'workStarted' ||
        columnSetting.key === 'signUpDate') &&
        row[columnSetting.key]) {
      return new Date(row[columnSetting.key]).toLocaleDateString();
    }

    if (columnSetting.key === 'state') {
      return row[columnSetting.key] === UserState.Active ? <DoneIcon /> : <ClearIcon />;
    }

    return mapColumnSettingKey(columnSetting.key, row);
  };

  return (
    <TableContainer component={Paper}>
      <TableMUI sx={{ minWidth: 650, width: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableColumnsSettings?.map((columnSettings: any) => (
              <TableCell
                data-testid={columnSettings.key}
                align="left"
                key={columnSettings.key}
                sx={{ padding: '16px 8px' }}
              >
                {t(columnSettings.name)}
                {columnSettings.isSortable && (
                  <TableSortLabel
                    active
                    direction={
                      activeSortLabel === columnSettings.key &&
                      order === ORDER_BY.ASC
                        ? 'asc'
                        : 'desc'
                    }
                    onClick={() => {
                      onSortingTableClick(columnSettings.key);
                      if (activeSortLabel === columnSettings.key) {
                        setOrder();
                      }
                    }}
                  />
                )}
              </TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length ? (
            rows.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {tableColumnsSettings.map((columnSetting: any) => columnSetting.key !== 'menu' ? (
                  <TableCell
                    sx={{
                      overflow: 'auto',
                      padding: '16px 8px',
                      maxWidth: '400px',
                      whiteSpace,
                      textAlign: columnSetting.key === 'state' ? 'center' : null
                    }}
                    key={`${columnSetting.key}${row.id}`}
                    align="left"
                  >
                    {getCellValue(row, columnSetting)}
                  </TableCell>
                ) : (
                  <TableCell
                    align="left"
                    key={`${columnSetting.key}${row.id}`}
                    sx={{ padding: '16px 8px' }}
                  >
                    <TableMenuButton
                      menuItemsSettings={menuItemsSettings}
                      id={row.id}
                      role={row.role}
                      type={row.type}
                      state={row.state}
                      clientUserId={row.clientUserId || row.userId}
                      consultantUserId={row.consultantUserId}
                      setActiveRowId={setActiveRowId}
                    >
                      Меню
                    </TableMenuButton>
                  </TableCell>
                )
                )}
              </TableRow>))
          ) : (
            <Typography sx={{ margin: '10px' }}>
              За вашим запитом нічого не знайдено.
            </Typography>
          )}
        </TableBody>
        {!!limit && rows?.length && totalRowsCount > limit ? (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={8}
                count={totalRowsCount}
                page={page}
                rowsPerPage={limit}
                // ------
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        ) : (
          ''
        )}
      </TableMUI>
    </TableContainer>
  );
}

Table.propTypes = {
  rows: PropTypes.instanceOf(Array),
  tableColumnsSettings: PropTypes.instanceOf(Array).isRequired,
  page: PropTypes.number,
  totalRowsCount: PropTypes.number,
  limit: PropTypes.number,
  onPageChange: PropTypes.elementType.isRequired,
  onSortFieldChange: PropTypes.elementType.isRequired,
  setOrder: PropTypes.elementType.isRequired,
  sortBy: PropTypes.oneOf(['createdAt', 'userRole', 'signUpDate', 'type'])
    .isRequired,
  menuItemsSettings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      callback: PropTypes.func,
    })
  ).isRequired,
  order: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  setActiveRowId: PropTypes.func,
  whiteSpace: PropTypes.string,
};

Table.defaultProps = {
  rows: [],
  page: 0,
  totalRowsCount: 0,
  limit: 10,
  setActiveRowId: () => {},
  whiteSpace: 'noWrap',
};

export default Table;
