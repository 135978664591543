import React, { useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import { clearConsultantDetails, getConsultantsList } from 'feature/consultants/consultantsSlice';
import { getConsultantsListSelector } from 'feature/consultants/consultantsSelector';
import Grid from '@mui/material/Grid';
import Spinner from 'components/shared/Spinner';
import Typography from '@mui/material/Typography';
import BreadcrumbsComponent from 'components/Breadcrumbs/Breadcrumbs';
import { getUserFromAuthStore } from 'feature/auth/authSelectors';
import { getUserByIdAndRole } from 'feature/user/userSlice';
import { getClientRequests } from 'feature/relations/relationsSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import ExpertCard from '../components/ExpertCard';

const sxStyles = {
 subText: {
   pb: 2,
   fontWeight: 600
 }
};

export default function ExpertList() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common']);

  const consultantsList = useAppSelector(getConsultantsListSelector);
  const authDate = useAppSelector(getUserFromAuthStore);
  const currentUserData: any = useAppSelector((state) => state.user.user);

  useEffect(() => {
    Promise.allSettled([
      dispatch(getConsultantsList()),
      dispatch(getClientRequests())
    ]);
  }, []);

  useEffect(() => {
    if (authDate.id && authDate.role) {
      dispatch(
        getUserByIdAndRole({
          userId: authDate.id,
          userRole: authDate.role,
        })
      );
    }
  }, [authDate]);

  useEffect(() => () => {
    dispatch(clearConsultantDetails());
  }, []);

  const breadcrumbsList = [
    {
      link: '/client/expert-list',
      title: t('consultantList'),
    },
  ];

  return (
    <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
      <Grid item xs={12} sm={9}>
        <BreadcrumbsComponent items={breadcrumbsList} />
      </Grid>

      <Grid item xs={12} sm={9}>
        <Typography sx={{ pb: 2 }} variant="h4">
          {t('consultantList')}
        </Typography>
        {isEmpty(consultantsList) ? (
          <Spinner />
        ) : (
          currentUserData === null ||
          !currentUserData?.consultant ?
            consultantsList.map((consultant: any) => (
              <ExpertCard
                consultant={consultant}
                currentUserData={currentUserData}
                key={consultant.user.id}
              />
            ))
            :
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Typography sx={sxStyles.subText} variant="subtitle1">
                {t('yourSpecialists')}
              </Typography>
              <ExpertCard
                consultant={currentUserData.consultant}
                currentUserData={currentUserData}
              />
              <Typography sx={{ ...sxStyles.subText }} variant="subtitle1">
                {t('restSpecialists')}
              </Typography>
              {consultantsList
                .filter((consultant: any) => consultant.user.id !== currentUserData.consultant.user.id)
                .map((consultant: any) => (
                  <ExpertCard
                    consultant={consultant}
                    currentUserData={currentUserData}
                    key={consultant.user.id}
                  />))}
            </Grid>
          )}
      </Grid>
    </Grid>
  );
}
