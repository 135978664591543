import styled from '@emotion/styled';

export const styles = {
  icon: {
    color: '#83639E'
  },

  bigIcon: {
    fontSize: '36px',
  },

  greyText: {
    color: '#65656A',
    mb: 3
  },

  text: {
    fontWeight: 600,
    display: 'inline-block',
    pl: 2
  },

  bigText: {
    textDecoration: 'uppercase',
    fontWeight: 700,
    fontFamily: 'Roboto, sans-serif'
  },

  gridItem: {
    maxWidth: '25px !important'
  },

  link: {
    textDecoration: 'none',
    display: 'block'
  },

  subBlock: {
    pl: '40px',
    pr: '40px'
  },
  privacyBlock: {
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    p: 2,
    margin: '20px 0',
    fontFamily: 'Roboto',
    lineHeight: '24px'
  },
  registered: {
    backgroundColor: '#f3f5f9',
    minHeight: '100vh',
    display: 'flex'
  },
  registeredSubBox: {
    maxWidth: '760px',
    width: '100%',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    height: '800px',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    gap: '20px',
     img: {
      marginBottom: '50px'
     }
  }
};

export const PurpleBlock = styled.div<{isBigBlock: boolean}>`
  background: rgba(131, 99, 158, .2);
  border-radius: 12px;
  display: grid;
  grid-template-columns: ${({ isBigBlock }) => (isBigBlock ? '25px' : '40px')} auto;
  padding: ${({ isBigBlock }) => (isBigBlock ? '20px 50px 20px 20px' : '20px')};
  text-align: left;
  width: fit-content;
  margin: 10px auto;
  align-items: center;
`;

export const Line = styled.hr`
  background-color: #E5E5E5;
  margin: 30px 0;
`;

export const ListWithoutDots = styled.ul`
  list-style-type: none
`;
