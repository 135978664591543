const headerContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '20px'
};

const tableHeaderContainer = {
  border: '1px dashed grey',
  marginTop: '20px'
};

const addClientBtnContainer = {
  justifyContent: 'end',
  display: 'flex',
  flexDirection: 'column'
};

const tableHeader = {
  xs: { flexDirection: 'column' },
  md: { flexDirection: 'row' },
  border: '1px dashed grey',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  p: '20px'
};

const relationsContainer = {
  xs: { flexDirection: 'column' },
  md: { flexDirection: 'row' },
  mt: '20px',
  pl: '20px'
};

const selectContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '659px',
  p: '20px'
};

const selectStyles = {
  minWidth: '400px'
};

const paginationStyles = {
  border: 'none',
  padding: 0
};

const submitBtnStyles = {
  display: 'flex',
  alignItems: 'center',
  width: '120px'
};

const backBtnContainer = (client: any) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  p: '20px',
  marginTop: client ? '20rem' : '0px'
});

const backBtnStyles = {
  width: '150px'
};

export {
  headerContainer,
  addClientBtnContainer,
  tableHeaderContainer,
  tableHeader,
  relationsContainer,
  selectContainer,
  selectStyles,
  paginationStyles,
  submitBtnStyles,
  backBtnContainer,
  backBtnStyles,
};
