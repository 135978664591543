import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from 'feature/snackbar/snackbarSlice';
import { getNotificationsList } from 'feature/snackbar/snackbarSelectors';
import { useTranslation } from 'react-i18next';

let displayed: any = [];

export default function Notifier() {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const notifications = useSelector(getNotificationsList);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: any) => {
    displayed = [...displayed.filter((key: any) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({
      key,
      message,
      options = {}
    }: any) => {
      // display snackbar using notistack
      enqueueSnackbar(t(message), {
        key,
        ...options,
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}
