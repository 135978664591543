import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { login } from 'feature/auth/authSlice';
import PhoneInput from 'components/shared/PhoneInput';
import formStyles from 'components/forms/Form.styles';
import { getAllAuthUserStore } from 'feature/auth/authSelectors';
import { VALIDATION_ERROR_MESSAGES } from 'configs/validation/errorMessages';
import { useAppDispatch } from 'hooks';

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const { isLoading } = useSelector(getAllAuthUserStore);

  const { t } = useTranslation(['common']);
  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(VALIDATION_ERROR_MESSAGES.phone.required)
      .length(12, t('validationPhoneFormat')),

    password: Yup.string().required(VALIDATION_ERROR_MESSAGES.password.required),
  });
  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
      formError: ''
    },
    validationSchema,
    onSubmit: async (data, actions) => {
      const res = await dispatch(
        login({
          phone: data.phone,
          password: data.password,
        })
      );
      // @ts-ignore
      if (res.error) {
        // In case of Server error we need to show errors for both fields, but only one textHelper
        // 'formError' is separate field for displaying global form errors
        actions.setFieldError('formError', t('validationResponse401'));
      }
    },
  });

  return (
    <form
      style={formStyles.form}
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <Typography component="h1" variant="h5">
        {t('loginGreetings')}!
      </Typography>
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          marginBottom: '1.5rem'
        })}
        component="div"
        variant="body2"
      >
        {t('loginHelperText')}
      </Typography>
      <PhoneInput
        onBlur={formik.handleBlur}
        FormHelperTextProps={{ style: formStyles.helper, }}
        sx={formStyles.input}
        fullWidth
        required
        label={t('phoneNumber')}
        value={formik.values.phone}
        id="phone"
        name="phone"
        onChange={formik.handleChange}
        error={
          (formik.touched.phone && Boolean(formik.errors.phone)) ||
          Boolean(formik.errors.formError)
        }
        helperText={formik.touched.phone && formik.errors.phone}
      />
      <TextField
        autoComplete="off"
        onBlur={formik.handleBlur}
        type={showPassword ? 'text' : 'password'}
        FormHelperTextProps={{ sx: formStyles.helper, }}
        sx={formStyles.input}
        fullWidth
        id="password"
        name="password"
        label="Пароль"
        required
        value={formik.values.password}
        onChange={formik.handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={
          (formik.touched.password && Boolean(formik.errors.password)) ||
          Boolean(formik.errors.formError)
        }
        helperText={formik.touched.password && formik.errors.password}
      />
      {formik.errors.formError && (
        <Typography
          paragraph
          sx={formStyles.formError}
          id="formError"
        >
          {formik.errors.formError}
        </Typography>
      )}

      <Typography align="right" pb={2}>
        <Link href="/restore-password" color="secondary">{t('forgotPassword')}</Link>
      </Typography>

      <Button color="primary" disabled={isLoading} size="large" fullWidth variant="contained" type="submit">
        {t('enter')}
      </Button>

      <Typography align="center" pt={1}>
        {t('noAccount')}
        <Link href="/registration" color="secondary" ml={1}>{t('create')}</Link>
      </Typography>
    </form>
  );
}
