import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    notifications: [] as any[]
  },
  reducers: {
    enqueueSnackbar: {
      reducer: (state, { payload }) => {
        state.notifications.push(payload);
      },
      // @ts-expect-error TS(2322): Type '(notification: any) => { payload: any; }' is... Remove this comment to see the full error message
      prepare(notification) {
        const payload = { ...notification, key: new Date().getTime() + Math.random() };
        return { payload };
      },
    },
    removeSnackbar: (state, { payload }) => {
      state.notifications = state.notifications.filter((notification) => notification.key !== payload);
    },
    closeSnackbar: {
      reducer: (state, action) => {
        const { payload } = action;
        state.notifications = state.notifications.map((notification) => {
          const shouldDismiss = payload.dismissAll || notification.key === payload.key;
          return shouldDismiss ? { ...notification, dismissed: true } : { ...notification };
        });
      },
      // @ts-expect-error TS(2322): Type '(key: any) => { payload: { key: any; dismiss... Remove this comment to see the full error message
      prepare: (key) => ({ payload: { key, dismissAll: !key } })
    },
  }
});
export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
