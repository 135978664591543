export const getConsultantsListSelector = (state: any) => state.consultants.consultantsList;
export const getIsLoadingConsultantSelector = (state: any) => state.consultants.isLoading;

export const getConsultantClientListSelector = (state: any) => {
  const data = {
    newClients: [] as any[],
    oldClients: [] as any[],
  };
  const today = new Date();

  state.consultants.clientList.forEach((client: any) => {
    const consultantStartDate = new Date(client.consultantStartDate);
    const diffTime = Math.abs(consultantStartDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 7) {
      data.newClients.push(client);

      return;
    }

    data.oldClients.push(client);
  });

  return data;
};
