import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getConsultantInfo } from './consultantDetail';

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    consultantDetail: {},
};

// Get consultant detail
export const getConsultantDetails = createAsyncThunk(
  'consultants',
  async (id: string, thunkAPI) => {
    try {
      return await getConsultantInfo(id);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const consultantSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.consultantDetail = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConsultantDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConsultantDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.consultantDetail = action.payload;
      })
      .addCase(getConsultantDetails.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.consultantDetail = {};
      });
  },
});

export const { reset } = consultantSlice.actions;
export default consultantSlice.reducer;
