import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'feature/snackbar/snackbarSlice';
import { UserRequest } from 'models/user';
import { removeClientRequestApi, getClientRequests, processClientRequestApi } from './clientRequestsService';

const initialState = {
  requests: [],
  total: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const getClientRequestsList = createAsyncThunk(
  'requests/get-client-requests',
  async (data: any, thunkAPI) => {
    try {
      return await getClientRequests(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const removeClientRequest = createAsyncThunk(
  'requests/remove-client-request',
  async (id: string, thunkAPI) => {
    try {
      return await removeClientRequestApi(id);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const processClientRequest = createAsyncThunk(
  'requests/process-client-request',
  async (data: {id: number, action: string}, thunkAPI) => {
    try {
      const result = await processClientRequestApi(data);
      if (result) {
        thunkAPI.dispatch(enqueueSnackbar({ message: 'commonSuccess', options: { variant: 'success' } }));
      }
      return result;
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      thunkAPI.dispatch(enqueueSnackbar({ message: error.data.message, options: { variant: 'error' } }));
      // @ts-expect-error TS(2571): Object is of type 'unknown'.

      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const requestsSlice = createSlice({
  name: 'client-requests',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientRequestsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientRequestsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.requests = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(getClientRequestsList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.requests = [];
      })
      .addCase(removeClientRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeClientRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.requests = state.requests.filter(({ userId }) => userId !== action.meta.arg);
      })
      .addCase(processClientRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(processClientRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.requests = (state.requests as any)
          .map((item: UserRequest) =>
            (item.id === action.payload.id ? { ...item, status: action.payload.status } : item));
      })
      .addCase(processClientRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
      });
  },
});

export const { reset } = requestsSlice.actions;
export default requestsSlice.reducer;
