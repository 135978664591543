import React, { useState, useEffect } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { searchClientConsultantList } from 'feature/relations/relationsSlice';
import { getClientConsultantFromRelationsStore } from 'feature/relations/relationsSelectors';
import Table from 'components/Table';
import TableHeaderPanel from 'components/TableHeaderPanel';
import { LIMIT_USER_LIST, ORDER_BY } from 'components/utils/constants';
import { ITEMS_ON_PAGE } from 'configs/index';
import { useAppDispatch } from 'hooks';
import { TableMenuItem } from 'enums/table-menu-item.enum';

const tableColumnsSettings = [
  {
    name: "Ім'я Клієнта/тки",
    key: 'clientName',
    isSortable: true,
  },

  {
    name: "Ім'я Консультанта/тки",
    key: 'consultantName',
    isSortable: true,
  },
  {
    name: 'Дата початку співпраці',
    key: 'workStarted',
    isSortable: true,
  },
  { name: '', key: 'menu' },
];

function ClientConsultantList() {
  const [sortBy, setSortBy] = useState('workStarted');

  const [queryParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const [order, setOrder] = useState(queryParams.get('order') || ORDER_BY.DESC);
  const [offsetSkipUsers, setOffsetSkipUsers] = useState(0);
  const [debouncedSearch, setDebouncedSearch] = useState(
    queryParams.get('search') || ''
  );
  const [searchValue, setSearchValue] = useState(debouncedSearch);
  const [currentPage, setCurrentPage] = useState(
    Number(queryParams.get('currentPage')) || 0
  );
  const navigate = useNavigate();

  const sortFieldHandler = (sortField: any) => setSortBy(sortField);
  const orderHandler = () =>
    setOrder(order === ORDER_BY.ASC ? ORDER_BY.DESC : ORDER_BY.ASC);

  const onPageChangeHandler = (page: any) => {
    setOffsetSkipUsers(LIMIT_USER_LIST * page);
    setCurrentPage(page);
  };

  useEffect(() => {
    dispatch(
      searchClientConsultantList({
        limit: ITEMS_ON_PAGE,
        offset: offsetSkipUsers,
        sortBy,
        order,
        search: debouncedSearch,
      })
    );
    navigate(
      {
        search: `${createSearchParams({
          sortBy,
          order,
          limit: String(LIMIT_USER_LIST),
          offset: String(offsetSkipUsers),
          search: debouncedSearch,
        })}`,
      },
      { replace: true }
    );
  }, [sortBy, order, offsetSkipUsers, debouncedSearch]);

  const menuItemsSettings = [
    {
      label: TableMenuItem.EditConsultant,
      callback: ({
        consultantUserId
      }: any) => {
        navigate(`consultant/${consultantUserId}`);
      },
    },
    {
      label: TableMenuItem.EditClient,
      callback: ({
        clientUserId
      }: any) => {
        navigate(`${clientUserId}`);
      },
    },
  ];

  const clientConsultantList = useSelector(
    getClientConsultantFromRelationsStore
  );
  return (
    <>
      <TableHeaderPanel
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setDebouncedSearch={setDebouncedSearch}
        setPaginationValues={[setCurrentPage, setOffsetSkipUsers]}
        header="Клієнт/ка - Консультант/ка"
        rowsCount={clientConsultantList.total || null}
      />
      <Table
        rows={clientConsultantList.data}
        tableColumnsSettings={tableColumnsSettings}
        page={currentPage}
        menuItemsSettings={menuItemsSettings}
        totalRowsCount={clientConsultantList.total}
        limit={LIMIT_USER_LIST}
        onPageChange={(page: any) => onPageChangeHandler(page)}
        onSortFieldChange={sortFieldHandler}
        order={order}
        setOrder={orderHandler}
        sortBy={sortBy}
      />
    </>
  );
}

export default ClientConsultantList;
