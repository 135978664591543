import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash.isempty';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

import { getClientDetailsSelector } from 'feature/clients/clientsSelector';
import Spinner from 'components/shared/Spinner';
import { GENDER_TYPE, VIOLENCE_TYPE } from 'configs';
import { ROLE_URLS } from 'api/urls';
import userIcon from 'assets/user-icon.svg';
import BreadcrumbsComponent from 'components/Breadcrumbs/Breadcrumbs';
import { getClientData } from 'feature/clients/clientsSlice';
import { useAppDispatch } from 'hooks';
import { ChipStyles, ChipContainerStyles, TitleStyles } from '../../../components/shared/styles';
import {
  ClientMediaStyles,
  ClientProfileImgStyles,
  ClientAboutContentStyles,
  ClientCardContentStyles,
} from './ClientInfo.styles';

export function ClientInfo() {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const { clientId } = useParams();

  const clientDetails = useSelector(getClientDetailsSelector);

  useEffect(() => {
    dispatch(getClientData(clientId as string));
  }, []);

  const breadcrumbsList = [
    {
      link: '/consultant/client-list',
      title: t('clientList'),
      isDisabled: false
    },
    {
      link: '',
      title: t('clientProfile'),
      isDisabled: false
    },
  ];

  return (
    <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
      <Grid item xs={12} sm={8}>
        <BreadcrumbsComponent items={breadcrumbsList} />
      </Grid>
      <Grid item sm={8}>
        <Grid container spacing={2}>
          <Typography sx={{ p: 2 }} variant="h4">
            {t('clientProfile')}
          </Typography>

          {isEmpty(clientDetails) ? (<Spinner />) : (
            <Grid item xs={12}>
              <Card sx={ClientMediaStyles}>
                <Grid item xs={12} md={4}>
                  <CardContent>
                    <Stack spacing={3}>
                      <CardMedia
                        component="img"
                        sx={ClientProfileImgStyles}
                        image={clientDetails.photoId ?
                          // @ts-expect-error TS(2571): Object is of type 'unknown'.
                          `${ROLE_URLS[clientDetails.user.role]}/${clientDetails.user.id}/profile-picture`
                          : userIcon}
                        alt={t('clientPhoto')}
                      />
                    </Stack>
                  </CardContent>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                  >
                    <CardContent sx={ClientCardContentStyles}>
                      <Typography component="h4" variant="h4">
                        {clientDetails.firstName} {clientDetails.lastName}
                      </Typography>
                      <Typography paragraph sx={ClientAboutContentStyles}>
                        {/* @ts-expect-error TS(2571): Object is of type 'unknown'. */}
                        {GENDER_TYPE[clientDetails.gender]}, {new Date(clientDetails.birthday).toLocaleDateString()}
                      </Typography>
                      <Stack spacing={1} direction="column">
                        <Typography
                          sx={TitleStyles}
                          variant="h6"
                          component="h6"
                        >
                          {t('problems')}:
                        </Typography>
                        <Stack spacing={1} direction="row" sx={ChipContainerStyles}>
                          {clientDetails?.violenceTypes &&
                            clientDetails.violenceTypes.map((violenceType: any) => (
                              <Chip
                                key={violenceType}
                                /* @ts-expect-error TS(2571): Object is of type 'unknown'. */
                                label={`${VIOLENCE_TYPE[violenceType]} ${t('violence')}`}
                                color="primary"
                                style={ChipStyles}
                              />
                            ))}
                        </Stack>
                      </Stack>

                      <Stack spacing={1} direction="column">
                        <Typography
                          sx={TitleStyles}
                          variant="h6"
                          component="h6"
                        >
                          {t('consultationStartDate')}:
                        </Typography>
                        <Typography paragraph sx={ClientAboutContentStyles}>
                          {new Date(clientDetails.consultantStartDate).toLocaleDateString()}
                        </Typography>
                      </Stack>

                      <Stack spacing={1} direction="column">
                        <Typography
                          sx={TitleStyles}
                          variant="h6"
                          component="h6"
                        >
                          {t('communicationChannel')}:
                        </Typography>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography paragraph sx={ClientAboutContentStyles}>
                              {t('viber')} ({t('main')}):
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography paragraph sx={ClientAboutContentStyles}>
                              +{clientDetails.user.phone}
                            </Typography>
                            <Typography paragraph sx={ClientAboutContentStyles}>
                              -
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                    </CardContent>
                  </Box>
                </Grid>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
