import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { Drafts, LocalPhone } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { REGISTRATION_SUB_PAGES } from 'configs';
import { getDateWithoutTimezone, hiddenPhoneRegExp } from 'configs/helper';
import ViolenceTypesForm from 'components/forms/ViolenceTypesForm';
import RegistrationForm from 'components/forms/RegistrationForm';
import LoginWrapper from 'components/shared/LoginWrapper';
import formStyles from 'components/forms/Form.styles';
import ConfirmCodeForm from 'components/forms/ConfirmCodeForm';
import logo from 'assets/big-colored-logo.svg';
import skype from 'assets/skype.svg';
import telegram from 'assets/telegram.svg';
import authService from 'feature/auth/authService';
import { Line, PurpleBlock, styles, ListWithoutDots } from './styles';
import { validationSchema } from './helper';

export default function RegistrationPage() {
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();

  const [activeSubPage, setActiveSubPage] = useState(REGISTRATION_SUB_PAGES.preRegistration);
  const [code, setCode] = useState('');
  const [validation, setValidation] = React.useState(validationSchema(t, true, false));
  const [isPrivacyChecked, setPrivacyChecked] = useState(false);
  const [isFormSubmited, setFormSubmited] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      gender: '',
      birthday: '',
      phone: '',
      password: '',
      confirmPassword: '',
      email: '',
      communicationChannel: '',
      verificationCode: '',
      violenceTypes: [],
      other: '',
      file: ''
    },
    validationSchema: validation,
    onSubmit: (data) => {
      const formData = new FormData();
      formData.append('firstName', data.firstName);
      formData.append('birthday', getDateWithoutTimezone(data.birthday).toISOString());
      formData.append('gender', data.gender);
      formData.append('phone', data.phone);
      formData.append('password', data.password);
      formData.append('communicationChannel', data.communicationChannel);
      formData.append('verificationCode', code);
      formData.append('file', data.file);
      if (data.lastName) {
        formData.append('lastName', data.lastName);
      }
      if (data.email) {
        formData.append('email', data.email);
      }

      data.violenceTypes.forEach((violenceType) => formData.append('violenceTypes[]', violenceType));
      Cookies.remove('id');
      Cookies.remove('role');
      Cookies.remove('email');
      Cookies.remove('firstName');
      Cookies.remove('lastName');
      Cookies.remove('externalId');

      authService.signUpClient(formData)
      .then(() => {
        setFormSubmited(true);
      })
      .catch(() => {
        enqueueSnackbar(t('commonError'), { variant: 'error' });
      });
    }
  });

  const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      validateForm
  } = formik;

  return (
    !isFormSubmited ?
      <LoginWrapper>
        <form onSubmit={handleSubmit} style={activeSubPage !== REGISTRATION_SUB_PAGES.confirmCode && activeSubPage !== REGISTRATION_SUB_PAGES.info ? formStyles.form : {}}>
          {activeSubPage === REGISTRATION_SUB_PAGES.preRegistration &&
          <ViolenceTypesForm
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setActiveSubPage={setActiveSubPage}
            validateForm={validateForm}
            setValidation={setValidation}
          />}
          {activeSubPage === REGISTRATION_SUB_PAGES.registration &&
          <RegistrationForm
            values={values}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setActiveSubPage={setActiveSubPage}
            setValidation={setValidation}
            validateForm={validateForm}
          />}
          {activeSubPage === REGISTRATION_SUB_PAGES.privacy &&
          <Box>
            <Typography variant="h5">
              {t('privacyHeader')}
            </Typography>
            <Box sx={styles.privacyBlock}>
              <Typography variant="h6">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ ТА ЗАХИСТУ ПЕРСОНАЛЬНИХ ДАНИХ</Typography>
              <strong>Загальні положення</strong>
              <p>Фонд ООН у галузі народонаселення (UNFPA) як власник та адміністратор онлайн платформи «Аврора» (далі – «Платформа») діє відповідно до вимог чинного законодавства України, зокрема у сфері захисту персональних даних осіб, які відвідали сайт Платформи avrora-help.org.ua, включаючи всі його піддомени, якщо такі є (далі – «сайт»). Ключова мета Платформи та, відповідно, сайту – надання спеціалізованої психотерапевтичної допомоги особам, які зіткнулися з насильством, зокрема з сексуальним, що пов’язане з війною.</p>
              <p>UNFPA з повагою ставиться до персональної інформації осіб, які відвідали cайт, а також тих, хто користується наданими сервісами. UNFPA бере на себе зобов’язання захищати конфіденційність наданої користувачами сайту інформації, тим самим не тільки дотримуючись законодавства, а й прагнучи створити сприятливі та довірливі умови використання сайту кожним(ною) користувачем(чкою).</p>
              <p>Дана Політика конфіденційності та захисту персональних даних (далі – «Політика») встановлює порядок здійснення UNFPA збору, обробки, використання та захист персональних даних, види персональних даних, які збираються, взаємодія UNFPA з третіми особами, заходи безпеки для захисту персональних даних, права та обов’язки сторін.</p>

              <strong>Визначення термінів</strong>
              <ul>
                <li>Персональні дані – відомості чи сукупність відомостей про фізичну особу, яка ідентифікована або може бути конкретно ідентифікована.</li>
                <li>Суб’єкт персональних даних – фізична особа, персональні дані якої обробляються.</li>
                <li>Конфіденційна інформація – інформація про фізичну особу, а також інформація, доступ до якої обмежено фізичною або юридичною особою, крім суб’єктів владних повноважень, у тому числі (але не виключно) дані про звернення користувача(ки) за психотерапевтичною або іншою допомогою, результати консультацій, досліджень, а також інша інформація про психологічний стан користувача(ки).</li>
                <li>Користувач(ка) – особа, яка прийняла умови даної Політики шляхом відповідної реєстрації на сайті (з авторизацією через SMS), володіє індивідуальним логіном та паролем для використання можливостей сайту та усіх послуг, що пропонуються на сайті. Користувач(ка) передає персональні дані та конфіденційну інформацію у користування адміністратору(ам) сайту та координатору(ам) Платформи з метою подальшого отримання послуг, які передбачені сайтом.</li>
                <li>Контрагент/партнер – сторона цивільно-правових договорів з UNFPA, яка співпрацює для реалізації мети Платформи, у тому числі може отримувати конфіденційну інформацію та персональні дані користувачів за умови збереження конфіденційності інформації.</li>
                <li>Файли сookie – невеликі фрагменти тексту, які відвіданий сайт надсилає веб-переглядачу, покликані допомогти сайту запам’ятати потрібну інформацію, щоб користувач(к)ам та відвідувач(к)ам було легше його відвідувати наступного разу, а контент на ньому ставав кориснішим.</li>
              </ul>
              <strong>1. Збір та використання персональних даних</strong>
              <ListWithoutDots>
                <li>1.1 На виконання положень законодавства України про захист персональних даних, користувач(ка) засвідчує, що у разі передачі своїх персональних даних UNFPA у процесі користування сайтом, UNFPA отримує на необмежений строк беззаперечну згоду на обробку та передачу персональних даних, що їй передаються користувачем(кою).</li>
                <li>1.2 UNFPA збирає тільки ті дані, які свідомо і добровільно надані суб’єктами в цілях використання сервісів сайту, що відповідно до вимог законодавства є згодою суб’єкта на обробку своїх персональних даних відповідно до сформульованої в цій Політиці мети їх обробки – надання психотерапевтичної та іншої допомоги.</li>
                <li>1.3 При використанні користувачем(кою) сервісів сайту здійснюється обробка даних, а саме: даних, що надаються користувачем(кою) як при заповненні реєстраційних форм, так і в процесі користування сервісами; файли cookie; IP-адреси; параметри і налаштувань інтернет-браузерів. Зокрема, але не виключно, що адміністратор(ка) та психотерапевт(к)и отримують наступні дані користувачів: справжнє ім’я та прізвище чи псевдонім, вік, стать, номер телефону, будь-яку іншу інформацію, яку користувачі передають UNFPA про сфери свого інтересу та мети користування сайтом, і будь-яку іншу інформацію, яку користувачі передають додатково.</li>
                <li>1.4 UNFPA збирає дані про статистику відвідування cайту; відомості можуть містити інформацію про з’єднання, трафік, браузер користувача(ки), а також про дату, час, тривалість знаходження на сайті.</li>
                <li>1.5 Користувач(ка) повністю усвідомлює та згоден(дна) з метою обробки персональних даних, що відбувається відповідно до законодавства та з тим, що його/її персональні дані можуть бути включені до бази персональних даних UNFPA, а також можуть бути передані афілійованим особам в Україні та за кордоном, а також контрагентам/партнерам у разі, якщо це є необхідним для надання послуг, передбачених сайтом.</li>
                <li>1.6 Персональні дані зберігаються терміном не більше, ніж це необхідно відповідно до мети їх використання. Після того, як суб’єкт персональних даних перестав бути користувачем сайту шляхом видалення свого облікового запису на сайті (шляхом спеціально розробленої процедури) або після реєстрації протягом календарного місяця не виходить на зв&rsquo;язок з адміністратором чи психотерапевтом без поважної на то причини, його/її персональні дані також видаляються, але у UNFPA залишаються деперсоналізовані основні соціально-демографічні дані (включаючи ID номер клієнта(ки) та консультанта(ки)), на основі яких збирається та формується статистика. Обробка і зберігання наданих персональних даних здійснюється в дата-центрах, де розміщується обладнання, що забезпечує функціонування сервісів сайту. Надані персональні дані обробляються і можуть зберігатись в базі персональних даних чи окремій таблиці бази даних сайту.</li>
              </ListWithoutDots>
              <strong>2. Захист персональних даних та конфіденційної інформації</strong>
              <ListWithoutDots>
                <li>2.1 UNFPA використовує загальноприйняті стандарти технічного та операційного захисту інформації та персональних даних від втрати, неправильного використання, несанкціонованого доступу, зміни або знищення. Однак, не дивлячись на зусилля, UNFPA не може гарантувати абсолютну захищеність від будь-яких загроз, що виникають поза межами регулювання UNFPA.</li>
                <li>2.2 З метою охорони конфіденційності інформації всі працівники UNFPA, які мають доступ до персональних даних та конфіденційної інформації, зобов&rsquo;язані не розголошувати цю інформацію без згоди суб’єктів та не використовувати інформацію в особистих та інших цілях, що не пов’язані з метою сайту.</li>
                <li>2.3 Відносини між UNFPA і його контрагентами/партнерами в частині, що стосується нерозголошення конфіденційної інформації, регулюються законодавством, договорами і цією Політикою. У договорах з контрагентами/партнерами UNFPA зазначає пункт про нерозголошення інформації, що становить конфіденційну інформацію.</li>
                <li>2.4 Поширення персональних даних без згоди суб’єкта дозволяється у випадках, визначених законом, в тому числі, якщо це необхідно в інтересах національної безпеки та захисту прав людини.</li>
                <li>2.5 З міркувань безпеки осіб, які не досягли повноліття, UNFPA рекомендує батькам самостійно заопікуватись безпекою дітей в Інтернеті та, за необхідності, пояснювати мету Платформи та потребу у використання тих чи інших сервісів сайту.</li>
              </ListWithoutDots>
              <strong>3. Права та обов’язки користувачів сайту</strong>
              <ListWithoutDots>
                <li>3.1 Користувачі мають право: користуватися всіма технічними можливостями сайту; мати доступ до будь-якої публічної інформації на сайті; використовувати інформацію з сайту в особистих і некомерційних цілях; застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних; на захист від автоматизованого рішення, яке має для нього/нього правові наслідки.</li>
                <li>3.2 Користувачі зобов&rsquo;язуються: при здійсненні реєстрації вказувати достовірні дані (які дозволяють зв&rsquo;язатися з ним/нею); використовувати cайт тільки в цілях, що не суперечать чинному законодавству України та меті сайту; нести відповідальність перед третіми особами за свої дії або бездіяльність при використанні cайту; самостійно і за власний рахунок врегулювати всі претензії третіх осіб, пов’язані з дією або бездіяльністю користувача(чки) в процесі користування сайтом.</li>
                <li>3.3 Користувачам забороняється: вчиняти дії, спрямовані на введення адміністратора(ку) чи психотерапевтів(ок) та інших надавачів послуг в оману; розпалювати релігійні, расові або міжнаціональної ворожнечі; вчиняти дії, що порушують честь і гідність, права і свободи будь-якої особи; реєструватись від імені або замість іншої особи; надавати в користування свій обліковий запис третім особам; використання інформації з сайту в комерційних цілях.</li>
              </ListWithoutDots>
              <strong>4. Права та обов’язки UNFPA</strong>
              <ListWithoutDots>
                <li>4.1 UNFPA має право: встановлювати, змінювати і скасовувати в письмовій формі режим конфіденційної інформації відповідно до чинного законодавства; у разі розміщення користувачами на сайті інформації або здійснення інших дій, які не відповідають положенням чинного законодавства України та даної Політики, без повідомлення та на власний розсуд видалити розміщену інформацію.</li>
                <li>4.2 UNFPA зобов’язується: захищати в установленому чинним законодавством порядку свої права в разі розголошення, незаконного одержання або незаконного використання третіми особами інформації, що є конфіденційною інформацією; вимагати від юридичних і фізичних осіб, які отримали доступ до конфіденційної інформації, а також органів державної влади дотримання обов’язків щодо конфіденційності в межах чинного законодавства; вимагати від осіб, які отримали доступ до інформації в результаті дій, вчинених випадково або помилково охорони конфіденційності цієї інформації.</li>
              </ListWithoutDots>

              <strong>5. Взаємодія сайту з іншими ресурсами та використання файлів cookie</strong>
              <ListWithoutDots>
                <li>5.1 При використанні користувачами сайту на його сторінках можуть бути присутніми коди інших інтернет ресурсів і третіх осіб, в результаті чого такі інтернет ресурси і треті особи отримують дані користувачів. Отже, ці інтернет-ресурси можуть отримувати і обробляти інформацію, про те, що користувач відвідав ці сторінки, а також іншу інформацію, яку передає браузер користувача.</li>
                <li>5.2 Такими інтернет-ресурсами можуть бути: адмін-панель сайту; соціальні плагіни мереж.</li>
                <li>5.3 Використання зазначених сервісів необхідно для оперативного користування сайтом користувачами, для внутрішньої і зовнішньої оцінки відвідуваності сайту, активності користувачів. Дані, отримані від зазначених сервісів, UNFPA не зберігає і не обробляє. Відповідно, якщо користувач(ка) в силу будь-яких причин не бажає, щоб зазначені сервіси отримували доступ до його персональних даних, користувач може за власним бажанням вийти зі свого акаунта чи профілю, очистити файли cookie (через свій браузер).</li>
                <li>5.4 Cookie дозволяють надавати індивідуалізовані послуги. Існує кілька різновидів файлів cookie: тимчасові cookie (або сеансові cookie) стираються, коли браузер закривається; постійні cookie не стираються, але залишаються до тих пір, поки їх навмисно не видаляють або закінчується термін їхньої дії (він залежить від того, на який час сайт програмує тривалість дії cookie).</li>
                <li>5.5 Більшість браузерів автоматично налаштовані приймати файли cookie*. Користувач можете відключити файли cookie на своєму браузері, але пам&rsquo;ятайте, що якщо це зробити, можна втратити багато характеристик, необхідних для кращої роботи (використання) сайту. Якщо користувачу(ці) необхідна більш детальна інформація про файли cookie, будь ласка, відвідайте сайт www.aboutcookies.org, який містить вичерпну і незалежну інформацію про те, як відключити cookie, використовуючи налаштування браузера, і як видалити cookie, що вже знаходяться на комп&rsquo;ютері користувача(ки). Щоб видалити файли cookie зі свого мобільного телефону, користувачу(ці) необхідно проконсультуватися з інструкцією до свого телефонного пристрою.</li>
                <ul>
                  <li>Строго обов’язкові файли cookie – такі файли cookie важливі для того, щоб допомогти користувачу(ці) в навігації по сайту і використанні його можливостей, таких як доступ до конфіденційних зон на веб-сайті. Без цих файлів cookie, запитувані користувачами послуги, оперативна навігація не можуть бути надані.</li>
                  <li>Експлуатаційні файли cookie – такі файли cookie збирають інформацію про те, як користувачі використовують сайт, наприклад, на які сторінки відвідувачі заходять найчастіше, і чи отримують вони на веб-сторінках повідомлення про помилку. Дані файли cookie не збирають інформацію, яка ідентифікує користувача. Вся інформація, яку збирають ці файли cookie, є сумарною, і тому анонімною. Вона використовується виключно для поліпшення роботи сайту.</li>
                  <li>Функціональні файли cookie – такі файли cookie дозволяють сайту запам’ятати зроблений користувачем(кою) вибір (наприклад, ім&rsquo;я, мову чи регіон) і збирають розширені, особисті характеристики. Інформація, яку збирають ці cookie, може бути зроблена анонімною, і вони не можуть відстежити пошукову діяльність користувача на інших сайтах.</li>
                </ul>
                <li>5.6 Приймаючи Умови використання (дану Політику), а також отримання доступу до сайту і використання його, користувач(ка) погоджуєтеся на використання файлів cookie. Користувач(ка) також можете погодитися на використання нами файлів cookie, за допомогою налаштувань, вибраних в своєму браузері.</li>

              </ListWithoutDots>
              <strong>6. Зміна Політики та перенаправлення</strong>
              <ListWithoutDots>
                <li>6.1 UNFPA може вносити зміни та доповнення до даної Політики в односторонньому порядку, у тому числі, але не виключно при зміні вимог законодавства. Користувачам сайту час від часу рекомендується переглядати дану Політику для того, щоб бути обізнаним щодо її актуальної версії.</li>
                <li>6.2 У випадку внесення істотних змін чи доповнень до цієї Політики, буде здійснено інформування про це (шляхом розміщення повідомлення чи/або здійснення електронної розсилки) та буде зазначено термін набрання ними чинності. Продовження користування сайтом автоматично означатиме згоду з цими змінами чи доповненнями.</li>
                <li>6.3 На сайті Платформи можуть бути посилання на інші сайти, при переході на які дія цієї Політики поширюватися не буде. У зв’язку з цим, UNFPA рекомендує переглядати політику конфіденційності і персональних даних кожного сайту перед тим, як передавати будь-які персональні дані.</li>
              </ListWithoutDots>
            </Box>
            <FormControlLabel
              control={<Checkbox />}
              label={t('privacyCheckboxText')}
              value={isPrivacyChecked}
              onChange={() => setPrivacyChecked(!isPrivacyChecked)}
            />
            <Button
              color="primary"
              disabled={!isPrivacyChecked}
              size="large"
              fullWidth
              variant="contained"
              sx={{ mb: 2, mt: 2 }}
              type="submit"
            >
              {t('create')}
            </Button>
          </Box>}
        </form>
        {activeSubPage === REGISTRATION_SUB_PAGES.confirmCode &&
        <Box sx={formStyles.form}>
          <Typography variant="h5">
            {t('confirmCodeTitle')}
          </Typography>
          <Typography
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              mb: 3
            })}
            variant="body2"
          >
            {t('confirmCodeSubTitle1')} {hiddenPhoneRegExp(values.phone)} {
            t('confirmCodeSubTitle2')
          }
          </Typography>
          <ConfirmCodeForm
            setCode={setCode}
            phone={values.phone}
            setActiveSubPage={setActiveSubPage}
          />
        </Box>}
        {activeSubPage === REGISTRATION_SUB_PAGES.info &&
        <Box sx={formStyles.form}>
          <Typography variant="h5">
            {t('results')}
          </Typography>
          <Typography
            sx={styles.greyText}
            variant="body2"
          >
            {t('infoSubTitle')}
          </Typography>
          <Box textAlign="center">
            <Box sx={styles.subBlock}>
              <Typography variant="h5" sx={styles.bigText}>
                {t('hotLine')}
              </Typography>
              <PurpleBlock isBigBlock>
                <LocalPhone sx={styles.icon} />
                <Typography variant="h6" sx={styles.text}>0 800 500 335 / 116 123</Typography>
                <Drafts sx={styles.icon} />
                <Typography variant="h6" sx={styles.text}>hotline@la-strada.org.ua</Typography>
                <img alt="skype-loge" src={skype} />
                <Typography variant="h6" sx={styles.text}>lastrada-ukraine</Typography>
                <img alt="telegram-loge" src={telegram} />
                <Typography variant="h6" sx={styles.text}>@NHL116123</Typography>
              </PurpleBlock>
              <Typography
                sx={styles.greyText}
                variant="body2"
              >
                {t('hotLineSubText')}
              </Typography>
            </Box>
            <Line />
            <Box sx={styles.subBlock}>
              <Typography variant="h5" sx={styles.bigText}>
                {t('hotLine2')}
              </Typography>
              <PurpleBlock isBigBlock={false}>
                <LocalPhone sx={{ ...styles.icon, ...styles.bigIcon }} />
                <Typography variant="h4" sx={styles.bigText}>15 47</Typography>
              </PurpleBlock>
              <Typography
                sx={styles.greyText}
                variant="body2"
              >
                {t('hotLine2SutText')}
              </Typography>
            </Box>
            <Line />
            <Typography variant="h5" sx={{ ...styles.bigText, ...styles.subBlock }}>
              {t('fullServicesList')}
            </Typography>
            <Link href="http://BIT.ly/HELP_GBV" sx={styles.link}>
              <Button variant="text">HTTP://BIT.ly/HELP_GBV</Button>
            </Link>
            <Link href="/home" sx={styles.link}>
              <Button variant="text" sx={{ mt: 6 }}>{t('returnToMainPage')}</Button>
            </Link>
          </Box>
        </Box>}
      </LoginWrapper>
      :
      <Box sx={styles.registered}>
        <Box sx={styles.registeredSubBox}>
          <img src={logo} alt="logo" />
          <Typography variant="h6">{t('accountCreated')} <br />
            {t('youCanLogin')}
          </Typography>
          <Link href="/login">
            <Button variant="contained">{t('logIn')}</Button>
          </Link>
        </Box>
      </Box>
  );
}
