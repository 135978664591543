import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function RightTitle({
  isYou,
  id,
  lastLoginAt,
  createdAt
}: any) {
  const { t } = useTranslation(['common']);

  const matches = useMediaQuery('(min-width:600px)');
  return (
    <Grid item xs={6} lg={4}>
      <Grid
        container
        sx={{
          pr: 0,
          pl: 0,
          mt: 1
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            justifyContent={{
              xs: isYou ? 'flex-end' : 'flex-start',
              sm: isYou ? 'flex-end' : 'flex-start',
            }}
            alignItems="center"
            direction="row"
          >
            <Grid item>
              <Typography
                data-testid="id-label"
                fontWeight={700}
                mr={2}
                variant={matches ? 'h5' : 'h6'}
                sx={{
                  fontSize: {
                    xs: '16px',
                    md: '18px',
                    lg: '20px',
                  },
                }}
              >
                ID:
              </Typography>
            </Grid>
            <Grid item direction="row">
              <Typography
                data-testid="id-value"
                sx={{
                  fontSize: {
                    xs: '14px',
                    md: '16px',
                    lg: '20px',
                  },
                }}
              >
                {id}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {createdAt && (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              direction="row"
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '16px',
                      md: '18px',
                      lg: '20px',
                    },
                  }}
                  fontWeight={700}
                  mr={2}
                  variant="h6"
                >
                  {t('registrationDate')}

                </Typography>
              </Grid>
              <Grid xs={3} md={3} lg={2} item direction="row">
                <Typography
                  sx={{
                    fontSize: {
                      xs: '14px',
                      md: '16px',
                      lg: '20px',
                    },
                  }}
                >
                  {new Date(createdAt).toLocaleDateString()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {lastLoginAt && (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              direction="row"
            >
              <Grid>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '16px',
                      md: '18px',
                      lg: '20px',
                    },
                  }}
                  fontWeight={700}
                  mr={2}
                  variant="h6"
                >
                  {t('lastOnlineDate')}

                </Typography>
              </Grid>
              <Grid xs={3} md={3} lg={2} item direction="row">
                <Typography
                  sx={{
                    fontSize: {
                      xs: '14px',
                      md: '16px',
                      lg: '20px',
                    },
                  }}
                >
                  {new Date(lastLoginAt).toLocaleDateString()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

RightTitle.propTypes = {
  id: PropTypes.string.isRequired,
  lastLoginAt: PropTypes.string,
  createdAt: PropTypes.string,
  isYou: PropTypes.bool,
};
RightTitle.defaultProps = {
  lastLoginAt: '',
  createdAt: '',
  isYou: false,
};
