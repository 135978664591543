export enum TableMenuItem {
  Agree = 'agree',
  Deactivate = 'deactivate',
  EditClient = 'editClient',
  EditConsultant = 'editConsultant',
  Reject = 'reject',
  Remove = 'remove',
  RemoveRequest = 'removeRequest',
  ReviewAndEdit = 'reviewAndEdit',
  ViewDetails = 'viewDetails',
}
