import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { debounce } from '@mui/material/utils';
import { useAbortController } from 'hooks/request';
import { isCancel } from 'api/client';

export interface Option {
  label: string;
  value: string;
}

type Props = {
  id: string;
  fieldLabel: string;
  noOptionsText: string;
  fetchRequest: (params: { search: string }, options: { signal: AbortSignal }) => any;
  mapResponseToOptions: (response: any) => Option[];

  // eslint-disable-next-line react/require-default-props
  onError?: (error: any) => void;

  // eslint-disable-next-line react/require-default-props
  liftOptionUp?: (option: Option | null) => void;

  // eslint-disable-next-line react/require-default-props
  resetSignal?: Symbol | undefined;

  // eslint-disable-next-line react/require-default-props
  error?: string;
}

const AsyncSingleSelect: React.ForwardRefRenderFunction<any, Props> = function AsyncSingleSelect(
  { id, fieldLabel, noOptionsText, mapResponseToOptions, fetchRequest, onError, liftOptionUp, resetSignal, error }: Props,
  ref: any) {
  const isMountedRef = useRef(false);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedOption, setSelectedOption] = useState<null|Option>(null);
  const { enqueueSnackbar } = useSnackbar();
  const cancelCurrentAndCreateNewAbortController = useAbortController();
  const { t } = useTranslation(['common']);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  const fetchOptions = useCallback(debounce(async (search: string) => {
    setLoading(true);
    const abortController = cancelCurrentAndCreateNewAbortController();
    try {
      const response = await fetchRequest({ search }, { signal: abortController.signal });
      if (!isMountedRef.current) {
        return;
      }
      setOptions(mapResponseToOptions(response));
      setLoading(false);
    } catch (e: any) {
      if (!isMountedRef.current) {
        return;
      }
      setLoading(true);
      if (isCancel(e)) {
        return;
      }
      setOptions([]);
      if (typeof onError === 'function') {
        onError(e);
      } else {
        enqueueSnackbar(t('commonError'), { variant: 'error' });
      }
    }
  }, 300), [fetchRequest, mapResponseToOptions, onError]);
  useEffect(() => {
    (async () => {
      await fetchOptions(searchValue);
    })();
  }, [searchValue]);
  useEffect(() => {
    if (!isMountedRef.current || resetSignal === undefined) {
      return;
    }
    setSelectedOption(null);
    (async () => {
      await fetchOptions('');
    })();
  }, [resetSignal]);

  // eslint-disable-next-line no-use-before-define
  if (isMountedRef.current && selectedOption !== null && !optionsContains(options, selectedOption)) {
    setOptions((prev) => [...prev, selectedOption]);
  }
  return (
    <Autocomplete
      ref={ref}
      id={id}
      open={open}
      onOpen={() => {
        if (isMountedRef.current) {
          setOpen(true);
        }
      }}
      onClose={() => {
        if (isMountedRef.current) {
          setOpen(false);
        }
      }}
      value={selectedOption}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={({ label }) => label}
      filterOptions={(dropdownOptions) => dropdownOptions.filter((opt) => options.some(({ value }) => value === opt.value))}
      options={options}
      loading={loading}
      loadingText={t('loading')}
      noOptionsText={noOptionsText}
      onInputChange={(event: React.SyntheticEvent, newValue: string) => {
        if (isMountedRef.current) {
          setSearchValue(newValue);
        }
      }}
      onChange={(event: React.SyntheticEvent, selectedOption: Option | null) => {
        if (isMountedRef.current) {
          setSearchValue('');
          setSelectedOption(selectedOption);
          if (typeof liftOptionUp === 'function') {
            liftOptionUp(selectedOption);
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={fieldLabel}
          helperText={error}
          error={!!error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

function optionsContains(options: readonly Option[], option: Option | null) {
  return options.map(({ value }) => value)
    .some((value) => option?.value === value);
}

export default React.forwardRef(AsyncSingleSelect);
