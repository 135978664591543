import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useAppDispatch } from 'hooks';
import { searchUsers } from 'feature/users/usersSlice';
import { Asc, Desc, LIMIT_USER_LIST } from 'components/utils/constants';
import { styles } from './styles';

const state = 'state';
const role = 'role';
const gender = 'gender';
const violenceType = 'violenceType';
const states = [
  {
    label: 'active',
    name: 'Active',
  },
  {
    label: 'deactivated',
    name: 'Deactivated',
  },
];
const roles = [
  {
    label: 'client',
    name: 'Client',
  },
  {
    label: 'consultant',
    name: 'Consultant',
  },
  {
    label: 'coordinator',
    name: 'Coordinator',
  },
  {
    label: 'admin',
    name: 'Admin',
  },
];
const genders = [
  {
    label: 'male',
    name: 'Male',
  },
  {
    label: 'female',
    name: 'Female',
  },
  {
    label: 'other',
    name: 'Other',
  },
];
const violenceTypes = [{
  label: 'physical',
  name: 'Physical',
}, {
  label: 'psychological',
  name: 'Psychological',
}, {
  label: 'sexual',
  name: 'Sexual',
}, {
  label: 'otherViolenceType',
  name: 'Other',
}];

type Props = {
  sortBy: string;
  order: Asc | Desc;
  offset: number;
  limit: number;
  filterState: any;
  onResetFilters: Function;
  onFilterChange: Function;
  onFilterSubmitCallback: Function;
}

function FiltersPanel({
  sortBy,
  order,
  offset,
  limit,
  filterState,
  onResetFilters,
  onFilterChange,
  onFilterSubmitCallback
}: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common']);
  const handleFilterField = (event: any, type: any) => {
    const newFilterState = { ...filterState };
    if (event.target.checked) {
      newFilterState[type] = [...newFilterState[type], event.target.name];
    } else {
      newFilterState[type] = [...newFilterState[type].filter((item: any) => item !== event.target.name)];
    }
    onFilterChange(newFilterState);
  };
  const filterHandler = () => {
    dispatch(searchUsers({ ...filterState, limit, sortBy, order }));
    onFilterSubmitCallback();
  };
  const resetFilters = () => {
    onResetFilters();
    dispatch(
      searchUsers({ limit, offset, sortBy, order })
    );
  };
  return (
    <Box sx={styles.mainBlock}>
      <div style={{ marginLeft: '20px' }}>
        <Typography sx={styles.filterBlock}>{t('activity')}</Typography>
        <FormGroup>
          {states.map((item) => (
            <FormControlLabel
              key={item.name}
              control={<Checkbox checked={filterState.state.includes(item.name)} onChange={(e) => handleFilterField(e, state)} />}
              label={t(item.label)}
              name={item.name}
            />
          ))}
        </FormGroup>
        <Typography sx={styles.filterBlock}>{t('role')}</Typography>
        <FormGroup>
          {roles.map((item) => (
            <FormControlLabel
              key={item.name}
              control={<Checkbox checked={filterState.role.includes(item.name)} onChange={(e) => handleFilterField(e, role)} />}
              label={t(item.label)}
              name={item.name}
            />
          ))}
        </FormGroup>
        <Typography sx={styles.filterBlock}>{t('gender')}</Typography>
        <FormGroup>
          {genders.map((item) => (
            <FormControlLabel
              key={item.name}
              control={<Checkbox checked={filterState.gender.includes(item.name)} onChange={(e) => handleFilterField(e, gender)} />}
              label={t(item.label)}
              name={item.name}
            />
          ))}
        </FormGroup>
        <Typography sx={styles.filterBlock}>{t('violenceType')}</Typography>
        <FormGroup>
          {violenceTypes.map((item) => (
            <FormControlLabel
              key={item.name}
              control={<Checkbox checked={filterState.violenceType.includes(item.name)} onChange={(e) => handleFilterField(e, violenceType)} />}
              label={t(item.label)}
              name={item.name}
            />
          ))}
        </FormGroup>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '15px'
        }}
      >
        <div
          style={{
            marginLeft: '15px',
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={filterHandler}
          >
            <Typography
              sx={{
                paddingLeft: '15px',
                paddingRight: '15px',
                paddingTop: '2px',
                paddingBottom: '1px',
                fontSize: '12px',
                textTransform: 'none',
              }}
            >
              {t('toFilter')}
            </Typography>
          </Button>
        </div>
        <div
          style={{
            marginLeft: '15px',
            marginRight: '15px',
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={resetFilters}
          >
            <Typography
              sx={{
                paddingLeft: '15px',
                paddingRight: '15px',
                paddingTop: '2px',
                paddingBottom: '1px',
                fontSize: '12px',
                textTransform: 'none',
              }}
            >
              {t('resetFilter')}
            </Typography>
          </Button>
        </div>
      </div>
    </Box>
  );
}

FiltersPanel.propTypes = {
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number,
  sortBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  filterState: PropTypes.shape({
    role: PropTypes.arrayOf(PropTypes.string),
    gender: PropTypes.arrayOf(PropTypes.string),
    violenceType: PropTypes.arrayOf(PropTypes.string),
    state: PropTypes.arrayOf(PropTypes.string),
  }),
  onFilterChange: PropTypes.func,
  onResetFilters: PropTypes.func,
  onFilterSubmitCallback: PropTypes.func,
};

FiltersPanel.defaultProps = {
  filterState: {
    role: [],
    gender: [],
    violenceType: []
  },
  limit: LIMIT_USER_LIST,
  onFilterChange: () => { },
  onResetFilters: () => { },
  onFilterSubmitCallback: () => { },
};

export default FiltersPanel;
