import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  TablePagination,
} from '@mui/material';
import {
  updateClientConsultantRelation,
  deleteClientConsultantRelation,
  getClientsList,
  mapClientsToOptions,
} from 'feature/relations/relationsService';
import ConfirmationDialog from 'components/modals/ConfirmationDialog';
import { LIMIT_USER_LIST } from 'components/utils/constants';
import {
  getConsultantClientsFromRelationsStore,
  getConsultantInfoFromRelationsStore,
  getConsultantTotalClientsFromRelationsStore,
} from 'feature/relations/relationsSelectors';
import { getConsultantCurrentClients, getConsultantData } from 'feature/relations/relationsSlice';
import { useAppDispatch } from 'hooks';
import AsyncSingleSelect, { Option } from 'components/fields/AsyncSingleSelect';
import { formatApiPhoneStr } from 'utils';
import * as styles from './EditRelations.styles';

export default function EditConsultant() {
  const { consultantId } = useParams();
  const [newClientId, setNewClientId] = useState('');
  const liftSelectedOption = useCallback((option: Option | null) => setNewClientId(option?.value || ''), []);

  // eslint-disable-next-line symbol-description
  const [resetSignal, setResetSignal] = useState<Symbol | undefined>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedUserId, setDeletedUserId] = useState('');
  const [userDeleted, setUserDeleted] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [offsetSkipUsers, setOffsetSkipUsers] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const asyncSelectRef = useRef<any>(null);
  const executeScroll = () => {
    const { current: $element } = asyncSelectRef;
    if (typeof $element?.scrollIntoView === 'function') {
      // @ts-ignore
      $element?.scrollIntoView();
    }
  };
  const currentClients = useSelector(
    getConsultantClientsFromRelationsStore
  );
  const totalClients = useSelector(
    getConsultantTotalClientsFromRelationsStore
  );
  const consultant = useSelector(
    getConsultantInfoFromRelationsStore
  );
  const getConsultantInfo = () => {
    dispatch(getConsultantData(consultantId as string));
  };
  const showSelectClient = () => {
    setShowSelect(true);
  };

  useEffect(() => {
    dispatch(getConsultantCurrentClients({
      id: consultantId,
      params: {
        limit: LIMIT_USER_LIST,
        offset: offsetSkipUsers,
      }
    }));
    getConsultantInfo();
    setUserDeleted(false);
  }, [userDeleted, newClientId, offsetSkipUsers]);

  useEffect(() => {
    if (showSelect) {
      executeScroll();
    }
  }, [showSelect]);
  const updateRelation = () => {
    updateClientConsultantRelation({
      clientUserId: newClientId,
      consultantUserId: consultantId,
    }).then(() => {
      setNewClientId('');
      // eslint-disable-next-line symbol-description
      setResetSignal(Symbol());
    });
  };

  const deleteClient = (id: any) => {
    setOpenDeleteModal(true);
    setDeletedUserId(id);
  };

  const deleteClientHandler = () => {
    deleteClientConsultantRelation(deletedUserId)
      .then(() => {
        enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
        setUserDeleted(true);
        // eslint-disable-next-line symbol-description
        setResetSignal(Symbol());
      })
      .catch(() => {
        enqueueSnackbar(t('commonError'), { variant: 'error' });
      });

    setOpenDeleteModal(false);
  };

  const onPageChangeHandler = (event: any, page: any) => {
    setOffsetSkipUsers(LIMIT_USER_LIST * page);
    setCurrentPage(page);
  };
  return (
    <Box component="div">
      <Grid sx={styles.headerContainer}>
        <Grid>
          <Typography fontWeight={700} variant="h5">{consultant?.firstName || ''} {consultant?.lastName || ''}</Typography>
          <Typography fontWeight={700} variant="h5">{t('currentClients')}</Typography>
        </Grid>
        <Grid sx={styles.addClientBtnContainer}>
          <Button
            variant="contained"
            type="submit"
            onClick={showSelectClient}
            disabled={showSelect}
          >
            {t('addClient')}
          </Button>
        </Grid>
      </Grid>
      <Grid sx={styles.tableHeaderContainer}>
        <Grid
          container
          sx={styles.tableHeader}
        >
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6" fontWeight={600} fontSize="1.0rem">
              {t('client')}
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6" fontWeight={600} fontSize="1.0rem">
              {t('phone')}
            </Typography>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="h6" fontWeight={600} fontSize="1.0rem">
              {t('startWorkingDate')}
            </Typography>
          </Grid>
        </Grid>
        {showSelect && (
          <Grid sx={styles.selectContainer}>
            <Grid>
              <FormControl sx={styles.selectStyles} focused={showSelect}>
                <AsyncSingleSelect
                  ref={asyncSelectRef}
                  id="chooseClient"
                  fieldLabel={t('chooseClient')}
                  noOptionsText={t('noConsultantsFound')}
                  fetchRequest={(queryParams, fetchOptions) => getClientsList(
                    { state: ['Active'], ...queryParams }, fetchOptions)}
                  mapResponseToOptions={mapClientsToOptions}
                  liftOptionUp={liftSelectedOption}
                  resetSignal={resetSignal}
                />
              </FormControl>
            </Grid>
            <Grid sx={styles.submitBtnStyles}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                onClick={updateRelation}
                disabled={!newClientId.length}
              >
                {t('add')}
              </Button>
            </Grid>
          </Grid>
        )}
        {currentClients.map((item: any) => (
          <Grid
            key={item?.user?.id}
            container
            sx={styles.relationsContainer}
          >
            <Grid item md={3} sm={6} xs={12}>
              <Typography>
                {item.firstName} {item.lastName}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography>
                {formatApiPhoneStr(item?.user?.phone)}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Typography>
                {new Date(item.consultantStartDate).toLocaleDateString('fr-CH')}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Button
                variant="contained"
                type="submit"
                onClick={() => deleteClient(item?.user?.id)}
              >
                {t('untie')}
              </Button>
            </Grid>
          </Grid>
        ))}
        {offsetSkipUsers > 9 || currentClients.length > 9 ? (
          <TablePagination
            sx={styles.paginationStyles}
            rowsPerPageOptions={[]}
            count={totalClients}
            page={currentPage}
            rowsPerPage={LIMIT_USER_LIST}
            onPageChange={onPageChangeHandler}
          />
        ) : null}
        <ConfirmationDialog
          open={openDeleteModal}
          okHandler={deleteClientHandler}
          closeHandler={() => setOpenDeleteModal(false)}
          dialogContentText={t('areYouReallyWantToChange')}
          confirmLabel={t('yes')}
          cancelLabel={t('no')}
        />
        <Grid sx={styles.backBtnContainer(false)}>
          <Button
            sx={styles.backBtnStyles}
            variant="contained"
            type="submit"
            fullWidth
            onClick={() => navigate('/admin/clients')}
          >
            {t('back')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
