export const sxStyles = {
  contentBox: {
    padding: '1.5rem 1.5rem 2rem'
  },
  consultantBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    margin: '1rem 0 1.5rem'
  },
  avatar: {
    width: 80,
    height: 80,
  },
  textInfo: (theme: any) => ({
    mb: '1.5rem',
    color: theme.palette.text.secondary
  }),
  changeContact: (theme: any) => ({
    ...theme.typography.body2,
    color: '#B14E24',
    marginLeft: '5px'
  }),
};
