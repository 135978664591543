export enum UserState {
  /**
   * User is created and invited to sign up (usually via referral link, applied to Consultant & Coordinator roles)
   */
  Invited = 'Invited',
  /**
   * User is active. User can use all features of the system that are allowed for his role.
   */
  Active = 'Active',
  /**
   * User is deactivated. He can't log in and use the system features.
   */
  Deactivated = 'Deactivated',
  /**
   * User is deleted from the system. He can't log in and sensitive info is removed from the DB.
   */
  Deleted = 'Deleted'
}
