export const styles = {
  mainBlock: {
    display: 'block',
    position: 'absolute',
    top: '160px',
    right: '24px',
    width: '315px',
    height: 'auto',
    backgroundColor: '#e8e4e4',
    ml: '20px',
    zIndex: 1,
    pb: 2
  },
  filterBlock: {
    fontWeight: 400,
    fontSize: '20px',
    mb: '2px',
    mt: '20px'
  }
};
