import {
  CONSULTANT_ACCOUNT_DEACTIVATED_PATH,
  CONSULTANT_ACCOUNT_DEACTIVATED_SUCCESSFULLY_PATH,
  USER_ACCOUNT_DELETED_PATH,
  USER_ACCOUNT_DELETED_SUCCESSFULLY_PATH,
} from 'utils';
import DeletionSuccessPage from 'pages/DeleteSuccessPage';
import { Navigate, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function UserAccountDeleted() {
  const { t } = useTranslation(['common']);
  return (
    <DeletionSuccessPage>
      {t('userAccountDeletedSuccessfully')}
      <br />
      {t('thankYouForUsingOurService', { projectName: t('projectName') })}
      <br />
      {t('youMayCloseTheBrowserTab')}
    </DeletionSuccessPage>
  );
}

export const UserAccountDeletedRoute = (
  <>
    {/* The path is base on the UUID and can only be seen when the app redirects you there */}
    <Route path={USER_ACCOUNT_DELETED_SUCCESSFULLY_PATH} element={<UserAccountDeleted />} />
    {/* If the path does not contain the specific UUID, the user will be redirected to the home page */}
    <Route path={`${USER_ACCOUNT_DELETED_PATH}/:randomString`} element={<Navigate replace to="/home" />} />
  </>
);

function ConsultantAccountDeactivated() {
  const { t } = useTranslation(['common']);
  return (
    <DeletionSuccessPage displayBackToMainBtn>
      {t('requestToDeleteUserAccountHasBeenAccepted')}
      <br />
      {t('soonOurRepresentativeWillConnectWithYouForDetails')}
      <br />
      {t('thankYou')}
    </DeletionSuccessPage>
  );
}

export const ConsultantAccountDeactivatedRoute = (
  <>
    {/* The path is base on the UUID and can only be seen when the app redirects you there */}
    <Route path={CONSULTANT_ACCOUNT_DEACTIVATED_SUCCESSFULLY_PATH} element={<ConsultantAccountDeactivated />} />
    {/* If the path does not contain the specific UUID, the user will be redirected to the home page */}
    <Route path={`${CONSULTANT_ACCOUNT_DEACTIVATED_PATH}/:randomString`} element={<Navigate replace to="/home" />} />
  </>
);
