import { request } from 'api/client';
import URLS, { ROLE_URLS } from 'api/urls';
import { UserRole } from 'enums/user-role.enum';

export const getUser = async (id: string, userRole: UserRole) => {
  const response = await request({
    url: `${ROLE_URLS[userRole]}/${id}`,
    method: 'GET',
  });
  return response;
};

export const getRequestById = async (id: any) => {
  const response = await request({
    url: `${URLS.REQUESTS}/${id}`,
    method: 'GET',
  });

  return response;
};
