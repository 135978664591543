import React from 'react';
import { Drawer } from '@mui/material';
import PropTypes from 'prop-types';
import sx from './DesktopDrawer.styles';

export default function DesktopDrawer({
  drawer,
  open,
}: any) {
  return (
    <Drawer sx={sx} open={open} variant="persistent" anchor="left">
      {drawer}
    </Drawer>
  );
}
DesktopDrawer.propTypes = {
  drawer: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
};
