import { request } from 'api/client';
import URLS from 'api/urls';

export const getConsultantInfo = async (id: string) => {
  const response = await request({
    url: `${URLS.CONSULTANTS_LIST}/${id}`,
    method: 'GET',
  });

  return response;
};
