import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import { useAppDispatch, useAppSelector } from 'hooks';
import { COMMUNICATION_TYPE_OPTIONS, VIOLENCE_TYPE_OPTIONS } from 'configs';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Stack
} from '@mui/material';
import { CommonList } from 'models/common';
import RegistrationForm from 'components/forms/RegistrationForm';
import Spinner from 'components/shared/Spinner';
import formStyles from 'components/forms/Form.styles';
import PhoneInput from 'components/shared/PhoneInput';
import ExpertCard from 'components/ExpertCard';
import { Autocomplete, DeleteButton, OutlineButton } from 'components/shared/styles';
import { validationSchema } from 'pages/RegistrationPage/helper';
import { logout } from 'feature/auth/authSlice';
import { getClientUserProfile, updateClientUserProfile } from 'feature/user/userSlice';
import { getUserFromUserStore } from 'feature/user/userSelectors';
import SoftDeleteUserAccount from 'components/modals/SoftDeleteUserProfile';
import ConfirmationDialogAfterChanges from 'components/modals/ConfirmationDialogAfterChanges';
import UpdatePhoneDialog from 'components/modals/UpdatePhoneModal';
import RightTitle from 'components/EditUser/RightTitle';
import { useSaveChangesDialogOnExit } from 'hooks/useSaveChangesDialogOnExit';
import { USER_ACCOUNT_DELETED_SUCCESSFULLY_PATH } from 'utils';
import { styles } from './styles';

function mapCommunicationChannelToI18(communicationChanel: string, t: Function) {
  switch (communicationChanel) {
    case 'Phone':
      return t('phoneNumber');
    case 'Email':
      return t('email');
    case 'Viber':
    case 'WhatsApp':
    case 'Telegram':
      return `${t('phoneNumber')} ${communicationChanel}`;
    default:
      return t('otherCommunicationChannel');
  }
}

export default function ClientProfile({ isAdmin = false }: {isAdmin?: boolean}) {
  const { userId, userRole } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const [softDeleteIsOpen, setSoftDeleteIsOpen] = useState(false);
  const [saveChangesDialogIsOpen, setSaveChangeDialogIsOpen] = useState(false);
  const [isEditModalOpen, setOpenEditModal] = useState(false);
  const [validation, setValidation] = React.useState(validationSchema(t, false, true));
  const [wasBackButtonClicked, setBackButtonClicked] = useState(false);
  const [activeCb, setActiveCb] = useState(() => () => {});
  const currentUserId = Cookies.get('id');

  const isYou = userId === currentUserId;

  useEffect(() => {
    dispatch(getClientUserProfile(userId as string));
  }, [userId, dispatch]);

  const { isLoading, user: clientDetail } = useAppSelector(getUserFromUserStore);

  const handleBackNavigation = () => {
    if (wasBackButtonClicked) {
      if (isAdmin) {
        navigate('/admin/users');
      } else {
        navigate('/client/expert-list');
      }
      setBackButtonClicked(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: clientDetail?.firstName || '',
      lastName: clientDetail?.lastName || '',
      gender: clientDetail?.gender || '',
      birthday: clientDetail?.birthday || '',
      phone: clientDetail?.user?.phone || '',
      email: clientDetail?.user?.email || '',
      file: clientDetail?.photoId || '',
      violenceTypes: VIOLENCE_TYPE_OPTIONS.filter((item) => (clientDetail?.violenceTypes || []).includes(item.value)),
      communicationChannel: clientDetail?.communicationChannel || '',
      additionalCommunicationChannel: clientDetail?.additionalCommunicationChannel || '',
      additionalCommunicationContact: clientDetail?.additionalCommunicationContact || ''
    },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: (data) => {
      dispatch(updateClientUserProfile({
        id: userId,
        role: userRole,
        isAdmin,
        data: {
          firstName: data.firstName,
          lastName: data.lastName || null,
          birthday: data.birthday,
          gender: data.gender,
          user: {
            email: data.email,
            phone: data.phone
          },
          violenceTypes: data.violenceTypes.map((item) => item.value),
          communicationChannel: data.communicationChannel,
          additionalCommunicationChannel: data.additionalCommunicationChannel || null,
          additionalCommunicationContact: data.additionalCommunicationChannel ? data.additionalCommunicationContact : null
      } }));
      handleBackNavigation();
    }
  });
  const {
    values,
    initialValues,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched
  } = formik;

  useSaveChangesDialogOnExit(initialValues, values, setSaveChangeDialogIsOpen, setBackButtonClicked);

  const navigateToList = () => {
    navigate('/client/expert-list');
  };

  // @ts-ignore
  const getValidationCondition = (key: string) => (errors[key] || (touched[key] && errors[key]));
  if (isEmpty(clientDetail)) {
    return <Spinner />;
  }

  const updateViolenceTypes = (event: any, newValue: CommonList[] | any) => {
    setFieldValue('violenceTypes', newValue.map((item: CommonList) => item), true);
  };

  const updateAdditionalChannel = (e: any) => {
    setFieldValue('additionalCommunicationChannel', e.target.value);
    setFieldValue('additionalCommunicationContact', e.target.value === 'Email' ? values.email : values.phone);
    setFieldTouched('additionalCommunicationContact');
  };

  const showChangeModal = (cb: Function) => {
    if (!isEqual(initialValues, values)) {
      setSaveChangeDialogIsOpen(true);
      setActiveCb(() => cb);
    } else {
      cb();
    }
  };

  return (
    <>
      {!isYou &&
      <Grid container justifyContent="flex-end">
        <RightTitle
          isYou={isYou}
          id={clientDetail.user.externalId || ''}
          createdAt={isYou ? '' : clientDetail.user.createdAt}
          lastLoginAt={isYou ? '' : clientDetail.user.lastLoginAt}
        />
      </Grid>}
      <Grid container spacing={2} sx={{ justifyContent: 'center', mt: isYou ? '3rem' : '0' }}>
        <Grid item xs={12} sm={10} md={isAdmin ? 10 : 8} lg={isAdmin ? 9 : 7}>
          <Grid container spacing={2}>
            <Typography sx={{ mb: '2rem' }} variant="h4" component="h1">
              {isAdmin ? t('profile') : t('myProfile')}
            </Typography>
            {isLoading ?
              <Spinner /> :
              <Box sx={styles.whiteBlock}>
                <form onSubmit={handleSubmit}>
                  <Grid item xs={11} md={9}>
                    <RegistrationForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setValidation={setValidation}
                      setActiveSubPage={() => {}}
                      isRegistration={false}
                      isClientProfile
                      userId={userId}
                      userRole={userRole || 'Client'}
                      setOpenEditModal={setOpenEditModal}
                    />
                    {values.communicationChannel && (
                      values.communicationChannel === 'Email' ? <TextField
                        onBlur={handleBlur}
                        sx={formStyles.input}
                        fullWidth
                        label={mapCommunicationChannelToI18(values.communicationChannel, t)}
                        value={values.email}
                        id="email"
                        name="email"
                        disabled
                      /> : <PhoneInput
                        onBlur={handleBlur}
                        disabled
                        sx={formStyles.input}
                        fullWidth
                        label={mapCommunicationChannelToI18(values.communicationChannel, t)}
                        value={values.phone}
                        id="phone"
                        name="phone"
                      />
                    )}
                    {values.communicationChannel &&
                    <TextField
                      select
                      fullWidth
                      sx={formStyles.input}
                      name="additionalCommunicationChannel"
                      label={t('additionalCommunicationChannel')}
                      variant="outlined"
                      aria-describedby={t('additionalCommunicationChannel')}
                      helperText={getValidationCondition('additionalCommunicationChannel')}
                      value={values.additionalCommunicationChannel}
                      onChange={updateAdditionalChannel}
                      onBlur={handleBlur}
                      error={
                              getValidationCondition('additionalCommunicationChannel')
                            }
                    >
                      {[...COMMUNICATION_TYPE_OPTIONS,
                            { value: '', label: t('notSelected') }].map(({ label, value }: any) => (
                              <MenuItem key={value} value={value}>
                                {label}
                              </MenuItem>
                          ))}
                    </TextField>}
                    {values.additionalCommunicationChannel && (
                      values.additionalCommunicationChannel === 'Email' ? <TextField
                        onBlur={handleBlur}
                        sx={formStyles.input}
                        fullWidth
                        label={t('email')}
                        value={values.additionalCommunicationContact}
                        id="additionalCommunicationContact"
                        name="additionalCommunicationContact"
                        onChange={handleChange}
                        error={
                          getValidationCondition('additionalCommunicationContact')
                        }
                        helperText={getValidationCondition('additionalCommunicationContact')}
                      /> : <PhoneInput
                        onBlur={handleBlur}
                        FormHelperTextProps={{ style: formStyles.helper }}
                        sx={formStyles.input}
                        fullWidth
                        label={t('phoneNumber')}
                        value={values.additionalCommunicationContact}
                        id="additionalCommunicationContact"
                        name="additionalCommunicationContact"
                        onChange={handleChange}
                        error={getValidationCondition('additionalCommunicationContact')}
                        helperText={getValidationCondition('additionalCommunicationContact')}
                      />
                    )}
                    <Stack spacing={3}>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        value={values?.violenceTypes}
                        options={VIOLENCE_TYPE_OPTIONS}
                        getOptionLabel={(option: any) => option.label}
                        filterSelectedOptions
                        // @ts-ignore
                        renderInput={({ error: inputError, onChange, ...params }) => (
                          <TextField
                            {...params}
                            label={t('violenceTypes')}
                            onChange={onChange}
                          />
                        )}
                        onChange={updateViolenceTypes}
                        sx={{ mb: 2 }}
                      />
                    </Stack>
                  </Grid>
                  {!isAdmin &&
                  <Typography
                    variant="h5"
                    sx={formStyles.subTitle}
                  >
                    {t('yourSpecialist')}
                  </Typography>}
                  {!isAdmin && (clientDetail.consultant ?
                    <>
                      <ExpertCard
                        consultant={clientDetail.consultant}
                        currentUserData={clientDetail.user}
                        isSelectedConsultant
                        showChangeModal={showChangeModal}
                      />
                      <OutlineButton
                        color="primary"
                        size="large"
                        variant="outlined"
                        sx={{ mb: 2, mt: 2 }}
                        onClick={() => showChangeModal(() => navigateToList())}
                      >
                        {t('goToAllSpecialists')}
                      </OutlineButton>
                    </> :
                    <>
                      <Typography variant="caption">
                        {t('youHaventSelectConsultant')}
                      </Typography>
                      <br />
                      <OutlineButton
                        color="primary"
                        size="large"
                        variant="outlined"
                        sx={{ mb: 2, mt: 2 }}
                        onClick={() => showChangeModal(() => navigateToList())}
                      >
                        {t('goToSpecialists')}
                      </OutlineButton>
                    </>)}
                  <Typography
                    variant="h5"
                    sx={formStyles.subTitle}
                  >
                    {t('options')}
                  </Typography>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isEqual(initialValues, values) || Object.keys(errors).length !== 0}
                    size="large"
                    variant="contained"
                    sx={{ mr: 2, mb: 2 }}
                  >
                    {t('saveChanges')}
                  </Button>
                  {!isAdmin &&
                  <OutlineButton
                    color="primary"
                    size="large"
                    variant="outlined"
                    sx={{ mr: 2, mb: 2 }}
                    onClick={() => showChangeModal(() => dispatch(logout()))}
                  >
                    {t('logOut')}
                  </OutlineButton>}
                  {!isAdmin &&
                  <DeleteButton
                    color="primary"
                    size="large"
                    variant="contained"
                    sx={{ mr: 2, mb: 2 }}
                    onClick={() => showChangeModal(() => setSoftDeleteIsOpen(true))}
                  >
                    {t('deleteAccount')}
                  </DeleteButton>}
                </form>
              </Box>}
          </Grid>
        </Grid>
        {isAdmin &&
        <Grid container mt={2} justifyContent="flex-end">
          <Button onClick={() => showChangeModal(() => navigate('/admin/users'))} size="large" variant="contained">
            {t('back')}
          </Button>
        </Grid>}
      </Grid>
      <ConfirmationDialogAfterChanges
        open={saveChangesDialogIsOpen}
        okHandler={() => {
          handleSubmit();
          setSaveChangeDialogIsOpen(false);
          activeCb();
        }}
        closeHandler={() => setSaveChangeDialogIsOpen(false)}
        notSave={() => {
          setSaveChangeDialogIsOpen(false);
          handleBackNavigation();
          activeCb();
        }}
      />
      <SoftDeleteUserAccount
        userId={userId}
        open={softDeleteIsOpen}
        navigateTo={USER_ACCOUNT_DELETED_SUCCESSFULLY_PATH}
        deleteBtnText={t('remove')}
        closeHandler={() => setSoftDeleteIsOpen(false)}
      />
      <UpdatePhoneDialog
        open={isEditModalOpen}
        closeHandler={() => setOpenEditModal(false)}
        okHandler={(value) => setFieldValue('phone', value)}
        userId={userId}
      />
    </>
  );
}

ClientProfile.defaultProps = {
  isAdmin: false,
};
