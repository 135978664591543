import { useCallback, useEffect, useRef } from 'react';

export function useAbortController(): () => AbortController {
  const abortControllerRef = useRef(new AbortController());
  useEffect(() => () => abortControllerRef.current.abort(), []);
  return useCallback(() => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    return abortControllerRef.current;
  }, []);
}
