import { DRAWER_WIDTH } from '../../configs';

const sx = {
  display: { xs: 'block', md: 'none' },
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    width: DRAWER_WIDTH,
  },
};

export default sx;
