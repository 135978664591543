import { request } from 'api/client';
import { ROLE_URLS } from 'api/urls';

export const getClient = async (id: string) => {
  const response = await request({
    url: `${ROLE_URLS.Client}/${id}`,
    method: 'GET',
  });
  return response;
};
