import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormStepTitle, PrimaryBtn } from 'components/forms/RestorePassword/FormStepComponents';

export default function PasswordChangedSuccessfullyStep() {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const navigateToLoginPage = () => navigate('/login');
  return (
    <div>
      <FormStepTitle
        stepHeader={t('passwordHasBeenChanged')}
        stepDescription={t('passwordHasBeenChangedDescription')}
      />
      <PrimaryBtn onClick={navigateToLoginPage} btnContent={t('logIn')} />
    </div>
  );
}
