import { request } from 'api/client';
import URLS, { API_URL } from 'api/urls';

export const getConsultants = async () => {
    const response = await request({
        url: URLS.CONSULTANTS_LIST,
        method: 'GET',
    });

    return response;
};

export const getConsultantClientListApi = async (id: any) => {
    const response = await request({
        url: `${API_URL}/consultants/${id}/clients`,
        method: 'GET',
    });

    return response;
};
