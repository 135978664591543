import { request } from '../../api/client';
import URLS, { API_URL } from '../../api/urls';

export const getClientRequests = async (params: any) => {
  const response = await request({
    url: URLS.REQUESTS,
    method: 'GET',
    params,
  });

  return response;
};

export const removeClientRequestApi = async (id: any) => {
  const response = await request({
    url: `${API_URL}/users-requests/${id}`,
    method: 'DELETE',
  });

  return response;
};

export const processClientRequestApi = async (data: {id: number, action: string}) => {
  const response = await request({
    url: `${API_URL}/users-requests/${data.id}/process`,
    method: 'POST',
    data: {
      action: data.action
    }
  });

  return response;
};
