export const isProduction = process.env.NODE_ENV === 'production';
export const DRAWER_WIDTH = 260;

export const NAV_MENU_CONFIG = [
  {
    title: 'Клієнт/ка - Консультант/ка',
    url: '/admin/clients',
  },
  {
    title: 'Запити',
    url: '/admin/requests',
  },
];

export const USERS_NAV_MENU_CONFIG = [
  {
    title: 'Користувачі',
    url: '/admin/users',
  },
];

export const BREADCRUMBS_CONFIG = [
  {
    title: 'Запросити користувача',
    url: '/admin/users/invite',
  },
];

export const GENDER_TYPE_OPTIONS = [
  {
    label: 'Чоловік',
    value: 'Male',
  },
  {
    label: 'Жінка',
    value: 'Female',
  },
  {
    label: 'Інша',
    value: 'Other',
  },
];

export const COMMUNICATION_TYPE_OPTIONS = [
  {
    value: 'Phone',
    label: 'Phone',
  },
  {
    value: 'Email',
    label: 'Email',
  },
  {
    value: 'Viber',
    label: 'Viber',
  },
  {
    value: 'WhatsApp',
    label: 'WhatsApp',
  },
  {
    value: 'Telegram',
    label: 'Telegram',
  }
];
export const VIOLENCE_TYPE_OPTIONS = [
  {
    value: 'Physical',
    label: 'Фізичне',
  },
  {
    value: 'Psychological',
    label: 'Психологічне',
  },
  {
    value: 'Sexual',
    label: 'Сексуальне',
  },
  {
    value: 'Other',
    label: 'Інше',
  },
];

export const ITEMS_ON_PAGE = 10;
export const USER_ROLES = {
  Admin: 'Адміністратор/ка',
  Client: 'Клієнт/ка',
  Consultant: 'Консультант/ка',
  Coordinator: 'Координатор/ка',
};
export const VIOLENCE_TYPE = {
  Sexual: 'Сексуальне',
  Other: 'Інше',
  Physical: 'Фізичне',
  Psychological: 'Психологічне',
};

export const STATUS_TYPES = {
  new: 'New',
  approve: 'Approve',
  reject: 'Reject',
};

export const GENDER_TYPE = {
  Male: 'Чоловік',
  Female: 'Жінка',
  Other: 'Інша'
};

export const REQUEST_TYPE = {
  RequestConsultant: 'Запит на консультанта/тку',
  ChangeConsultant: 'Зміна консультанта/тки',
  DeleteProfile: 'Видалення профілю',
  Deactivate: 'Деактивація користувача/ки',
  DeactivateClient: 'Деактивація клієнта/ки',
  DeactivateConsultant: 'Деактивація консультанта/ки',
  DeactivateCoordinator: 'Деактивація координатора/ки',
  Delete: 'Видалення профілю користувача/ки',
  DeleteClient: 'Видалення профілю клієнта/ки',
  DeleteConsultant: 'Видалення профілю консультанта/ки',
  DeleteCoordinator: 'Видалення профілю координатора/ки',
};

export const STATUS_TYPE = {
  Approved: 'Погоджено',
  New: 'Новий',
  Rejected: 'Відхилено'
};
export const MAX_IMAGE_SIZE = 5000000;

export const REGISTRATION_SUB_PAGES = {
  preRegistration: 'preRegistration',
  registration: 'registration',
  privacy: 'privacy',
  confirmCode: 'confirmCode',
  info: 'info'
};
