import React from 'react';
import { Navigate, Outlet, useLocation, } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageLayout from 'components/PageLayout';
import Spinner from 'components/shared/Spinner';

export default function ProtectedRoute({
  isLoading,
  isAllowed,
  redirectPath = '/login',
  children
}: any) {
  const location = useLocation();
  if (isLoading) {
    return <Spinner />;
  }
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }
  return <PageLayout>{children || <Outlet />}</PageLayout>;
}

ProtectedRoute.propTypes = {
  isLoading: PropTypes.bool,
  isAllowed: PropTypes.bool,
  redirectPath: PropTypes.string,
  children: PropTypes.node,
};
ProtectedRoute.defaultProps = {
  isLoading: false,
  isAllowed: false,
  redirectPath: '/login',
  children: undefined,
};
