import { request } from '../../api/client';
import URLS from '../../api/urls';

export const getUsers = async (data: any) => {
  const response = await request({
    url: URLS.SEARCH_USERS,
    method: 'GET',
    params: data,
  });

  return response;
};
