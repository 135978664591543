import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useMediaQuery } from '@mui/material';
import logo from 'assets/logo.svg';
import bigLogo from 'assets/big-logo.svg';
import decoration from 'assets/decoration.svg';
import mountain from 'assets/mountain.png';
import { ReactComponent as DividerIcon } from 'assets/divider.svg';
import footerLogo from 'assets/footerLogo.svg';
import portrait1 from 'assets/portrait1.png';
import portrait2 from 'assets/portrait2.png';
import portrait3 from 'assets/portrait3.png';
import portrait4 from 'assets/portrait4.png';
import portrait5 from 'assets/portrait5.png';
import portrait6 from 'assets/portrait6.png';
import { ReactComponent as BgGlyph1 } from 'assets/glyph-1.svg';
import { ReactComponent as BgGlyph2 } from 'assets/glyph2.svg';
import { ReactComponent as BgGlyph3 } from 'assets/glyph3.svg';
import { ReactComponent as BgGlyph4 } from 'assets/glyph4.svg';
import { ReactComponent as BgGlyph5 } from 'assets/glyph5.svg';
import { ReactComponent as BgGlyph6 } from 'assets/glyph6.svg';
import { ReactComponent as Facebook } from 'assets/facebook.svg';
import { ReactComponent as Instagram } from 'assets/instagram.svg';
import { ReactComponent as Softserve } from 'assets/logo_softserve.svg';
import UNFPA from 'assets/UNFPA.png';
import responsible from 'assets/responsible.png';
import fco from 'assets/fco.png';
import OVPM from 'assets/OVPM.png';
import {
  Container,
  BorderRadius,
  HeaderBox,
  HeaderText,
  CenterBox,
  CenterContent,
  QuestionSection,
  ButtonStyles,
  DividerBox,
  InfoText,
  InfoTextTitle,
  BackgroundOrange,
  BackgroundPurple,
  DescriptionSection,
  DescriptionTitleSection,
  LastDivider,
  ConsultantInfo,
  ConsultantText,
  ConsultantTitleInfo,
  ConsultantInfoSection,
  FooterTitle,
  FooterSection,
  ConsultantSpan,
  ConsultantSection,
  ConsultantDescription,
  ConsultantImg,
  DividerStyles,
  DividerOrange,
  DividerPurple,
  LinkStyles,
  GlyphBox,
  ToolbarStyles,
  ToolbarButtonStyles,
  DescriptionTitle,
  BtnCentered,
  ScreenHeight
} from './styles';

const helpsList = [
  {
    title: 'complex',
    text: 'complexText',
    icon: <BgGlyph1 />
  },
  {
    title: 'free',
    text: 'freeText',
    icon: <BgGlyph4 />
  },
  {
    title: 'anonymous',
    text: 'anonymousText',
    icon: <BgGlyph2 />
  },
  {
    title: 'confidentially',
    text: 'confidentiallyText',
    icon: <BgGlyph5 />
  },
  {
    title: 'remotely',
    text: 'remotelyText',
    icon: <BgGlyph3 />
  },
  {
    title: 'qualitatively',
    text: 'qualitativelyText',
    icon: <BgGlyph6 />
  }
];

const consultantList = [
  {
    name: 'consultant1',
    text: 'consultant1Text',
    photo: portrait1,
    experience: 'experience1'
  },
  {
    name: 'consultant2',
    text: 'consultant2Text',
    photo: portrait2,
    experience: 'experience2'
  },
  {
    name: 'consultant3',
    text: 'consultant3Text',
    photo: portrait3,
    experience: 'experience3'
  },
  {
    name: 'consultant4',
    text: 'consultant4Text',
    photo: portrait4,
    experience: 'experience4'
  },
  {
    name: 'consultant5',
    text: 'consultant5Text',
    photo: portrait5,
    experience: 'experience5'
  }
];

const services = [
  {
    name: 'service1'
  },
  {
    name: 'service2'
  },
  {
    name: 'service3'
  },
  {
    name: 'service4'
  },
  {
    name: 'service5'
  }
];

export default function Landing() {
  const { t } = useTranslation(['common']);

  const linkToHelp = '/registration';
  const matches = useMediaQuery('(min-width:690px)');
  const matchesXS = useMediaQuery('(min-width:600px)');

  const getRandom = <T, >(list: T[]) => list.sort(() => (Math.random() > 0.5) ? 1 : -1);

  return (
    <Box sx={Container}>
      <Box>
        <AppBar position="static">
          <Toolbar sx={ToolbarStyles}>
            <img
              src={logo}
              alt="logo"
            />
            <Link sx={LinkStyles} href="/login" target="_blank">
              <Button sx={ToolbarButtonStyles}>{t('logIn')}</Button>
            </Link>
          </Toolbar>
        </AppBar>
      </Box>
      <Box sx={ScreenHeight}>
        <Box sx={HeaderBox}>
          <Grid
            container
            sx={CenterBox(matches)}
          >
            <Grid item xs={12} md={9}>
              {/* @ts-ignore */}
              <Typography variant="h4" sx={HeaderText(matches)}>
                {t('landingHeader1')} <br /> {t('landingHeader2')}
              </Typography>
              {matches &&
              <Grid item xs={3}>
                <CardMedia
                  sx={{ height: '100%' }}
                  component="img"
                  image={decoration}
                  alt="decoration"
                />
              </Grid>}
            </Grid>
            <Grid item xs={12} md={3} display="flex" justifyContent="center">
              <img
                src={bigLogo}
                alt="big logo"
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ height: matchesXS ? '42vh' : '30vh', pb: 4 }}>
          <Grid
            sx={CenterBox(matches)}
            container
          >
            <Grid container sx={BorderRadius} alignItems="center">
              {matchesXS &&
              <Grid item sm={6} sx={{ height: '100%' }}>
                <CardMedia
                  sx={{ height: '100%' }}
                  component="img"
                  image={mountain}
                  alt="mountain"
                />
              </Grid>}
              <Grid item xs={12} sm={6} sx={QuestionSection}>
                <Typography
                  variant="h4"
                  fontWeight={700}
                  fontFamily="Manrope, sans-serif"
                  color="#7969A1"
                  pb="10px"
                  /* @ts-ignore */
                  textAlign={matches ? 'none' : 'center'}
                >
                  {t('landingSubHeader')}
                </Typography>
                <Link href={linkToHelp} target="_blank" sx={BtnCentered}>
                  {/* @ts-ignore */}
                  <Button sx={[ButtonStyles, BackgroundPurple]} variant="button">
                    {t('helpBtn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={CenterContent}>
        <Grid container sx={DividerBox}>
          <Divider sx={[DividerStyles, DividerOrange]}><DividerIcon fill="#FCC9B4" /></Divider>
        </Grid>
        <Typography variant="h4" sx={InfoTextTitle(matches)}>
          {t('weHelp')}
        </Typography>
        <Grid container sx={ConsultantInfoSection}>
          {helpsList.map((item) => (
            <Grid item xs={12} md={6} key={item.title} sx={{ pb: 2 }}>
              <Grid
                container
                rowSpacing={4}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item sm={3.5} xs={12} sx={{ margin: '0 auto' }}>
                  <Box sx={GlyphBox}>
                    {item.icon}
                  </Box>
                </Grid>
                <Grid item sm={8.5} xs={12}>
                  <Typography variant="h4" sx={InfoTextTitle(matches)} pl={3}>
                    {t(item.title)}
                  </Typography>
                  <Typography variant="body2" sx={InfoText(matches)}>
                    {t(item.text)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'center' }}>
          <Link href={linkToHelp} target="_blank" sx={{ textDecoration: 'none' }}>
            <Button sx={[ButtonStyles, BackgroundOrange]}>
              {t('helpBtn')}
            </Button>
          </Link>
          <Grid container sx={DividerBox}>
            <Divider sx={[DividerStyles, DividerPurple]}><DividerIcon fill="#B2A0DC" /></Divider>
          </Grid>
        </Box>
      </Box>
      <Box sx={[DividerBox, CenterContent]}>
        <Typography sx={DescriptionTitleSection(matches)}>
          {t('aboutTheService')}
        </Typography>
        {services.map((service) => (
          <Box sx={DescriptionSection}>
            <Typography variant="h6" sx={DescriptionTitle}>
              {t(service.name)}
            </Typography>
          </Box>
          )
        )}
        <Grid container sx={[DividerBox, LastDivider]}>
          <Divider sx={[DividerStyles, DividerPurple]}><DividerIcon fill="#B2A0DC" /></Divider>
        </Grid>
      </Box>
      <Box sx={ConsultantSection}>
        <Grid container sx={CenterContent}>
          <Grid item>
            <Typography sx={DescriptionTitleSection(matches)}>
              {t('ourConsultants')}
            </Typography>
          </Grid>
          <Grid container rowGap={2} sx={ConsultantInfoSection}>

            {getRandom(consultantList).map((consultant: any) => (
              <>
                <Grid item xs={12} md={2.5}>
                  <CardMedia
                    component="img"
                    sx={ConsultantImg}
                    image={consultant.photo}
                    alt={t('consultantPhoto')}
                  />
                </Grid>
                <Grid item xs={12} md={9.5} sx={ConsultantDescription}>
                  <Typography variant="h5" sx={ConsultantTitleInfo}>
                    {t(consultant.name)}
                  </Typography>
                  <Typography variant="body2" sx={ConsultantInfo}>
                    {t(consultant.text)}
                  </Typography>
                  <Typography variant="body2" sx={ConsultantText}>
                    {t(consultant.experience)}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid item>
            <Typography sx={DescriptionTitleSection(matches)}>
              {t('ourCoordinator')}
            </Typography>
          </Grid>
          <Grid container rowGap={2} sx={ConsultantInfoSection}>
            <Grid item xs={12} md={2.5}>
              <CardMedia
                component="img"
                sx={ConsultantImg}
                image={portrait6}
                alt={t('consultantPhoto')}
              />
            </Grid>
            <Grid item xs={12} md={9.5} sx={ConsultantDescription}>
              <Typography variant="h5" sx={ConsultantTitleInfo}>
                {t('consultant6')}
              </Typography>
              <Typography variant="body2" sx={ConsultantInfo}>
                {t('consultant6Text1')}
              </Typography>
              <Typography variant="body2" sx={ConsultantInfo}>
                {t('consultant6Text2')}
              </Typography>
              <Typography variant="body2" sx={ConsultantText}>
                {t('experience6')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={ConsultantSpan(matches)}>
              {t('counselingWay')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Link href={linkToHelp} target="_blank" sx={{ textDecoration: 'none' }}>
              <Button sx={[ButtonStyles, BackgroundPurple]}>
                {t('helpBtn')}
              </Button>
            </Link>
          </Grid>
          <Grid item sx={[DividerBox, LastDivider]}>
            <Divider sx={[DividerStyles, DividerPurple]}><DividerIcon fill="#B2A0DC" /></Divider>
          </Grid>
        </Grid>
      </Box>
      <Box sx={FooterSection}>
        <Grid container sx={CenterBox(matches)} spacing={3}>
          <Grid item lg={3} md={4} xs={9}>
            <img src={footerLogo} alt="footer logo" />
          </Grid>
          <Grid item lg={2} md={4} xs={6}>
            <CardMedia
              component="img"
              image={UNFPA}
              alt="UNFPA"
            />
          </Grid>
          <Grid item lg={2} md={4} xs={6}>
            <CardMedia
              component="img"
              image={OVPM}
              alt="Уряду Великої Британі"
            />
          </Grid>
          <Grid item lg={2} md={4} xs={6}>
            <CardMedia
              component="img"
              image={responsible}
              alt="Офісу Віцепрем’єр-міністерки з питань європейської та євроатлантичної інтеграці"
            />
          </Grid>
          <Grid item lg={2} md={4} xs={6}>
            <CardMedia
              component="img"
              image={fco}
              alt="Урядової Уповноваженою з питань гендерної політики"
            />
          </Grid>
          <Grid item xs={12}>
            <Link href="https://www.instagram.com/rozirvy_kolo/" target="_blank">
              <Instagram />
            </Link>
            <Link sx={LinkStyles} href="https://www.facebook.com/rozirvykolo" target="_blank">
              <Facebook />
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={FooterTitle}>
              © {`${new Date().getFullYear()}`}. {t('makeBy')}
            </Typography>
          </Grid>
          <Grid item xs={6} display="flex" flexDirection="column" alignItems="flex-end">
            <Typography sx={FooterTitle}>
              <Link href="https://opentech.softserveinc.com/" target="_blank"><Softserve /></Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
