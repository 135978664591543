import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';

export default function SearchInput({
  value = '',
  searchHandler
}: any) {
  const searchCleanHandler = () => searchHandler();
  const searchOnChangeHandler = (e: any) => searchHandler(e.target.value);

  return (
    <TextField
      data-testid="search"
      id="input-with-icon-textfield"
      label="Пошук"
      autoComplete="off"
      value={value}
      onChange={searchOnChangeHandler}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <Close
              tabIndex={0}
              onKeyDown={searchCleanHandler}
              onClick={searchCleanHandler}
              sx={{ cursor: 'pointer' }}
            />
          </InputAdornment>
        ) : null,
      }}
      variant="outlined"
      size="small"
    />
  );
}
SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  searchHandler: PropTypes.func.isRequired,
};
