export const ROUTES = {
  expertList: 'client/expert-list',
  expertInfo: 'client/expert-list/:consultantId',
  editUserProfile: 'client/edit-user/:userRole/:userId',
  confirmExpert: 'client/expert-list/:consultantId/confirm-expert',
  declineExpert: 'client/expert-list/:consultantId/decline-expert',
  successExpertAction: 'client/expert/success/:action',

  clientList: 'consultant/client-list',
  clientInfo: 'consultant/client-list/:clientId',

  consultantProfile: 'consultant/edit-user/:userRole/:userId'
};

export const getEditUserProfilePath = (id: any, role: any) => `/client/edit-user/${role}/${id}`;

export const getConfirmExpertPath = (id: any) => `/client/expert-list/${id}/confirm-expert`;

export const getDeclineExpertPath = (id: any) => `/client/expert-list/${id}/decline-expert`;

export const getExpertInfoPath = (id: any) => `/client/expert-list/${id}`;

export const getClientInfoPath = (id: any) => `/consultant/client-list/${id}`;

export const getSuccessExpertActionPath = (action: any) => `/client/expert/success/${action}`;
