import { request } from 'api/client';
import { API_URL } from 'api/urls';

export const getSpecializationsByQuery = async ({ params }: {params?: string}) => {
  const searchQuery = params ? `?search=${params}` : '';

  const response = await request({
    url: `${API_URL}/specialisations${searchQuery}`,
    method: 'GET',
  });
  return response;
};
