import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { getUserById } from '../user/userService';
import authService from './authService';

interface AuthState {
  user: any,
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}
const initialState: AuthState = {
  user: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const getUserByIdAndRole = createAsyncThunk(
  'auth/get-by-id',
  async (data: { role: string, id: string }, thunkAPI) => {
    try {
      return await getUserById(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

// Logout user
export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout();
});

// Login user
export const login = createAsyncThunk(
  'auth/login',
  async (userData: any, thunkAPI) => {
    try {
      const res = await authService.login(userData).then((res) => {
        if (res) {
          thunkAPI.dispatch(getUserByIdAndRole({ id: res.id, role: res.role }));
          return res;
        }
        // Case when for some reason there was no logout on FE, but user either can't login.
        // Typical case - when cookies storage was cleared. User will be redirected to login page after any activity,
        // but won't be able to login without end of session on server.
        thunkAPI.dispatch(logout()).then(() => {
          thunkAPI.dispatch(login(userData));
        });
        return res;
      });
      return res;
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    setUserData: (state) => {
      const id = Cookies.get('id');
      const role = Cookies.get('role');
      const email = Cookies.get('email');
      const firstName = Cookies.get('firstName');
      const lastName = Cookies.get('lastName');
      const externalId = Cookies.get('externalId');
      if (id && role && email) {
        state.user = { id, role, email, firstName, lastName, externalId };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const { id, role, email } = action.payload;
        if (id && role) {
          Cookies.set('role', role);
          Cookies.set('id', id);
          Cookies.set('email', email);
          state.user = action.payload;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.user = {};
      })
      .addCase(getUserByIdAndRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserByIdAndRole.fulfilled, (state, action) => {
        const {
          firstName,
          lastName,
          user,
        } = action.payload;

        state.isLoading = false;
        state.isSuccess = true;
        state.user = { ...user };

        state.user.externalId = user.externalId;
        state.user.firstName = firstName;
        state.user.lastName = lastName || '';
        Cookies.set('firstName', firstName);
        Cookies.set('lastName', lastName || '');
        Cookies.set('externalId', user.externalId);
      })
      .addCase(getUserByIdAndRole.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.user = {};
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        Cookies.remove('id');
        Cookies.remove('role');
        Cookies.remove('email');
        Cookies.remove('firstName');
        Cookies.remove('lastName');
        Cookies.remove('externalId');
        state.isLoading = false;
        state.user = {};
      })
      .addCase(logout.rejected, (state) => {
        Cookies.remove('id');
        Cookies.remove('role');
        Cookies.remove('email');
        Cookies.remove('firstName');
        Cookies.remove('lastName');
        Cookies.remove('externalId');
        state.isLoading = false;
        state.user = {};
      });
  },
});

export const { reset, setUserData } = authSlice.actions;
export default authSlice.reducer;
