import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUserById,
  updateUserById,
  updatePassword,
  addProfileImage,
  deleteProfileImage,
  declineExpertRequest,
  confirmExpertRequest,
  getConsultantProfile,
  updateConsultantProfile,
  requestCode,
  getClientProfile,
  updateClientProfile,
} from 'feature/user/userService';
import { getUserByIdAndRole as getLoggedUserData } from 'feature/auth/authSlice';
import Cookies from 'js-cookie';
import { enqueueSnackbar } from 'feature/snackbar/snackbarSlice';
import { getDateWithoutTimezone } from 'configs/helper';

const initialState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  isLoadingPasswordUpdate: false,
  isErrorPasswordUpdate: false,
  messagePasswordUpdate: '',
  isSuccessPasswordUpdate: false,
  addImageLoading: false,
  addImageSuccess: false,
  addImageError: false,
  addImageMessage: '',
  isLoadingRequestPasswordReset: false,
};

export const getUserByIdAndRole = createAsyncThunk(
  'user/get-by-id',
  async (data: any, thunkAPI) => {
    try {
      return await getUserById({
        id: data.userId,
        role: data.userRole,
      });
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);
export const updateUserByIdAndRole = createAsyncThunk(
  'user/update-by-id',
  async (data: any, thunkAPI) => {
    try {
      return await updateUserById(data)
        .then(() => {
          thunkAPI.dispatch(enqueueSnackbar({
            message: 'changesAreSaved',
            options: { variant: 'success' }
          }));
        })
        .finally(() => {
          const id = Cookies.get('id') as string;
          const role = Cookies.get('role') as string;
          thunkAPI.dispatch(getLoggedUserData({
            id,
            role
          }));
        });
    } catch (error) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'changesNotSaved',
        options: { variant: 'error' }
      }));
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);
export const updateUserPassword = createAsyncThunk(
  'user/update-user-password',
  async (data: { oldPassword: string, password: string }, thunkAPI) => {
    try {
      return await updatePassword(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const addUserProfileImage = createAsyncThunk(
  'user/add-profile-image',
  async (data: any, thunkAPI) => {
    try {
      return await addProfileImage(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const deleteUserProfileImage = createAsyncThunk(
  'user/add-profile-image',
  async (data: any, thunkAPI) => {
    try {
      return await deleteProfileImage(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const declineExpert = createAsyncThunk(
  'user/decline-expert',
  async (data: any, thunkAPI) => {
    try {
      return await declineExpertRequest(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const confirmExpert = createAsyncThunk(
  'user/confirm-expert',
  async (data: any, thunkAPI) => {
    try {
      return await confirmExpertRequest(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getConsultantUserProfile = createAsyncThunk(
  'user/get-consultant-profile',
  async (id: string, thunkAPI) => {
    try {
      return await getConsultantProfile(id);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const updateConsultantUserProfile = createAsyncThunk(
  'user/update-consultant-profile',
  async (data: any, thunkAPI) => {
    try {
      const birthday = data?.data?.birthday;
      if (birthday instanceof Date) {
        data.data.birthday = getDateWithoutTimezone(birthday).toISOString();
      }
      const result = await updateConsultantProfile(data);
      if (result) {
        thunkAPI.dispatch(enqueueSnackbar({ message: 'commonSuccess', options: { variant: 'success' } }));
        thunkAPI.dispatch(getConsultantUserProfile(data.id));
        if (!data.isAdmin) {
          thunkAPI.dispatch(getLoggedUserData(data));
        }
      }
      return result;
    } catch (error) {
      thunkAPI.dispatch(enqueueSnackbar({ message: 'commonError', options: { variant: 'error' } }));
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const requestPasswordReset = createAsyncThunk(
  'user/request-password-reset',
  async (phone: any, thunkAPI) => {
    try {
      await requestCode(phone);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      thunkAPI.rejectWithValue(error.data);
    }
  });

export const getClientUserProfile = createAsyncThunk(
  'user/get-client-profile',
  async (id: string, thunkAPI) => {
    try {
      return await getClientProfile(id);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const updateClientUserProfile = createAsyncThunk(
  'user/update-client-profile',
  async (data: any, thunkAPI) => {
    try {
      const birthday = data?.data?.birthday;
      if (birthday instanceof Date) {
        data.data.birthday = getDateWithoutTimezone(birthday).toISOString();
      }
      const result = await updateClientProfile(data);
      if (result) {
        thunkAPI.dispatch(enqueueSnackbar({ message: 'commonSuccess', options: { variant: 'success' } }));
        thunkAPI.dispatch(getClientUserProfile(data.id));
        if (!data.isAdmin) {
          thunkAPI.dispatch(getLoggedUserData(data));
        }
      }
      return result;
    } catch (error) {
      thunkAPI.dispatch(enqueueSnackbar({ message: 'commonError', options: { variant: 'error' } }));
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    resetImagestate: (state) => {
      state.addImageLoading = false;
      state.addImageSuccess = false;
      state.addImageError = false;
      state.addImageMessage = '';
    },
    resetPasswordState: (state) => {
      state.isLoadingPasswordUpdate = false;
      state.isErrorPasswordUpdate = false;
      state.isSuccessPasswordUpdate = false;
      state.messagePasswordUpdate = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserByIdAndRole.pending, (state) => {
        state.user = null;
        state.isLoading = true;
      })
      .addCase(getUserByIdAndRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(getUserByIdAndRole.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.user = null;
      })
      .addCase(updateUserByIdAndRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserByIdAndRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload as any;
      })
      .addCase(updateUserByIdAndRole.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.user = null;
      })
      .addCase(requestPasswordReset.pending, (state) => {
        state.isLoadingRequestPasswordReset = true;
      })
      .addCase(requestPasswordReset.fulfilled, (state) => {
        state.isLoadingRequestPasswordReset = false;
      })
      .addCase(requestPasswordReset.rejected, (state) => {
        state.isLoadingRequestPasswordReset = false;
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.isLoadingPasswordUpdate = true;
      })
      .addCase(updateUserPassword.fulfilled, (state) => {
        state.isLoadingPasswordUpdate = false;
        state.isErrorPasswordUpdate = false;
        state.isSuccessPasswordUpdate = true;
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.isLoadingPasswordUpdate = false;
        state.isErrorPasswordUpdate = true;
        state.messagePasswordUpdate =
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          action.payload.message || action.payload?.data.message;
      })
      .addCase(addUserProfileImage.pending, (state) => {
        state.addImageLoading = true;
      })
      .addCase(addUserProfileImage.fulfilled, (state) => {
        state.addImageLoading = false;
        state.addImageSuccess = true;
      })
      .addCase(addUserProfileImage.rejected, (state, action) => {
        state.addImageLoading = false;
        state.addImageError = true;
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        state.addImageError = action.payload.message;
      })
      .addCase(declineExpert.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(declineExpert.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = {
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          ...state.user,
          consultant: null,
        };
      })
      .addCase(declineExpert.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
      })
      .addCase(confirmExpert.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(confirmExpert.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(confirmExpert.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isError = true;
        state.message = action.payload as string;
      })
      .addCase(getConsultantUserProfile.pending, (state) => {
        state.addImageLoading = true;
      })
      .addCase(getConsultantUserProfile.fulfilled, (state, action) => {
        state.addImageLoading = false;
        state.addImageSuccess = true;
        state.user = action.payload;
      })
      .addCase(getConsultantUserProfile.rejected, (state, action) => {
        state.addImageLoading = false;
        state.addImageError = true;
        state.user = null;
        state.message = action.payload as string;
      })
      .addCase(getClientUserProfile.pending, (state) => {
        state.addImageLoading = true;
      })
      .addCase(getClientUserProfile.fulfilled, (state, action) => {
        state.addImageLoading = false;
        state.addImageSuccess = true;
        state.user = action.payload;
      })
      .addCase(getClientUserProfile.rejected, (state, action) => {
        state.addImageLoading = false;
        state.addImageError = true;
        state.user = null;
        state.message = action.payload as string;
      });
  },
});

export const {
  reset,
  resetImagestate,
  resetPasswordState
} = userSlice.actions;
export default userSlice.reducer;
