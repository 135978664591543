import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { TableMenuItem } from 'enums/table-menu-item.enum';
import { UserRole } from 'enums/user-role.enum';
import { UserState } from 'enums/user-state.enum';

export default function TableMenuButton({
  id,
  role,
  menuItemsSettings,
  children,
  type,
  clientUserId,
  consultantUserId,
  setActiveRowId,
  state
}: any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation('common');
  const open = Boolean(anchorEl);
  const currentUserRole = Cookies.get('role') as string;
  const isAdmin = /Admin/i.test(currentUserRole);
  const isCoordinator = /Coordinator/i.test(currentUserRole);
  const userId = Cookies.get('id');
  const isDifferentUser = id !== userId;
  const handleClick = (event: any) => {
    setActiveRowId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isActionAllowed = (label: TableMenuItem) => {
    switch (label) {
      case TableMenuItem.Remove:
        return isDifferentUser &&
          ((isAdmin && role !== UserRole.Admin) ||
            (isCoordinator && ![UserRole.Admin, UserRole.Coordinator].includes(role)));
      case TableMenuItem.ReviewAndEdit:
        return isAdmin || isCoordinator;
      case TableMenuItem.Deactivate:
        return isDifferentUser &&
          state === UserState.Active &&
          ((isAdmin && role !== UserRole.Admin) ||
            (isCoordinator && ![UserRole.Admin, UserRole.Coordinator].includes(role)));
      case TableMenuItem.EditConsultant:
        return (isAdmin || isCoordinator) && consultantUserId !== null;
      case TableMenuItem.EditClient:
        return (isAdmin || isCoordinator) && clientUserId !== null;
      case TableMenuItem.ViewDetails:
        return isAdmin || isCoordinator;
      case TableMenuItem.RemoveRequest:
        return isAdmin || isCoordinator;
      case TableMenuItem.Agree:
        return isAdmin || isCoordinator;
      case TableMenuItem.Reject:
        return isAdmin || isCoordinator;
      default:
        return false;
    }
  };
  const handleMenuItemClick = (setting: any) => {
    handleClose();
    setting?.callback({ id, type, role, clientUserId, consultantUserId });
  };
  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="outlined"
      >
        {children}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {menuItemsSettings &&
          menuItemsSettings.map((setting: any) => (
            <MenuItem
              disabled={!isActionAllowed(setting.label)}
              key={setting.label}
              onClick={() => handleMenuItemClick(setting)}
            >
              {t(setting.label)}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

TableMenuButton.propTypes = {
  children: PropTypes.elementType.isRequired,
  role: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  menuItemsSettings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      callback: PropTypes.func,
    })
  ).isRequired,
  type: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  clientUserId: PropTypes.string,
  consultantUserId: PropTypes.string,
  setActiveRowId: PropTypes.elementType.isRequired,
};

TableMenuButton.defaultProps = {
  clientUserId: null,
  consultantUserId: null
};
