import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type StepTitleProps = {
  stepHeader: string;
  stepDescription: string;
};

export function FormStepTitle({ stepHeader, stepDescription }: StepTitleProps) {
  return (
    <>
      <Typography component="h1" variant="h5">
        {stepHeader}
      </Typography>
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          marginBottom: '1.5rem'
        })}
        component="div"
        variant="body2"
      >
        {stepDescription}
      </Typography>
    </>
  );
}
FormStepTitle.propTypes = {
  stepHeader: PropTypes.string.isRequired,
  stepDescription: PropTypes.string.isRequired,
};

type PrimaryBtnProps = {
  btnContent: string;
  onClick?: () => void;
  disabled?: boolean;
};

export function PrimaryBtn({ onClick, btnContent, disabled }: PrimaryBtnProps) {
  return (
    <Button color="primary" variant="contained" fullWidth type="submit" onClick={onClick} disabled={disabled}>
      {btnContent}
    </Button>
  );
}
PrimaryBtn.propTypes = {};
PrimaryBtn.defaultProps = {
  onClick: () => {},
  disabled: false,
};

type PasswordInputAdornmentProp = {
  isPasswordVisible: boolean;
  togglePasswordVisibility: Function;
}

export function PasswordInputAdornment({ isPasswordVisible, togglePasswordVisibility }: PasswordInputAdornmentProp) {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => togglePasswordVisibility(!isPasswordVisible)}
        onMouseDown={() => togglePasswordVisibility(!isPasswordVisible)}
      >
        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
}
PasswordInputAdornment.propTypes = {
  isPasswordVisible: PropTypes.bool.isRequired,
  togglePasswordVisibility: PropTypes.func.isRequired,
};
