const EditGridContainerStyles = {
  border: '1px dashed grey',
  xs: { flexDirection: 'column' },
  md: { flexDirection: 'row' },
  justifyContent: 'center'
};

const EditGridItemStyles = {
  p: 2,
  xs: { p: 2 },
  lg: { p: 4 },
  border: '1px dashed grey',
};

const EditFormStyles = {
  display: 'flex',
  flexDirection: 'column' as 'column',
};

const SelectChipStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 0.5,
};

export {
  EditGridContainerStyles,
  EditGridItemStyles,
  EditFormStyles,
  SelectChipStyles
};
