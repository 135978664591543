export const LIMIT_USER_LIST = 10;

export type Asc = 'ASC';
export type Desc = 'DESC';

type OrderByType = {
  ASC: Asc;
  DESC: Desc;
}
export const ORDER_BY: OrderByType = {
  ASC: 'ASC',
  DESC: 'DESC',
};
