export const styles = {
  AvatarBig: {
    m: 'auto',
    height: '200px',
    width: '100%',
    xs: { height: '300px' },
  },
  BigAvatarBox: {
    p: 2,
    lg: { p: 4 },
    border: '1px dashed grey',
  },

  ImageDescription: {
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: '14px'
  }
};

export const AvatarSmall = (theme: any) => ({
  width: 100,
  height: 100,

  [theme.breakpoints.down('sm')]: {
    width: 72,
    height: 72,
  },
});

export const ButtonStyles = (theme: any) => ({
  color: '#B14E24',
  borderColor: '#B14E24',
  '&:hover': {
    borderColor: '#B14E24',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    p: '5px'
  },
});
