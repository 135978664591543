import { DeactivateUser } from 'models/user';
import { request } from '../../api/client';
import URLS, { API_URL, ROLE_URLS } from '../../api/urls';

export const requestCode = async (phone: string) => {
  const response = await request({
    url: URLS.REQUEST_PASSWORD_RESET,
    method: 'POST',
    data: { phone },
  });

  return response.data;
};

export const confirmCode = async (data: any) => {
  const response = await request({
    url: URLS.CONFIRM_PASSWORD_RESET,
    method: 'POST',
    data,
  });

  return response.data;
};

// Request signUp code
export const requestSignUpCode = async (data: any) => {
  await request({
    url: URLS.REQUEST_SIGN_UP_CODE,
    method: 'POST',
    data
  });
};

export const confirmSignUpCode = async (data: any) => {
  const response = await request({
    url: URLS.CONFIRM_SIGN_UP_CODE,
    method: 'POST',
    data,
  });

  return response.data;
};

// Request phone update
export const requestPhoneUpdate = async (data: any) => {
  await request({
    url: URLS.REQUEST_PHONE_UPDATE,
    method: 'POST',
    data
  });
};

// Update phone
export const updatePhone = async (data: any, id: string) => {
  await request({
    url: `${URLS.USERS}/${id}/phone`,
    method: 'PUT',
    data
  });
};

export const resetPassword = async (data: any) => {
  const response = await request({
    url: URLS.PASSWORD_RESET,
    method: 'POST',
    data,
  });

  return response.data;
};

type DeleteUserRequest = {
  requesterUserId: string,
  deleteUserId: string,
  notify: boolean,
  reason?: string,
};

export const deleteUserById = async (data: DeleteUserRequest) => request({
  url: `${API_URL}/users-requests/delete-user`,
  method: 'POST',
  data,
});

export const deactivateUserById = async (data: DeactivateUser) => {
  const response = await request({
    url: URLS.DEACTIVATE_USER,
    method: 'POST',
    data
  });

  return response;
};
export const getUserById = async ({
  id,
  role
}: any) => {
  const response = await request({
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    url: `${ROLE_URLS[role]}/${id}`,
    method: 'GET',
  });
  return response;
};
export const updateUserById = async ({
  id,
  role,
  data
}: any) => {
  const response = await request({
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    url: `${ROLE_URLS[role]}/${id}`,
    method: 'PUT',
    data,
  });
  return response;
};

export const createReferralLink = async (data: any) => {
  const response = await request({
    url: URLS.REFERRAL_LINK,
    method: 'POST',
    data,
  });

  return response;
};

export const checkReferralLink = async (data: any) => {
  const response = await request({
    url: `${URLS.REFERRAL_LINK_STATUS}?link=${data}`,
    method: 'GET',
  });

  return response;
};
export const updatePassword = async (data: any) => {
  const response = await request({
    url: `${URLS.CHANGE_USER_PASSWORD}`,
    method: 'POST',
    data,
  });
  return response;
};

export const addProfileImage = async ({
  id,
  role,
  formData
}: any) => {
  const response = await request({
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    url: `${ROLE_URLS[role]}/${id}/profile-picture`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
};

export const deleteProfileImage = async ({
                                        id, role
                                      }: any) => {
  const response = await request({
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    url: `${ROLE_URLS[role]}/${id}/profile-picture`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
};

export const declineExpertRequest = async (data: any) => {
  const response = await request({
    url: URLS.REQUESTS_CHANGE_CONSULTANT,
    method: 'POST',
    data,
  });

  return response;
};

export const confirmExpertRequest = async (data: any) => {
  const response = await request({
    url: URLS.REQUESTS_REQUEST_CONSULTANT,
    method: 'POST',
    data,
  });

  return response;
};

export const getConsultantProfile = async (id: string) => {
  const response = await request({
    url: `${URLS.CONSULTANTS_LIST}/${id}`,
    method: 'GET',
  });

  return response;
};

export const updateConsultantProfile = async ({ id, data, isAdmin }: { id: string, data: any, isAdmin: boolean }) => {
  const response = await request({
    url: `${URLS.CONSULTANTS_LIST}/${id}${isAdmin ? '' : '/profile'}`,
    method: 'PUT',
    data
  });

  return response;
};

export const getClientProfile = async (id: string) => {
  const response = await request({
    url: `${ROLE_URLS.Client}/${id}`,
    method: 'GET',
  });

  return response;
};

export const updateClientProfile = async ({ id, data, isAdmin }: { id: string, data: any, isAdmin: boolean }) => {
  const response = await request({
    url: `${ROLE_URLS.Client}/${id}${isAdmin ? '' : '/profile'}`,
    method: 'PUT',
    data
  });

  return response;
};
