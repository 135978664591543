import { Avatar, Box, Grid, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import userIcon from 'assets/user-icon.svg';
import userPic from 'assets/user-pic.svg';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { resetImagestate } from 'feature/user/userSlice';
import { MAX_IMAGE_SIZE } from 'configs';
import { AvatarSmall, ButtonStyles, styles } from './styles';

type IProps = {
  imageUrl: string | undefined,
  handler: (data: unknown) => void;
  isViewOnly?: boolean;
  isClientProfile?: boolean
  deletePhoto?: () => void
};

export default function UserProfileImage({
  imageUrl,
  handler,
  isViewOnly,
  isClientProfile,
  deletePhoto
}: IProps) {
  const [image, setImage] = useState<any>(imageUrl);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);

  useEffect(() => () => {
    dispatch(resetImagestate());
  }, []);

  useEffect(() => {
    if (!imageUrl?.includes('base64')) {
      setImage(imageUrl);
    }
  }, [imageUrl]);

  const checkFileSize = (size: any) => size > MAX_IMAGE_SIZE;
  const checkFileType = (name: any) => {
    const allowedExtensions =
      /(\.jpg|\.jpeg|\.png|\.svg)$/i;
    return !allowedExtensions.exec(name);
  };

  const handleErrorMessage = (error1: any, error2: any) => {
    if (error1) enqueueSnackbar(t('fileSizeError'), { variant: 'error' });
    if (error2) enqueueSnackbar(t('fileTypeError'), { variant: 'error' });
  };

  const handleUpload = (e: any) => {
    const file = e.target.files[0];

    const isSizeValid = checkFileSize(file.size);
    const isTypeValid = checkFileType(file.name);

    if (isSizeValid || isTypeValid) {
      handleErrorMessage(isSizeValid, isTypeValid);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setImage(reader.result);

      if (isClientProfile) {
        handler(file);
      } else {
        const formData = new FormData();
        formData.append('file', file);
        handler(formData);
      }
    };
  };

  const handleDelete = () => {
    setImage('');
    if (deletePhoto) {
      deletePhoto();
    }
  };

  return (
    isClientProfile ? (
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Avatar
            alt="User profile icon"
            src={image || userPic}
            sx={AvatarSmall}
          />
        </Grid>
        <Grid item xs={9}>
          <Button variant="outlined" component="label" sx={ButtonStyles}>
            {t('addPhoto')}
            <input
              onChange={handleUpload}
              hidden
              accept="image/*"
              type="file"
            />
          </Button>
          <Button variant="text" onClick={handleDelete} sx={ButtonStyles}>{t('delete')}</Button>
          <p>
            <Typography variant="caption" sx={styles.ImageDescription}>
              Оберіть файл у форматі jpg, jpeg або png. Рекомендований розмір фото: 500х500, максимальний розмір файлу: 5 Мб
            </Typography>
          </p>
        </Grid>
      </Grid>
    ) : (
      <>
        {image ? (
          <Avatar
            variant="square"
            src={image}
            sx={styles.AvatarBig}
          />
        ) : (
          <Box
            sx={styles.BigAvatarBox}
          >
            <img alt="User profile icon" src={userIcon} />
          </Box>
        )}
        {!isViewOnly &&
          <>
            <Button sx={{ mt: 2 }} fullWidth variant="contained" component="label">
              {t('uploadPhoto')}
              <input
                onChange={handleUpload}
                hidden
                accept="image/*"
                type="file"
              />
            </Button>
            <Button variant="text" onClick={handleDelete} sx={ButtonStyles}>{t('delete')}</Button>
          </>}
      </>
    )
  );
}

UserProfileImage.defaultProps = {
  isViewOnly: false,
  isClientProfile: false,
  deletePhoto: () => {}
};
