import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'feature/snackbar/snackbarSlice';
import { getUsers } from './usersService';

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  users: [],
};

// Search users
export const searchUsers = createAsyncThunk(
  'user/search-users',
  async (data: any, thunkAPI) => {
    try {
      return await getUsers(data);
    } catch (error) {
      thunkAPI.dispatch(enqueueSnackbar({ message: 'commonError', options: { variant: 'error' } }));
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload;
      })
      .addCase(searchUsers.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.users = [];
      });
  },
});

export const { reset } = usersSlice.actions;
export default usersSlice.reducer;
