import React from 'react';
import { Drawer } from '@mui/material';
import PropTypes from 'prop-types';
import sx from './MobileDrawer.styles';

export default function MobileDrawer({
  handleDrawerToggle,
  window,
  drawer,
  open
}: any) {
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Drawer
      container={container}
      variant="temporary"
      open={open}
      onClose={handleDrawerToggle}
      ModalProps={{ keepMounted: true }}
      sx={sx}
    >
      {drawer}
    </Drawer>
  );
}

MobileDrawer.defaultProps = {
  window: undefined
};

MobileDrawer.propTypes = {
  window: PropTypes.element,
  drawer: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};
