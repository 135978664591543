import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Menu, MenuItem, Box, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logout } from 'feature/auth/authSlice';
import { NavbarBox } from 'components/NavbarUserMenu.styles';
import { useAppDispatch, useAppSelector } from 'hooks';
import userPic from 'assets/user-pic.svg';
import { ROLE_URLS } from 'api/urls';
import { getUserFromUserStore } from 'feature/user/userSelectors';

export default function NavbarMenu({
  children
}: any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUserId = Cookies.get('id');
  const currentUserRole = Cookies.get('role') as string;

  const { user } = useAppSelector(getUserFromUserStore);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    e.preventDefault();
    setAnchorEl(null);
  };
  const handleProfile = (e: any) => {
    navigate(`${(currentUserRole !== 'Client' && currentUserRole !== 'Consultant') ? 'admin/users/' : `${currentUserRole.toLowerCase()}/`}edit-user/${currentUserRole}/${currentUserId}`);
    handleClose(e);
  };
  const handleLogout = (e: any) => {
    navigate('/', { state: {} });
    dispatch(logout());
    handleClose(e);
  };

  return (
    <>
      <Box
        sx={NavbarBox}
        component="div"
        onClick={handleMenu}
        aria-controls="menu-appbar"
        aria-haspopup="true"
      >
        <Avatar
          alt="User profile icon"
          src={(user?.photoId) ? `${(ROLE_URLS as any)[currentUserRole]}/${currentUserId}/profile-picture` : userPic}
        />
        {children}
      </Box>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem data-testid="profile" onClick={handleProfile}>
          Профіль
        </MenuItem>
        <MenuItem onClick={handleLogout}>Вийти</MenuItem>
      </Menu>
    </>
  );
}

NavbarMenu.propTypes = {
  children: PropTypes.node
};
NavbarMenu.defaultProps = {
  children: null
};
