import React from 'react';
import { ListItemButton as ListItemButtonMUI, ListItemAvatar, ListItemText, Typography, Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import { ROLE_URLS } from 'api/urls';
import userIcon from 'assets/user-icon.svg';
import { useTranslation } from 'react-i18next';
import { sxStyles } from './ListItem.styles';

export function ListItem(props: any) {
  const { client, onClick } = props;
  const { t } = useTranslation(['common']);

  const imageSrc = client.photoId ?
    /* @ts-expect-error TS(2571): Object is of type 'unknown'. */
    `${ROLE_URLS[client.user.role]}/${client.user.id}/profile-picture`
    : userIcon;

  const fullName = `${client.firstName} ${client.lastName || ''}`;

  return (
    <ListItemButtonMUI sx={sxStyles.item} onClick={onClick}>
      <ListItemAvatar sx={sxStyles.img}>
        <Avatar alt={fullName} src={imageSrc} />
      </ListItemAvatar>

      <ListItemText
        primary={<Typography variant="subtitle2" sx={{ fontSize: '12px', color: '#a6a6a6' }}>{t('fullName')}</Typography>}
        sx={sxStyles.itemText}
        secondary={
          <Typography
            component="span"
            variant="body1"
            color="text.primary"
          >
            {fullName}
          </Typography>
        }
      />

      <ListItemText
        primary={<Typography variant="subtitle2" sx={{ fontSize: '12px', color: '#a6a6a6' }}>{t('consultationStartDate')}</Typography>}
        sx={sxStyles.itemText}
        secondary={
          <Typography
            component="span"
            variant="body1"
            color="text.secondary"
          >
            {new Date(client.consultantStartDate).toLocaleDateString()}
          </Typography>
        }
      />
    </ListItemButtonMUI>
  );
}

ListItem.propTypes = {
  client: PropTypes.shape({
    consultantStartDate: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    photoId: PropTypes.string,
    user: PropTypes.shape({
      role: PropTypes.string,
      id: PropTypes.string,
    })
  }).isRequired,
  onClick: PropTypes.func.isRequired
};
