import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSpecializationsByQuery } from './commonService';

const initialState = {
  specialisations: {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    data: []
  }
};

export const getSpecializations = createAsyncThunk(
  'specialisations/get',
  async (data: any, thunkAPI) => {
    try {
      return await getSpecializationsByQuery(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    reset: (state) => {
      state.specialisations = initialState.specialisations;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpecializations.pending, (state) => {
        state.specialisations.data = [];
        state.specialisations.isLoading = true;
      })
      .addCase(getSpecializations.fulfilled, (state, action) => {
        state.specialisations.isLoading = false;
        state.specialisations.isSuccess = true;
        state.specialisations.data = action.payload.data;
      })
      .addCase(getSpecializations.rejected, (state, action) => {
        state.specialisations.isLoading = false;
        state.specialisations.isError = true;
        state.specialisations.message = action.payload as string;
        state.specialisations.data = [];
      });
  },
});

export const { reset } = commonSlice.actions;
export default commonSlice.reducer;
