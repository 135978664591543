import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

export default function LeftTitle({
  title,
  userRole
}: any) {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Grid item xs={5} lg={7}>
      <Grid
        container
        sx={{
          p: 2,
          pl: 0,
          pr: 0,
        }}
      >
        <Grid item xs={12}>
          <Typography
            data-testid="title"
            fontWeight={700}
            variant={matches ? 'h5' : 'h6'}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">{userRole}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
LeftTitle.propTypes = {
  title: PropTypes.string.isRequired,
  userRole: PropTypes.string,
};
LeftTitle.defaultProps = { userRole: '' };
