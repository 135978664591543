import React, { useState } from 'react';
import { AppBar, Box, CssBaseline, Divider, IconButton, List, ListItem, ListItemText, Typography, Toolbar, Collapse } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import logo from 'assets/logo.svg';
import { NAV_MENU_CONFIG, USERS_NAV_MENU_CONFIG, BREADCRUMBS_CONFIG } from 'configs';
import NavbarUserMenu from 'components/NavbarUserMenu';
import MobileDrawer from 'components/MobileDrawer/MobileDrawer';
import DesktopDrawer from 'components/DesktopDrawer/DesktopDrawer';
import {
  AppBarStyles,
  ChildrenContainerStyles,
  LogoutButtonStyles,
  NavLinkStyles,
  NavBarMenuTypographyStyles,
  NavBarArrowStyles,
  MobileDrawerStyles,
  DrawerHeader,
  ToolbarStyle
} from 'components/PageLayout.styles';
import { getUserFromAuthStore } from 'feature/auth/authSelectors';

const ROLES = ['Consultant', 'Client'];

type InviteUsersProps = {
  openUsersSubmenu: boolean;
};

function InviteUsers({ openUsersSubmenu }: InviteUsersProps) {
  // TODO: invite users feature is not fully functional, see https://jira.softserveinc.com/browse/CRSV-608
  // eslint-disable-next-line no-constant-condition
  if (true) {
    return null;
  }
  return (
    <Collapse in={openUsersSubmenu} timeout="auto" unmountOnExit>
      <List component="div" disablePadding sx={{ marginLeft: '15px' }}>
        {BREADCRUMBS_CONFIG.map(({ title, url }) => (
          <ListItem button key={url}>
            <NavLink style={NavLinkStyles} to={url}>
              <ListItemText primary={title} />
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
}

export default function ResponsiveDrawer({
  children
}: any) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const role = Cookies.get('role') as string;

  const [openUsersSubmenu, setOpenUsersSubmenu] = useState(true);
  const { firstName = '', lastName = '' } = useSelector(getUserFromAuthStore);

  const handleClick = () => {
    // TODO: invite users feature is not fully functional, see https://jira.softserveinc.com/browse/CRSV-608
    setOpenUsersSubmenu(false);
    if (window.screen.width < 900) {
      setMenuOpen(false);
    }
  };

  const handleDrawerToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const getUserName = () => {
    if (role === 'Admin') {
      return `Вітаю ${firstName}`;
    }

    return `${firstName} ${lastName}`;
  };

  const drawer = (
    <div>
      <Toolbar sx={ToolbarStyle}>
        <img src={logo} alt="footer logo" />
        <Box sx={DrawerHeader}>
          <IconButton onClick={handleDrawerToggle}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />
      <List component="nav">
        <ListItem button onClick={handleClick}>
          <NavLink style={NavLinkStyles} to={USERS_NAV_MENU_CONFIG[0].url}>
            <ListItemText primary={USERS_NAV_MENU_CONFIG[0].title} />
          </NavLink>
        </ListItem>
        <InviteUsers openUsersSubmenu={openUsersSubmenu} />
        {NAV_MENU_CONFIG.map(({
          title,
          url
        }: any) => (
          <ListItem key={url} button onClick={handleClick}>
            <NavLink style={NavLinkStyles} to={url}>
              <ListItemText primary={title} />
            </NavLink>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={AppBarStyles(true)}>
        <Toolbar>
          <img src={logo} alt="logo" />
          {!ROLES.includes(role) &&
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={LogoutButtonStyles}
          >
            <MenuIcon />
          </IconButton>}
          <Box sx={{ flexGrow: 1 }} />
          <NavbarUserMenu>
            <Typography sx={NavBarMenuTypographyStyles} data-testid="username">
              {getUserName()}
            </Typography>
            <ArrowDropDownIcon sx={NavBarArrowStyles} />
          </NavbarUserMenu>
        </Toolbar>
      </AppBar>

      {!ROLES.includes(role) &&
        <Box
          component="nav"
          sx={MobileDrawerStyles(isMenuOpen)}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <MobileDrawer
            open={isMenuOpen}
            drawer={drawer}
            handleDrawerToggle={handleDrawerToggle}
          />
          <DesktopDrawer
            drawer={drawer}
            open={isMenuOpen}
          />
        </Box>}
      <Box component="main" sx={ChildrenContainerStyles}>
        {children}
      </Box>
    </Box>
  );
}
ResponsiveDrawer.propTypes = { children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired };
