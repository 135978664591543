import { REQUEST_TYPE, USER_ROLES, STATUS_TYPE } from 'configs';
import { UserState } from 'enums/user-state.enum';

function getRandomStr() {
  if (typeof window.crypto?.randomUUID === 'function') {
    return window.crypto.randomUUID();
  }
  return Math.random().toString().replace('.', '_');
}

export const USER_ACCOUNT_DELETED_PATH = '/user-account-deleted-successfully';
export const USER_ACCOUNT_DELETED_SUCCESSFULLY_PATH = `${USER_ACCOUNT_DELETED_PATH}/${getRandomStr()}`;

export const CONSULTANT_ACCOUNT_DEACTIVATED_PATH = '/consultant-account-deactivated-successfully';
export const CONSULTANT_ACCOUNT_DEACTIVATED_SUCCESSFULLY_PATH = `${CONSULTANT_ACCOUNT_DEACTIVATED_PATH}/${getRandomStr()}`;

export const mapColumnSettingKey = (columnSettingKey: string, row: any) => {
  switch (columnSettingKey) {
    case 'requester':
      if (row?.user?.state === UserState.Deleted) {
        return row?.user?.id || '';
      }
      return `${row.userFirstName || ''} ${row.userLastName || ''}`;
    case 'deactivatedUser':
      return `${row.deactivatedUser?.userFirstName || ''} ${row.deactivatedUser?.userLastName || ''}`;
    case 'deletedUser':
      return `${row?.deletedUser?.id}`;
    case 'role':
    case 'userRole':
      // @ts-ignore
      return USER_ROLES[row[columnSettingKey]];
    case 'violenceTypes':
      return row[columnSettingKey]?.join(', ');
    case 'type':
      // @ts-ignore
      return row?.disabledUserRole ? REQUEST_TYPE[`${row[columnSettingKey]}${row?.disabledUserRole}`] : REQUEST_TYPE[row[columnSettingKey]];
    case 'consultant':
      if (row?.requestedConsultant?.user?.state === UserState.Deleted) {
        return row?.requestedConsultant?.user?.id || '';
      }
      return `${row?.requestedConsultant?.firstName || ''} ${row?.requestedConsultant?.lastName || ''}`;
    case 'status':
      // @ts-ignore
      return STATUS_TYPE[row[columnSettingKey]];
    default:
      return row[columnSettingKey];
  }
};

export function formatApiPhoneStr(phone: string) {
  const phoneNumberIsNotValid = 'Phone number is not valid!';
  if (!phone) {
    return phoneNumberIsNotValid;
  }
  return `+${phone.substring(0, 2)}(${phone.substring(2, 5)})${phone.substring(5, 8)}-${phone.substring(8, 10)}-${phone.substring(10)}`;
}
