import { request } from '../../api/client';
import URLS, { ROLE_URLS } from '../../api/urls';

export const getClientConsultantRelations = async (data: any) => {
  const response = await request({
    url: URLS.SEARCH_CLIENT_CONSULTANT,
    method: 'GET',
    params: data,
  });

  return response;
};

export const updateClientConsultantRelation = async (data: any) => {
  const response = await request({
    url: URLS.SEARCH_CLIENT_CONSULTANT,
    method: 'PUT',
    data,
  });

  return response;
};

export const createClient = async (data: any) => {
  const response = await request({
    url: 'test',
    method: 'POST',
    params: data,
  });

  return response;
};

export const deleteClientConsultantRelation = async (id: any) => {
  const response = await request({
    url: `${URLS.SEARCH_CLIENT_CONSULTANT}`,
    method: 'DELETE',
    data: {
      clientUserId: id
    }
  });

  return response;
};

export const getConsultantInfo = async (id: string) => {
  const response = await request({
    url: `${ROLE_URLS.Consultant}/${id}`,
    method: 'GET',
  });

  return response;
};

export const getConsultantClientsList = async (data: any) => {
  const response = await request({
    url: `${ROLE_URLS.Consultant}/${data?.id}/clients`,
    method: 'GET',
    params: data?.params,
  });

  return response;
};

export const getClientInfo = async (id: any) => {
  const response = await request({
    url: `${ROLE_URLS.Client}/${id}`,
    method: 'GET',
  });

  return response;
};

export const getClientsList = async (queryParams = {}, fetchOptions = {}) => {
  queryParams = Object.fromEntries(Object.entries(queryParams).filter(([, value]) => value));
  const response = await request({
    ...fetchOptions,
    url: `${URLS.RELATIONS_CLIENT_AUTOCOMPLETE}`,
    method: 'GET',
    params: { ...queryParams },
  });
  return response.data;
};

export const mapClientsToOptions = (response: any) => {
  if (Array.isArray(response)) {
    return response
    .filter(({ user, firstName, lastName }) => user.id && (firstName || lastName))
    .map(({ user, firstName, lastName }) => ({
      user,
      firstName: firstName || '',
      lastName: lastName || '',
    }))
    .map(({ user, firstName, lastName }) => ({
      label: `${firstName} ${lastName} (${user.phone})`,
      value: user.id,
    }));
  }
  return [];
};

export const getConsultantsList = async (queryParams = {}, fetchOptions = {}) => {
  queryParams = Object.fromEntries(Object.entries(queryParams).filter(([, value]) => value));
  const response = await request({
    ...fetchOptions,
    url: URLS.SEARCH_USERS,
    method: 'GET',
    params: { ...queryParams, role: ['Consultant'], },
  });
  return response.data;
};

export const mapConsultantsToOptions = (response: any) => {
  if (Array.isArray(response)) {
    return response
    .filter(({ firstName, lastName, id }) => id && (firstName || lastName))
    .map(({ firstName, lastName, id, phone }) => ({
      id,
      phone,
      firstName: firstName || '',
      lastName: lastName || '',
    }))
    .map(({ phone, id, firstName, lastName }) => ({
        label: `${firstName} ${lastName} (${(phone)})`,
        value: id,
      }));
  }
  return [];
};

export const getClientRequestsApi = async () => {
  const response = await request({
    url: URLS.REQUESTS_CLIENT,
    method: 'GET',
  });

  return response;
};
