const NavbarIconButton = {
  justifySelf: 'flex-end',
  border: '1px solid #991E66'
};

const NavbarBox = {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row'
};
export {
  NavbarIconButton,
  NavbarBox
};
