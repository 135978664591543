import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#83639E',
    },
    secondary: {
      main: '#B14E24'
    },
  },
  typography: {},
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-display: swap;
          font-family: 'Open Sans', sans-serif;
          font-size: 16;
          font-style: normal;
          font-weight: 400;
        }
      `,
    },
  },
});
