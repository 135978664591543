import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'feature/auth/authSlice';
import userReducer from 'feature/user/userSlice';
import usersReducer from 'feature/users/usersSlice';
import requestsReducer from 'feature/clientRequests/clientRequestsSlice';
import requestReducer from 'feature/clientRequest/clientRequestSlice';
import relationsReducer from 'feature/relations/relationsSlice';
import consultantsReducer from 'feature/consultants/consultantsSlice';
import snackbarReducer from 'feature/snackbar/snackbarSlice';
import clientsReducer from 'feature/clients/clientsSlice';
import commonReducer from 'feature/common/commonSlice';
import { error401Middleware, error500Middleware } from 'feature/middlewares';
import appStateReducer from 'feature/state/appStateSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    users: usersReducer,
    appState: appStateReducer,
    requests: requestsReducer,
    request: requestReducer,
    relations: relationsReducer,
    consultants: consultantsReducer,
    snackbar: snackbarReducer,
    clients: clientsReducer,
    common: commonReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([error401Middleware, error500Middleware]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
