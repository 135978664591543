export const sxStyles = {
  item: {
    backgroundColor: 'white',
    mb: '0.75rem',
    borderRadius: '12px',
    p: '0.25rem',
    alignItems: 'normal'
  },
  itemText: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  img: {
    alignSelf: 'center'
  }
};
