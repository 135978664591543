import React, { useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getConsultantDetails } from 'feature/consultants/consultantDetailSlice';
import { getConsultantDetailSelector } from 'feature/consultants/consultantDetailSelector';
import BreadcrumbsComponent from 'components/Breadcrumbs/Breadcrumbs';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Spinner from 'components/shared/Spinner';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import userIcon from 'assets/user-icon.svg';
import { STATUS_TYPES } from 'configs';
import {
  getUserFromAuthStore,
} from 'feature/auth/authSelectors';
import {
  BrandButton,
  CancelButton,
  ConsultantMediaStyles,
  ExpertAboutTitleStyles,
  ExpertAboutContentStyles,
  ExpertProfileContentStyles,
  ExpertProfileImgStyles,
} from 'components/ExpertCard.styles';
import { ROLE_URLS } from 'api/urls';
import { getConfirmExpertPath, getDeclineExpertPath } from 'configs/routes';
import { getClientConsultantFromRelationsStore } from 'feature/relations/relationsSelectors';
import { getClientRequests } from 'feature/relations/relationsSlice';
import { getUserByIdAndRole } from 'feature/user/userSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TitleStyles, ChipContainerStyles, ChipStyles } from '../components/shared/styles';

export default function ExpertInfo() {
  const { consultantId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const consultantDetail = useAppSelector(getConsultantDetailSelector);
  const authDate = useAppSelector(getUserFromAuthStore);
  const clientConsultantRequests = useAppSelector(getClientConsultantFromRelationsStore);
  const currentUserData: any = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (authDate.id && authDate.role) {
      dispatch(
        getUserByIdAndRole({
          userId: authDate.id,
          userRole: authDate.role,
        })
      );
    }
  }, [authDate]);

  useEffect(() => {
    Promise.allSettled([
      dispatch(getConsultantDetails(consultantId as string)),
      dispatch(getClientRequests())
    ]);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDecline = () => {
    const path = getDeclineExpertPath(consultantDetail.user.id);

    navigate(path);
  };

  const handleConfirm = () => {
    const path = getConfirmExpertPath(consultantDetail.user.id);

    navigate(path);
  };

  const breadcrumbsList = [
    {
      link: '/client/expert-list',
      title: t('consultantList'),
      isDisabled: false
    },
    {
      link: '',
      title: t('consultantProfile'),
      isDisabled: false
    },
  ];

  const clientRemoveConsultantRequests = clientConsultantRequests.data?.find((request: any) => request.type === 'ChangeConsultant' && request.status === STATUS_TYPES.new);

  return (
    <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
      <Grid item xs={12} md={9}>
        <BreadcrumbsComponent items={breadcrumbsList} />
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography sx={{ pb: 2 }} variant="h4">
          {t('consultantProfile')}
        </Typography>

        {isEmpty(consultantDetail) ? (<Spinner />) : (
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Card sx={ConsultantMediaStyles}>
              <Grid item xs={12} md={3}>
                <CardContent sx={ExpertProfileContentStyles}>
                  <Stack spacing={3}>
                    <CardMedia
                      component="img"
                      sx={ExpertProfileImgStyles}
                      image={consultantDetail.photoId ?
                        // @ts-expect-error TS(2571): Object is of type 'unknown'.
                        `${ROLE_URLS[consultantDetail.user.role]}/${consultantDetail.user.id}/profile-picture`
                        : userIcon}
                      alt="Фото Консультанта"
                    />

                    {currentUserData?.consultant?.user.id === consultantDetail.user.id ? (
                      <CancelButton
                        variant="contained"
                        size="large"
                        onClick={handleDecline}
                        disabled={clientRemoveConsultantRequests}
                      >
                        <Typography
                          sx={{
                            fontSize: '15px',
                          }}
                        >
                          {t('decline')}
                        </Typography>
                      </CancelButton>
                    ) : (
                      <BrandButton
                        variant="contained"
                        size="large"
                        onClick={handleConfirm}
                        disabled={currentUserData?.consultant && currentUserData.consultant.user.id !== consultantDetail.user.id}
                      >
                        <Typography
                          sx={{
                            fontSize: '15px',
                          }}
                        >
                          {t('choose')}
                        </Typography>
                      </BrandButton>
                    )}
                  </Stack>
                </CardContent>
              </Grid>

              <Grid item md={9}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                >
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="h4" variant="h4">
                      {consultantDetail.firstName} {consultantDetail.lastName}
                    </Typography>
                    <Typography
                      sx={TitleStyles}
                      variant="h6"
                      color="#000"
                      component="h6"
                    >
                      {t('specialisation')}
                    </Typography>
                    <Stack
                      spacing={1}
                      direction="row"
                      sx={ChipContainerStyles}
                    >
                      {consultantDetail?.specialisations &&
                        consultantDetail.specialisations.map((skill: any) => (
                          <Chip
                            key={skill}
                            label={skill}
                            color="primary"
                            style={ChipStyles}
                          />
                        ))}
                    </Stack>
                    <Box>
                      <Typography
                        sx={ExpertAboutTitleStyles}
                        variant="h6"
                        color="#000"
                        component="h6"
                      >
                        {t('aboutConsultant')}
                      </Typography>
                      <Typography paragraph sx={ExpertAboutContentStyles}>
                        {consultantDetail.aboutMe}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={ExpertAboutTitleStyles}
                        variant="h6"
                        color="#000"
                        component="h6"
                      >
                        {t('consultantExperience')}
                      </Typography>
                      <Typography paragraph sx={ExpertAboutContentStyles}>
                        {consultantDetail.experience}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Grid>
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
