import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function ConfirmationDialogAfterChanges({
  open,
  closeHandler,
  notSave,
  okHandler
}: any) {
  const { t } = useTranslation(['common']);

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{t('unsavedChanges')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('unsavedChangesText')}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'center', paddingBottom: 2 }}
      >
        <Button fullWidth variant="outlined" onClick={closeHandler}>
          {t('back')}
        </Button>
        <Button fullWidth variant="outlined" onClick={notSave}>
          {t('notSaveChanges')}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={okHandler}
          autoFocus
        >
          {t('saveChanges')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogAfterChanges.propTypes = {
  open: PropTypes.bool.isRequired,
  okHandler: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  notSave: PropTypes.func.isRequired
};
