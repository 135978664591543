import React, { useEffect } from 'react';
import isEqual from 'lodash.isequal';

export function useSaveChangesDialogOnExit(
  initialValues: any,
  values: any,
  setter: React.Dispatch<React.SetStateAction<boolean>>,
  setBackButtonClicked: React.Dispatch<React.SetStateAction<boolean>>
) {
  const alertUser = (e: any) => {
    if (!isEqual(initialValues, values)) {
      if (e.type === 'beforeunload') {
        e.returnValue = '';
      } else {
        setter(true);
        setBackButtonClicked(true);
      }
    }
  };
  useEffect(() => {
    // @ts-ignore
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', alertUser);
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('popstate', alertUser);
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [initialValues, values]);
}
