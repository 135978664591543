import { isRejectedWithValue } from '@reduxjs/toolkit';
import { logout } from 'feature/auth/authSlice';
import { enqueueSnackbar } from 'feature/snackbar/snackbarSlice';

export const error401Middleware = (api: any) => (next: any) => (action: any) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action) && action.payload.statusCode === 401 && action.payload.message === 'Unauthorized') {
    api.dispatch(logout());
  }

  return next(action);
};
export const error500Middleware = (api: any) => (next: any) => (action: any) => {
  if (isRejectedWithValue(action) && action.payload.statusCode === 500) {
    api.dispatch(enqueueSnackbar({ message: 'commonError', options: { variant: 'error' } }));
    api.dispatch(logout());
  }

  return next(action);
};
