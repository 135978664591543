import React, { useEffect } from 'react';
import {
  getClientRequest,
  getClientData,
  clearName,
  clearRequest,
  getDeactivatedUserData,
  getDeactivatorData,
} from 'feature/clientRequest/clientRequestSlice';
import { Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getRequestFromStore } from 'feature/clientRequest/clientRequestSelector';
import Table from 'components/Table';
import TableHeaderPanel from 'components/TableHeaderPanel';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TableColumnSettings } from 'models/table-column-settings.model';
import { RequestType } from 'enums/request-type.enum';

export default function RequestDetails() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { request } = useAppSelector(getRequestFromStore);
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getClientRequest(id as string));
    return () => {
      dispatch(clearName());
      dispatch(clearRequest());
    };
  }, []);
  function getUserWhoTriggeredAction() {
    if (request?.user?.id) {
      dispatch(getDeactivatorData({ id: request.user.id, userRole: request.user.role }));
    }
  }
  useEffect(() => {
    if (request?.type === RequestType.Deactivate) {
      if (request?.deactivatedUser?.id) {
        dispatch(getDeactivatedUserData({ id: request.deactivatedUser.id, userRole: request.deactivatedUser.role }));
      }
      getUserWhoTriggeredAction();
    } else if (request?.type === RequestType.Delete) {
      getUserWhoTriggeredAction();
    } else if (request?.type !== RequestType.Deactivate && request?.user?.id) {
      dispatch(getClientData(request?.user?.id));
    }
  }, [request?.user?.id]);
  const requestTypeIsRequestConsultant = request.type === RequestType.RequestConsultant;
  function getSubjectColumn() {
    if (request?.deletedUser?.id) {
      return [
        {
          name: request?.deletedUser ? `deleted${request.deletedUser.role}` : 'deletedUser',
          key: 'deletedUser'
        },
      ];
    }
    if (request?.deactivatedUser?.id) {
      return [
        {
          name: request?.deactivatedUser?.role ? `deactivated${request.deactivatedUser.role}` : 'deactivatedUser',
          key: 'deactivatedUser'
        },
      ];
    }
    return [];
  }
  const tableColumnsSettings: TableColumnSettings[] = [
    {
      name: requestTypeIsRequestConsultant ? 'senderClient' : 'sender',
      key: 'requester',
    },
    ...getSubjectColumn(),
    { name: 'date', key: 'createdAt' },
  ];
  const itemToInject = requestTypeIsRequestConsultant
    ? { name: 'consultant', key: 'consultant' }
    : { name: 'message', key: 'message' };
  const lastColumnIndex = tableColumnsSettings.length - 1;
  tableColumnsSettings.splice(lastColumnIndex, 0, itemToInject);
  const getTableHeader = () => {
    switch (request?.type) {
      case RequestType.RequestConsultant:
        return 'requestConsultant';
      case RequestType.Deactivate:
        return request?.deactivatedUser?.role ?
          `${request.deactivatedUser.role.toLowerCase()}Deactivation` :
          'userDeactivation';
      case RequestType.ChangeConsultant:
        return 'consultantChange';
      case RequestType.Delete:
        return request?.deletedUser?.role ?
          `${request.deletedUser.role.toLowerCase()}DeleteProfileAndPersonalDate` :
          'userDeleteProfileAndPersonalDate';
      default:
        return '';
    }
  };
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      height="calc(100vh - 150px)"
    >
      {/* @ts-ignore */}
      <TableHeaderPanel
        header={t(getTableHeader())}
        showSearch={false}
      />
      {/* @ts-ignore */}
      <Table
        rows={[request]}
        tableColumnsSettings={tableColumnsSettings}
        onPageChange={() => { }}
        onSortFieldChange={() => { }}
        whiteSpace="initial"
      />
      <Button
        onClick={() => {
          navigate('/admin/requests');
          dispatch(clearName());
        }}
        sx={{ position: 'absolute', bottom: 8, right: 8, pl: 4, pr: 4 }}
        variant="contained"
      >
        {t('back')}
      </Button>
    </Box>
  );
}
