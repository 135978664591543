import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Button, Link, Paper, TextField } from '@mui/material';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { ROLE_URLS } from 'api/urls';
import { getConsultantDetailSelector } from 'feature/consultants/consultantDetailSelector';
import { getConsultantDetails } from 'feature/consultants/consultantDetailSlice';
import BreadcrumbsComponent from 'components/Breadcrumbs/Breadcrumbs';
import { confirmExpert, getUserByIdAndRole } from 'feature/user/userSlice';
import { getUserFromAuthStore } from 'feature/auth/authSelectors';
import { getEditUserProfilePath, getSuccessExpertActionPath, ROUTES } from 'configs/routes';
import userIcon from 'assets/user-icon.svg';
import { useAppDispatch, useAppSelector } from 'hooks';
import { sxStyles } from './ConfirmExpert.styles';

export function ConfirmExpert() {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();
  const { consultantId } = useParams();
  const dispatch = useAppDispatch();
  const consultantDetail = useAppSelector(getConsultantDetailSelector);
  const authDate = useAppSelector(getUserFromAuthStore);
  const currentUserData: any = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (authDate.id && authDate.role) {
      dispatch(
        getUserByIdAndRole({
          userId: authDate.id,
          userRole: authDate.role,
        })
      );
    }
  }, [authDate]);

  useEffect(() => {
    dispatch(getConsultantDetails(consultantId as string));
  }, []);

  const handleConfirmExpert = async () => {
    const result = await dispatch(confirmExpert({
      consultantUserId: consultantId
    }));

    if (result.payload.error) {
      enqueueSnackbar(t('commonError'), {
        variant: 'error',
      });
    } else {
      const path = getSuccessExpertActionPath('confirm-expert');

      navigate(path);
    }
  };

  const handleBack = () => {
    navigate(`/${ROUTES.expertList}`);
  };

  const renderCommunicatedField = () => {
    if (!currentUserData) {
      return null;
    }

    let fieldValue = currentUserData.user.phone;

    if (currentUserData.communicationChannel === 'email') {
      fieldValue = currentUserData.user.email;
    }

    return (
      <TextField
        disabled
        sx={{ my: 2 }}
        size="small"
        fullWidth
        label={currentUserData.communicationChannel}
        value={fieldValue}
        name="communicationChannel"
      />
    );
  };

  const editProfilePath = `${getEditUserProfilePath(authDate.id, authDate.role)}/${consultantDetail?.user?.id}`;

  const breadcrumbsList = [
    {
      link: '/client/expert-list',
      title: t('consultantList'),
      isDisabled: false
    },
    {
      link: '',
      title: t('confirmSelection'),
      isDisabled: false
    }
  ];

  const imageSrc = consultantDetail?.photoId ?
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    `${ROLE_URLS[consultantDetail.user.role]}/${consultantDetail.user.id}/profile-picture`
    : userIcon;

  return (
    <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
      <Grid item xs={12} sm={8}>
        <BreadcrumbsComponent items={breadcrumbsList} />
      </Grid>

      <Grid item sm={8}>
        <Typography variant="h4" component="h1" sx={{ mb: '2rem' }}>
          {t('titleConfirmExpertPage')}
        </Typography>

        <Paper sx={sxStyles.contentBox}>
          <Typography variant="body1" component="div" fontWeight={600}>
            {t('selectedConfirmExpert')}:
          </Typography>

          <Box sx={sxStyles.consultantBox}>
            <Avatar
              sx={sxStyles.avatar}
              src={imageSrc}
            />

            <Typography
              variant="h6"
              component="span"
              fontWeight={300}
            >
              {consultantDetail?.firstName}
              &nbsp;
              {consultantDetail?.lastName}
            </Typography>
          </Box>

          <Typography variant="body1" component="div" sx={{ mb: '1rem' }} fontWeight={600}>
            {t('whatsNext')}
          </Typography>

          <Typography
            variant="body1"
            sx={sxStyles.textInfo}
          >
            {t('confirmExpertDetails')}
          </Typography>

          <Typography variant="body1" component="div" fontWeight={600}>
            {t('communicationMethodToYou')}:
          </Typography>

          {renderCommunicatedField()}

          <Typography
            variant="body1"
            sx={sxStyles.textInfo}
          >
            {t('communicationMethodInfo')}
          </Typography>

          <Typography
            variant="body2"
            sx={sxStyles.textInfo}
            component="span"
          >
            {t('wrongCommunicationMethod')}
          </Typography>

          <Link
            sx={sxStyles.changeContact}
            component={RouterLink}
            to={editProfilePath}
          >
            {t('changeContact')}
          </Link>

          <Box sx={{ mt: '1.5rem' }}>
            <Button
              sx={{ marginRight: '1rem' }}
              onClick={handleConfirmExpert}
              variant="contained"
              color="primary"
            >
              {t('confirm')}
            </Button>

            <Button variant="outlined" onClick={handleBack}>{t('back')}</Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
