import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getClientConsultantRelations, getClientInfo, getClientRequestsApi, getClientsList, getConsultantClientsList, getConsultantInfo, getConsultantsList } from 'feature/relations/relationsService';

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  clientConsultantList: {},
  consultantCurrentClients: [],
  totalClients: 0,
  unassignedClientsList: [],
  consultantInfo: [],
  unassignedConsultantsList: [],
  clientInfo: []
};

export const searchClientConsultantList = createAsyncThunk(
  'user/search-client-consultant',
  async (data: any, thunkAPI) => {
    try {
      return await getClientConsultantRelations(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getConsultantCurrentClients = createAsyncThunk(
  'user/get-consultant-clients',
  async (data: any, thunkAPI) => {
    try {
      return await getConsultantClientsList(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getUnassignedClientsList = createAsyncThunk(
  'user/get-unassigned-clients',
  async (data, thunkAPI) => {
    try {
      return await getClientsList();
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getConsultantData = createAsyncThunk(
  'user/get-consultant-info',
  async (id: string, thunkAPI) => {
    try {
      return await getConsultantInfo(id);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getUnassignedConsultantsList = createAsyncThunk(
  'user/get-unassigned-consultants-list',
  async (data, thunkAPI) => {
    try {
      return await getConsultantsList();
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getClientData = createAsyncThunk(
  'user/get-client-info',
  async (data: any, thunkAPI) => {
    try {
      return await getClientInfo(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getClientRequests = createAsyncThunk(
  'user/get-client-requests',
  async (data: void, thunkAPI) => {
    try {
      return await getClientRequestsApi();
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const usersSlice = createSlice({
  name: 'clientConsultant',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchClientConsultantList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchClientConsultantList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.clientConsultantList = action.payload;
      })
      .addCase(searchClientConsultantList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.clientConsultantList = [];
      })
      .addCase(getConsultantCurrentClients.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConsultantCurrentClients.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.consultantCurrentClients = action.payload.data;
        state.totalClients = action.payload.total;
      })
      .addCase(getConsultantCurrentClients.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.consultantCurrentClients = [];
        state.totalClients = 0;
      })
      .addCase(getUnassignedClientsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnassignedClientsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.unassignedClientsList = action.payload;
      })
      .addCase(getUnassignedClientsList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.unassignedClientsList = [];
      })
      .addCase(getConsultantData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConsultantData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.consultantInfo = action.payload;
      })
      .addCase(getConsultantData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.consultantInfo = [];
      })
      .addCase(getUnassignedConsultantsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUnassignedConsultantsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.unassignedConsultantsList = action.payload;
      })
      .addCase(getUnassignedConsultantsList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.unassignedConsultantsList = [];
      })
      .addCase(getClientData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clientInfo = action.payload;
      })
      .addCase(getClientData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.clientInfo = [];
      })
      .addCase(getClientRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientRequests.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.clientConsultantList = [];
      })
      .addCase(getClientRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.clientConsultantList = { data: action.payload, total: action.payload.length };
      });
  },
});

export const { reset } = usersSlice.actions;
export default usersSlice.reducer;
