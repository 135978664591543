import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';

export default function ConfirmationDialog({
  open,
  closeHandler,
  okHandler,
  dialogContentText,
  confirmLabel,
  cancelLabel
}: any) {
  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>{dialogContentText}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: 'center', }}
      >
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={okHandler}
          autoFocus
        >
          {confirmLabel}
        </Button>
        <Button fullWidth variant="outlined" onClick={closeHandler}>
          {cancelLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  okHandler: PropTypes.func.isRequired,
  closeHandler: PropTypes.func.isRequired,
  dialogContentText: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
};
