import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
} from '@mui/material';
import {
  updateClientConsultantRelation,
  deleteClientConsultantRelation,
  getConsultantsList,
  mapConsultantsToOptions,
} from 'feature/relations/relationsService';
import ConfirmationDialog from 'components/modals/ConfirmationDialog';
import { getClientData } from 'feature/relations/relationsSlice';
import { getClientInfoFromRelationsStore } from 'feature/relations/relationsSelectors';
import { useAppDispatch } from 'hooks';
import AsyncSingleSelect, { Option } from 'components/fields/AsyncSingleSelect';
import { formatApiPhoneStr } from 'utils';
import * as styles from './EditRelations.styles';

export default function EditClient() {
  const { clientId } = useParams();
  const [newConsultantId, setNewConsultantId] = useState('');
  const setSelectedOption = useCallback((option: Option | null) => setNewConsultantId(option?.value || ''), []);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedUserId, setDeletedUserId] = useState('');
  const [userDeleted, setUserDeleted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const client = useSelector(
    getClientInfoFromRelationsStore
  );

  const getClientInfo = () => {
    dispatch(getClientData(clientId));
  };

  useEffect(() => {
    getClientInfo();
    setUserDeleted(false);
  }, [userDeleted, newConsultantId]);
  const updateRelation = () => {
    updateClientConsultantRelation(
      {
      clientUserId: clientId,
      consultantUserId: newConsultantId
    }).then(() => setNewConsultantId(''));
  };

  const deleteConsultant = (id: any) => {
    setOpenDeleteModal(true);
    setDeletedUserId(id);
  };
  const deleteConsultantHandler = () => {
    deleteClientConsultantRelation(deletedUserId)
      .then(() => {
        enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
        setUserDeleted(true);
      })
      .catch(() => {
        enqueueSnackbar(t('commonError'), { variant: 'error' });
      });
    setOpenDeleteModal(false);
  };
  return (
    <Box component="div">
      <Grid sx={styles.headerContainer}>
        <Grid>
          <Typography fontWeight={700} variant="h5">{client?.firstName || ''} {client?.lastName || ''}</Typography>
          <Typography fontWeight={700} variant="h5">{t('currentConsultant')}</Typography>
        </Grid>
      </Grid>
      <Grid sx={styles.tableHeaderContainer}>
        <Grid container sx={styles.tableHeader}>
          <Grid width="250px">
            <Typography variant="h6" fontWeight={600} fontSize="1.0rem">
              {t('consultant')}
            </Typography>
          </Grid>
          <Grid width="250px">
            <Typography variant="h6" fontWeight={600} fontSize="1.0rem">
              {t('phone')}
            </Typography>
          </Grid>
          <Grid width="250px">
            <Typography variant="h6" fontWeight={600} fontSize="1.0rem">
              {t('startWorkingDate')}
            </Typography>
          </Grid>
        </Grid>
        {client?.consultant ? (
          <Grid
            key={client.consultant.user.id}
            container
            sx={styles.relationsContainer}
          >
            <Grid width="250px">
              <Typography>
                {client.consultant.firstName} {client.consultant.lastName}
              </Typography>
            </Grid>
            <Grid width="250px">
              <Typography>
                {formatApiPhoneStr(client.consultant?.user?.phone)}
              </Typography>
            </Grid>
            <Grid width="250px">
              <Typography>
                {new Date(client.consultantStartDate).toLocaleDateString('fr-CH')}
              </Typography>
            </Grid>
            <Grid>
              <Button
                variant="contained"
                type="submit"
                onClick={() => deleteConsultant(client.user.id)}
              >
                {t('untie')}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid sx={styles.selectContainer}>
            <Grid>
              <FormControl sx={styles.selectStyles}>
                <AsyncSingleSelect
                  id="chooseConsultant"
                  fieldLabel={t('chooseConsultant')}
                  noOptionsText={t('noConsultantsFound')}
                  fetchRequest={(queryParams, fetchOptions) => getConsultantsList(
                    { state: ['Active'], ...queryParams }, fetchOptions)}
                  mapResponseToOptions={mapConsultantsToOptions}
                  liftOptionUp={setSelectedOption}
                />
              </FormControl>
            </Grid>
            <Grid sx={styles.submitBtnStyles}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                onClick={updateRelation}
                disabled={!newConsultantId.length}
              >
                {t('add')}
              </Button>
            </Grid>
          </Grid>
        )}
        <ConfirmationDialog
          open={openDeleteModal}
          okHandler={deleteConsultantHandler}
          closeHandler={() => setOpenDeleteModal(false)}
          dialogContentText={t('areYouReallyWantToChange')}
          confirmLabel={t('yes')}
          cancelLabel={t('no')}
        />
        <Grid sx={styles.backBtnContainer(true)}>
          <Button
            sx={styles.backBtnStyles}
            variant="contained"
            type="submit"
            fullWidth
            onClick={() => navigate('/admin/clients')}
          >
            {t('back')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
