import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Paper } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from 'assets/logo.svg';

export function SuccessExpertAction() {
  const { t } = useTranslation(['common']);
  const { action } = useParams();

  const renderText = () => {
    if (action === 'confirm-expert') {
      return t('successConfirmExpert');
    } if (action === 'decline-expert') {
      return t('successDeclineExpert');
    }
      return '';
  };

  return (
    <Paper sx={{
      display: 'flex',
      alignItems: 'center',
      margin: '3rem auto 0',
      maxWidth: '760px',
      width: '100%',
      height: '70vh',
    }}
    >
      <Box sx={{
        maxWidth: '420px',
        textAlign: 'center',
        margin: '0 auto',
      }}
      >
        <img width="260px" height="150px" src={logo} alt="Logo" />

        <Typography variant="body1" component="div" sx={{ mt: '3rem', mb: '1.5rem' }}>
          {renderText()}
        </Typography>

        <Button variant="contained" component={RouterLink} to="/">
          {t('backToMain')}
        </Button>
      </Box>
    </Paper>
  );
}
