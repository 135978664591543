import { createSlice } from '@reduxjs/toolkit';

type AppState = {
  usersListFilterState: null | { [key: string]: string };
};

const initialState: AppState = {
  usersListFilterState: null,
};

export const appStateSlice = createSlice({
  name: 'app-state',
  initialState,
  reducers: {
    saveUsersListFilter: (state, { payload }) => {
      state.usersListFilterState = payload;
    },
  },
});

export const { saveUsersListFilter } = appStateSlice.actions;

export default appStateSlice.reducer;
