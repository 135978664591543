import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, List } from '@mui/material';

import { getUserByIdAndRole } from 'feature/user/userSlice';
import { getUserFromAuthStore } from 'feature/auth/authSelectors';
import { getClientInfoPath } from 'configs/routes';
import { getConsultantClientsList } from 'feature/consultants/consultantsSlice';
import { getConsultantClientListSelector, getIsLoadingConsultantSelector } from 'feature/consultants/consultantsSelector';
import Spinner from 'components/shared/Spinner';
import { useAppDispatch } from 'hooks';

import { ListItem } from './components/ListItem';
import { sxStyles } from './ClientList.styles';

export function ClientList() {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authData = useSelector(getUserFromAuthStore);
  const { newClients, oldClients } = useSelector(getConsultantClientListSelector);
  const isLoading = useSelector(getIsLoadingConsultantSelector);
  useEffect(() => {
    if (authData.id && authData.role) {
      dispatch(
        getUserByIdAndRole({
          userId: authData.id,
          userRole: authData.role,
        })
      );
      dispatch(getConsultantClientsList(authData.id));
    }
  }, [authData]);
  if (isLoading) {
    return (
      <Spinner />
    );
  }
  const handleClientDetails = (client: any) => {
    navigate(getClientInfoPath(client.user.id));
  };
  const renderList = (title: any, list: any[] = []) => {
    if (!list.length) {
      return null;
    }
    return (
      <>
        <Typography
          variant="body1"
          component="div"
          sx={sxStyles.listTitle}
        >
          {t(title)}:
        </Typography>

        <List sx={sxStyles.listItem}>
          {list.map((client) => (
            <ListItem key={client.user.id} client={client} onClick={() => handleClientDetails(client)} />
          ))}
        </List>
      </>
    );
  };
  const renderAllLists = () => {
    if (!newClients.length && !oldClients.length) {
      return (
        <Box sx={sxStyles.emptyList}>
          <Typography variant="h6" component="p" align="center">
            {t('noClients')}
          </Typography>
        </Box>
      );
    }
    return (
      <>
        {renderList('newClientTitle', newClients)}
        {renderList('oldClientTitle', oldClients)}
      </>
    );
  };
  return (
    <Grid container sx={sxStyles.container} spacing={2}>
      <Grid item xs={12} sm={7}>
        <Grid container spacing={2}>
          <Typography sx={{ mb: '2rem' }} variant="h4" component="h1">
            {t('consultantClientListTitle')}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {renderAllLists()}
        </Grid>
      </Grid>
    </Grid>
  );
}
