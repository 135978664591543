import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { BreadCrumbsStyles } from './Breadcrumbs.styles';

export default function BreadcrumbsComponent({
  items
}: any) {
  return !isEmpty(items) ? (
    <Stack spacing={2} sx={BreadCrumbsStyles}>
      <Breadcrumbs separator="•" aria-label="breadcrumb">
        {
          items.map(({
            title,
            link
          }: any, index: any) => {
            // Last item in list is current page, so it must be string, not link
            if (index === items.length - 1) {
              return (
                <Typography key={title} color="text.primary">
                  {title}
                </Typography>
              );
            }
            return (
              <Link underline="hover" key={title} color="inherit" href={link}>
                {title}
              </Link>
            );
          })
        }
      </Breadcrumbs>
    </Stack>
  ) : null;
}

BreadcrumbsComponent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string
    })).isRequired
};
