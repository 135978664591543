import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import {
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { confirmCode, resetPassword } from 'feature/user/userService';
import { PASSWORD_REGEX } from 'configs/validation/regex';
import { VALIDATION_ERROR_MESSAGES } from 'configs/validation/errorMessages';
import formStyles from 'components/forms/Form.styles';
import { fancyTimeFormat, hiddenPhoneRegExp } from 'configs/helper';
import { useCountDown } from 'hooks/useCountDown';
import { FormStepTitle, PasswordInputAdornment, PrimaryBtn } from 'components/forms/RestorePassword/FormStepComponents';
import { requestPasswordReset } from 'feature/user/userSlice';
import { useAppDispatch } from 'hooks';

const REQUEST_PASSWORD_COUNTDOWN_SECONDS = 61;

function getOneTimeCodeValidationSchema(t: Function) {
  return Yup.object().shape({
    code: Yup.string()
      .required(t('validationRequired')),
  });
}

function getPasswordChangeValidationSchema(t: Function) {
  return Yup.object().shape({
    password: Yup.string()
      .required(VALIDATION_ERROR_MESSAGES.password.required)
      .matches(PASSWORD_REGEX, VALIDATION_ERROR_MESSAGES.password.matches),
    confirmPassword: Yup.string()
      .required(VALIDATION_ERROR_MESSAGES.password.required)
      .oneOf([Yup.ref('password'), null], t('passwordShouldMatch')),
  });
}

type StepProps = {
  phone: string;
  moveToNextStep: Function;
}

export default function ChangePasswordFormStep({ phone, moveToNextStep }: StepProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmationCodeError, setConfirmationCodeError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common']);
  const { clearCountDown, countDown, startCountDown, setCountDown } = useCountDown(phone, REQUEST_PASSWORD_COUNTDOWN_SECONDS);
  const { enqueueSnackbar } = useSnackbar();
  const oneTimeCodeFormik = useFormik({
    initialValues: { code: '', },
    validationSchema: getOneTimeCodeValidationSchema(t),
    onSubmit: () => {},
  });
  const passwdChangeFormik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: getPasswordChangeValidationSchema(t),
    onSubmit: async (data) => {
      const { code } = oneTimeCodeFormik.values;
      if (!code) {
        return;
      }
      setIsLoading(true);
      let codeValidationFailed = false;
      try {
        await confirmCode({ code, phone, });
        setConfirmationCodeError('');
      } catch (e) {
        setConfirmationCodeError(t('codeIsNotCorrectTryAgain'));
        codeValidationFailed = true;
      }
      try {
        if (!codeValidationFailed) {
          await resetPassword({
            phone,
            code,
            password: data.password,
          });
          moveToNextStep();
          enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
        }
      } catch (e) {
        enqueueSnackbar(t('commonError'), {
          variant: 'error',
        });
      }
      setIsLoading(false);
    }
  });
  const sendAgain = () => {
    dispatch(requestPasswordReset(phone));
    clearCountDown();
    setCountDown(REQUEST_PASSWORD_COUNTDOWN_SECONDS);
    startCountDown();
  };

  // We specifically split this form into two (one time code form, password change form) to avoid issues with autocomplete
  return (
    <div>
      <form
        style={formStyles.form}
        autoComplete="off"
      >
        <FormStepTitle
          stepHeader={t('enterCoderAndTheNewPassword')}
          stepDescription={`${t('confirmCodeSubTitleRestorePassword1')} ${hiddenPhoneRegExp(phone)} ${t('confirmCodeSubTitle2')}`}
        />
        <TextField
          autoFocus
          autoComplete="one-time-code"
          sx={{ pb: 3 }}
          fullWidth
          id="code"
          name="code"
          label={t('code')}
          type="text"
          value={oneTimeCodeFormik.values.code}
          onChange={(...args) => {
            setConfirmationCodeError('');
            oneTimeCodeFormik.handleChange(...args);
          }}
          error={(oneTimeCodeFormik.touched.code && Boolean(oneTimeCodeFormik.errors.code)) || !isEmpty(confirmationCodeError)}
          helperText={(oneTimeCodeFormik.touched.code && oneTimeCodeFormik.errors.code) || confirmationCodeError}
          onBlur={oneTimeCodeFormik.handleBlur}
        />
      </form>
      <form
        onSubmit={passwdChangeFormik.handleSubmit}
        style={formStyles.form}
        autoComplete="off"
      >
        <TextField
          sx={{ pb: 3 }}
          fullWidth
          autoComplete="new-password"
          id="password"
          name="password"
          label={t('password')}
          type={showPassword ? 'text' : 'password'}
          value={passwdChangeFormik.values.password}
          onChange={passwdChangeFormik.handleChange}
          error={passwdChangeFormik.touched.password && Boolean(passwdChangeFormik.errors.password)}
          helperText={passwdChangeFormik.touched.password && passwdChangeFormik.errors.password}
          onBlur={passwdChangeFormik.handleBlur}
          InputProps={{
            endAdornment: (
              <PasswordInputAdornment
                isPasswordVisible={showPassword}
                togglePasswordVisibility={setShowPassword}
              />
            ),
          }}
        />
        <TextField
          sx={{ pb: 3 }}
          fullWidth
          autoComplete="new-password"
          id="confirmPassword"
          name="confirmPassword"
          label={t('confirmPassword')}
          type={showConfirmPassword ? 'text' : 'password'}
          value={passwdChangeFormik.values.confirmPassword}
          onChange={passwdChangeFormik.handleChange}
          error={passwdChangeFormik.touched.confirmPassword && Boolean(passwdChangeFormik.errors.confirmPassword)}
          helperText={passwdChangeFormik.touched.confirmPassword && passwdChangeFormik.errors.confirmPassword}
          onBlur={passwdChangeFormik.handleBlur}
          InputProps={{
            endAdornment: (
              <PasswordInputAdornment
                isPasswordVisible={showConfirmPassword}
                togglePasswordVisibility={setShowConfirmPassword}
              />
            ),
          }}
        />
        <PrimaryBtn btnContent={t('changePassword')} disabled={isLoading} />
      </form>
      <Box display="flex" justifyContent="center">
        {countDown === 0 &&
        <Typography variant="body2" sx={{ mt: 3 }}>
          {t('gotWrongCode')}
        </Typography>}
        <Button
          onClick={sendAgain}
          disabled={countDown > 0}
          sx={{
            mt: 2,
            fontSize: 14,
            background: 'transparent',
            textTransform: 'none',
            color: countDown > 0 ? '#AAA9AE' : '#B14E24'
          }}
        >
          {countDown > 0 ? `${t('sendOneMoreTime2')} ${fancyTimeFormat(countDown)}` : t('sendAgain')}
        </Button>
      </Box>
    </div>
  );
}
ChangePasswordFormStep.propTypes = {
  phone: PropTypes.string.isRequired,
  moveToNextStep: PropTypes.func.isRequired,
};
