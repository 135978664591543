import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import formStyles from 'components/forms/Form.styles';
import PhoneInput from 'components/shared/PhoneInput';
import { VALIDATION_ERROR_MESSAGES } from 'configs/validation/errorMessages';
import ConfirmCodeForm from 'components/forms/ConfirmCodeForm';
 import { requestPhoneUpdate, updatePhone } from 'feature/user/userService';

export default function UpdatePhoneDialog({
                                             open,
                                             closeHandler,
                                             okHandler,
                                             userId = ''
                                           }: {
  open: boolean,
  closeHandler: () => void,
  okHandler: (val: string) => void,
  userId?: string
}) {
  const [phoneScreen, setPhoneScreen] = useState(true);
  const [code, setCode] = useState('');
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();

  const closeHandlerWrapper = () => {
    closeHandler();
    setPhoneScreen(true);
  };

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .required(VALIDATION_ERROR_MESSAGES.phone.required)
      .length(12, t('validationPhoneFormat')),
  });

  const formik = useFormik({
    initialValues: {
      phone: '',
    },
    validationSchema,
    onSubmit: async (data) => {
      await requestPhoneUpdate(data)
        .then(() => {
          enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
          setPhoneScreen(false);
        })
        .catch((err: any) => {
          enqueueSnackbar(err.data.message === 'Bad Request' ?
            t('wrongPhoneNumber') : t('tryAgain'),
            { variant: 'error' });
        });
    }
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setTouched
  } = formik;

  const cancelEdit = () => {
    setFieldValue('phone', '');
    setTouched({}, false);
    closeHandlerWrapper();
  };

  const handleUpdatePhone = (verificationCode: string, cb: Function) => {
    updatePhone({ phone: values.phone, verificationCode }, userId)
      .then(() => {
        enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
        okHandler(values.phone);
        closeHandlerWrapper();
      })
      .catch((err: any) => {
        enqueueSnackbar(t('wrongCode'), { variant: 'error' });
        setPhoneScreen(false);
        cb();
      });
  };

  return (
    <Dialog
      open={open}
      onClose={cancelEdit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      {phoneScreen ?
        <form onSubmit={handleSubmit}>
          <DialogTitle>{t('enterNewPhoneNumber')}</DialogTitle>
          <DialogContent sx={{ pt: '20px !important' }}>
            <PhoneInput
              onBlur={handleBlur}
              sx={formStyles.input}
              fullWidth
              label={t('newPhoneNumber')}
              value={values.phone}
              id="phone"
              name="phone"
              onChange={handleChange}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
            />
            <DialogActions sx={{ p: 0 }}>
              <Button fullWidth variant="outlined" onClick={cancelEdit} size="large">
                {t('cancel2')}
              </Button>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                autoFocus
                type="submit"
                size="large"
              >
                {t('more')}
              </Button>
            </DialogActions>
          </DialogContent>
        </form> :
        <>
          <DialogTitle>{t('enterCoder')}</DialogTitle>
          <DialogContent>
            <Typography variant="body2" sx={{ pb: 2, color: '#AAA9AE' }}>
              {t('confirmCodeSubHeader')}
            </Typography>
            <ConfirmCodeForm phone={values.phone} setCode={setCode} isUpdatePhone handleUpdatePhone={handleUpdatePhone} cancelEdit={cancelEdit} />
          </DialogContent>
        </>}
    </Dialog>
  );
}

UpdatePhoneDialog.defaultProps = {
  userId: ''
};
