const ClientMediaStyles = (theme: any) => ({
  display: 'flex',
  boxShadow: 'none',
  borderRadius: '12px',

  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'block',
  }
});

const ClientProfileImgStyles = {
  borderRadius: '12px',
  height: '300px',
};

const ClientCardContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem'
};

const ClientAboutContentStyles = {
  color: '#00000099',
  mb: 0
};

export {
  ClientMediaStyles,
  ClientProfileImgStyles,
  ClientCardContentStyles,
  ClientAboutContentStyles,
};
