import React from 'react';
import { Box } from '@mui/material';
import logo from 'assets/login-logo.svg';
import { Container, FormBox, LogoBox } from './styles';

export default function LoginWrapper({ children }: any) {
  return (
    <Box sx={Container}>
      <Box sx={FormBox}>
        {children}
      </Box>

      <Box sx={LogoBox}>
        <img src={logo} alt="logo" />
      </Box>
    </Box>
  );
}
