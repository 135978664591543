import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { useSnackbar } from 'notistack';
import { updateUserPassword, resetPasswordState } from 'feature/user/userSlice';
import { PASSWORD_REGEX } from 'configs/validation/regex';
import { VALIDATION_ERROR_MESSAGES } from 'configs/validation/errorMessages';
import formStyles from 'components/forms/Form.styles';
import { useSelector } from 'react-redux';
import { getUserFromUserStore } from 'feature/user/userSelectors';
import { logout } from 'feature/auth/authSlice';
import { useAppDispatch } from 'hooks';

export default function EditPasswordForm() {
  const [showOldPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required(VALIDATION_ERROR_MESSAGES.password.required)
      .matches(PASSWORD_REGEX, VALIDATION_ERROR_MESSAGES.password.matches),
    password: Yup.string()
      .required(VALIDATION_ERROR_MESSAGES.password.required)
      .matches(PASSWORD_REGEX, VALIDATION_ERROR_MESSAGES.password.matches),
  });
  const userStore = useSelector(getUserFromUserStore);
  const dispatch = useAppDispatch();

  useEffect(() => () => {
    dispatch(resetPasswordState());
  }, []);
  useEffect(() => {
    if (!userStore.isLoadingPasswordUpdate && userStore.isErrorPasswordUpdate) {
      enqueueSnackbar(t('commonError'), { variant: 'error' });
    } else if (!userStore.isLoadingPasswordUpdate && userStore.isSuccessPasswordUpdate) {
      enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
      dispatch(logout());
    }
  }, [
    userStore.isErrorPasswordUpdate,
    userStore.isSuccessPasswordUpdate,
    userStore.isLoadingPasswordUpdate
  ]);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    resetForm,
  } = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (data) => {
      dispatch(updateUserPassword(data));
      resetForm({
        values: {
          oldPassword: '',
          password: '',
        },
      });
    },
  });

  return (
    <form onSubmit={handleSubmit} style={formStyles.form}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('password')}
      </Typography>

      <TextField
        autoComplete="current-password"
        size="small"
        sx={{ mb: 2 }}
        fullWidth
        id="oldPassword"
        name="oldPassword"
        label={t('oldPassword')}
        type={showOldPassword ? 'text' : 'password'}
        value={values.oldPassword}
        onChange={handleChange}
        error={touched.oldPassword && !!errors.oldPassword}
        helperText={touched.oldPassword && errors.oldPassword}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showOldPassword)}
                onMouseDown={() => setShowPassword(!showOldPassword)}
              >
                {showOldPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        autoComplete="new-password"
        size="small"
        sx={{ mb: 2 }}
        fullWidth
        id="password"
        name="password"
        label={t('newPassword')}
        type={showNewPassword ? 'text' : 'password'}
        value={values.password}
        onChange={handleChange}
        error={touched.password && !!errors.password}
        helperText={touched.password && errors.password}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowConfirmPassword(!showNewPassword)}
                onMouseDown={() => setShowConfirmPassword(!showNewPassword)}
              >
                {showNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Grid mb={2} container justifyContent="flex-end">
        <Button
          disabled={
            Object.keys(errors).length > 0 ||
            !values.password ||
            !values.oldPassword
          }
          aria-disabled={
            Object.keys(errors).length > 0 ||
            !values.password ||
            !values.oldPassword
          }
          color="primary"
          variant="contained"
          type="submit"
        >
          {t('save')}
        </Button>
      </Grid>
    </form>
  );
}
