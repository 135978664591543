import { Router } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRef, useState, useLayoutEffect, MutableRefObject } from 'react';
import { BrowserHistory } from 'history';
import { history } from '../../../history';

// TODO check if we need this Component
function CustomBrowserRouter({
  basename,
  children
}: any) {
  const historyRef = useRef() as MutableRefObject<BrowserHistory>;
  if (historyRef && !historyRef.current) {
    historyRef.current = history;
  }
  const newHistory = historyRef.current;
  const [state, setState] = useState({
    action: newHistory.action,
    location: newHistory.location
  });

  useLayoutEffect(() => newHistory.listen(setState), [newHistory]);

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={newHistory}
    >
      {children}
    </Router>
  );
}

CustomBrowserRouter.propTypes = {
  basename: PropTypes.string,
  children: PropTypes.node.isRequired
};
CustomBrowserRouter.defaultProps = { basename: '', };

export default CustomBrowserRouter;
