import { request } from '../../api/client';
import URLS from '../../api/urls';

// Login user
const login = async (userData: any) => {
  const response = await request({
    url: URLS.LOGIN,
    method: 'POST',
    data: userData,
  });
  return response;
};

// Logout user
const logout = async () => {
  await request({
    url: URLS.LOGOUT,
    method: 'POST',
  });
};

// Client SignUp
const signUpClient = async (data: any) => {
  await request({
    url: URLS.CLIENT_SIGN_UP,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const authService = {
  logout,
  login,
  signUpClient
};

export default authService;
