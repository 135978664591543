import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = React.forwardRef((props: any, ref) => {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        props.onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      format="+##(###)###-##-##"
      mask="_"
      placeholder="+xx(xxx)xxx-xx-xx"
    />
  );
});

NumberFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default function PhoneInput(props: any) {
  return (
    <TextField
      autoComplete="off"
      InputProps={{ inputComponent: NumberFormatCustom, }}
      {...props}
    />
  );
}
