import { CircularProgress, Box } from '@mui/material';
import React from 'react';

export default function Spinner() {
  return (
    <Box
      sx={{
        margin: 'auto',
        width: 'max-content',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
