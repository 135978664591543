import { isProduction } from '../configs';

export const API_URL = isProduction ? `${process.env.REACT_APP_API_URL}/api/v1` : '/api/v1';

const URLS = {
  LOGIN: `${API_URL}/login`,
  CLIENT_SIGN_UP: `${API_URL}/clients/sign-up`,
  REGISTER: `${API_URL}/register`,
  REQUEST_PASSWORD_RESET: `${API_URL}/users/request-password-reset`,
  CONFIRM_PASSWORD_RESET: `${API_URL}/users/confirm-password-reset`,
  REQUEST_SIGN_UP_CODE: `${API_URL}/users/request-sign-up-code`,
  CONFIRM_SIGN_UP_CODE: `${API_URL}/users/confirm-sign-up-code`,
  REQUEST_PHONE_UPDATE: `${API_URL}/users/request-phone-update`,
  USERS: `${API_URL}/users`,
  PASSWORD_RESET: `${API_URL}/users/password-reset`,
  LOGOUT: `${API_URL}/logout`,
  SEARCH_USERS: `${API_URL}/users`,
  REFERRAL_LINK: `${API_URL}/users/referral-link`,
  REFERRAL_LINK_STATUS: `${API_URL}/users/referral-link-status`,
  REQUESTS: `${API_URL}/users-requests`,
  REQUESTS_CHANGE_CONSULTANT: `${API_URL}/users-requests/client/change-consultant`,
  DEACTIVATE_USER: `${API_URL}/users-requests/deactivate-user`,
  REQUESTS_REQUEST_CONSULTANT: `${API_URL}/users-requests/client/request-consultant`,
  REQUESTS_CLIENT: `${API_URL}/users-requests/client`,
  SEARCH_CLIENT_CONSULTANT: `${API_URL}/relations/client-consultant`,
  RELATIONS_CLIENT: `${API_URL}/relations/clients`,
  RELATIONS_CONSULTANT: `${API_URL}/relations/consultants`,
  CONFIRM_CLIENT_CONSULTANT_RELATIONS: `${API_URL}/relations/client-consultant/client`,
  RELATIONS_CLIENT_AUTOCOMPLETE: `${API_URL}/clients/autocomplete/unassigned`,
  CONSULTANTS_LIST: `${API_URL}/consultants`,
  CHANGE_USER_PASSWORD: `${API_URL}/users/password`,
};
export const ROLE_URLS = {
  Admin: `${API_URL}/admins`,
  Coordinator: `${API_URL}/coordinators`,
  Consultant: `${API_URL}/consultants`,
  Client: `${API_URL}/clients`,
};

export default URLS;
