import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import { Button, TextField, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { REGISTRATION_SUB_PAGES } from 'configs';
import { useCountDown } from 'hooks/useCountDown';
import { fancyTimeFormat } from 'configs/helper';
import { requestPhoneUpdate, requestSignUpCode, confirmSignUpCode } from 'feature/user/userService';
import formStyles from 'components/forms/Form.styles';

export default function ConfirmCodeForm({
  setCode,
  phone,
  cancelEdit,
  setActiveSubPage,
  isUpdatePhone,
  handleUpdatePhone
}: any) {
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();
  const { clearCountDown, countDown, startCountDown, setCountDown } =
    useCountDown(phone, 60);

  const [showError, setShowError] = useState(false);

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required(t('validationRequired'))
      .length(6, t('notCorectCodeFormat')),
  });
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } =
    useFormik({
      initialValues: { code: '' },
      validationSchema,
      onSubmit: async (data) => {
        if (isUpdatePhone) {
          handleUpdatePhone(data.code, () => setShowError(true));
        } else {
          confirmSignUpCode({
            code: data.code,
            phone
          })
            .then(() => {
            setCode(data.code);
            enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
            setActiveSubPage(REGISTRATION_SUB_PAGES.privacy);
            })
          .catch((err: any) => {
            enqueueSnackbar(t('wrongCode'), { variant: 'error' });
            setShowError(t('wrongCode'));
          });
        }
      }
    });

  const updateCodeHandler = (handler: any, counter: number) => {
    handler({ phone })
      .then(() => {
        clearCountDown();
        setCountDown(counter);
        startCountDown();
      })
      .catch(() => {
        enqueueSnackbar(t('commonError'), { variant: 'error' });
      });
  };

  const requestSignUpCodeOneMore = () => updateCodeHandler(requestSignUpCode, 60);

  const requestCodePhoneUpdate = () => updateCodeHandler(requestPhoneUpdate, 60);

  return (
    <>
      <form onSubmit={(e: any) => { e.preventDefault(); handleSubmit(); }} style={!isUpdatePhone ? formStyles.form : {}}>
        <TextField
          autoComplete="one-time-code"
          FormHelperTextProps={{ sx: formStyles.helper }}
          sx={{ pb: 3 }}
          fullWidth
          id="code"
          name="code"
          label={t('code')}
          type="text"
          value={values.code}
          onChange={(e) => {
            handleChange(e);
            setShowError(false);
          }}
          error={(touched.code && Boolean(errors.code)) || showError}
          helperText={(touched.code && errors.code) || (showError && t('wrongCode'))}
          onBlur={handleBlur}
        />
        {isUpdatePhone ?
          <Box sx={{ display: 'flex', gap: '20px' }}>
            <Button fullWidth variant="outlined" onClick={cancelEdit} size="large">
              {t('cancel2')}
            </Button>
            <Button
              disabled={!values.code || !isEmpty(errors)}
              fullWidth
              variant="contained"
              color="primary"
              autoFocus
              size="large"
              type="submit"
            >
              {t('changePhone')}
            </Button>
          </Box> :
          <Button color="primary" variant="contained" fullWidth type="submit" size="medium">
            {t('confirm')}
          </Button>}
      </form>
      <Box display="flex" justifyContent="center">
        {countDown === 0 &&
        <Typography variant="body2" sx={{ mt: 3 }}>
          {t('gotWrongCode')}
        </Typography>}
        <Button
          onClick={isUpdatePhone ? requestCodePhoneUpdate : requestSignUpCodeOneMore}
          disabled={countDown > 0}
          sx={{
            mt: 2,
            fontSize: 14,
            background: 'transparent',
            textTransform: 'none',
            color: countDown > 0 ? '#AAA9AE' : '#B14E24'
          }}
        >
          {countDown > 0 ? `${t('sendOneMoreTime2')} ${fancyTimeFormat(countDown)}`
          : t('sendAgain')}
        </Button>
      </Box>
    </>
  );
}
ConfirmCodeForm.propTypes = {
  setCode: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setActiveSubPage: PropTypes.func,
  handleUpdatePhone: PropTypes.func,
  isUpdatePhone: PropTypes.bool,
  cancelEdit: PropTypes.func
};

ConfirmCodeForm.defaultProps = {
  isUpdatePhone: false,
  setActiveSubPage: () => {},
  handleUpdatePhone: () => {},
  cancelEdit: () => {}
};
