import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from '@mui/material';
import SearchInput from './SearchInput';

function TableHeaderPanel({
  header,
  rowsCount,
  searchValue,
  setSearchValue,
  setDebouncedSearch,
  setPaginationValues,
  children,
  showSearch
}: any) {
  const setDebounced = useCallback(
    debounce((value) => {
      setDebouncedSearch(value);
    }, 500),
    []
  );
  const searchHeandler = (value: any) => {
    setSearchValue(value);
    setPaginationValues.forEach((setValue: any) => {
      setValue(0);
    });
    setDebounced(value);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ margin: '5px' }}>
        <h2 data-testid="title">{header}</h2>
        {rowsCount && <span data-testid="title">Всього {rowsCount} </span>}
      </div>

      <div
        style={{
          marginTop: '35px',
          display: 'flex',
          maxWidth: '400px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {showSearch && (
          <SearchInput value={searchValue} searchHandler={searchHeandler} />
        )}
        {children}
      </div>
    </div>
  );
}

TableHeaderPanel.propTypes = {
  header: PropTypes.string.isRequired,
  setDebouncedSearch: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  setPaginationValues: PropTypes.arrayOf(PropTypes.func).isRequired,
  rowsCount: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showSearch: PropTypes.bool,
  searchValue: PropTypes.string,
};

TableHeaderPanel.defaultProps = {
  children: null,
  rowsCount: null,
  showSearch: true,
  searchValue: '',
};

export default TableHeaderPanel;
