import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getClient } from './clientsService';

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  clientData: {},
};

export const getClientData = createAsyncThunk(
  'clients/get-client',
  async (id: string, thunkAPI) => {
    try {
      return await getClient(id);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);
export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clientData = action.payload;
      })
      .addCase(getClientData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        // TODO: check if this property should be there
        // @ts-ignore
        state.request = {};
      });
  },
});

export const { reset } = clientsSlice.actions;
export default clientsSlice.reducer;
