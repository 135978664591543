import { Button as ButtonMui, Autocomplete as AutocompleteMui } from '@mui/material';
import styled from '@emotion/styled';

export const ChipStyles = {
    backgroundColor: '#F9D0BD',
    color: '#000',
    marginLeft: 0
};

export const ChipContainerStyles = {
    mb: 3,
    minHeight: '35px',
    flexWrap: 'wrap',
    gap: '10px'
};

export const TitleStyles = {
    fontWeight: 600,
    mb: 1,
    fontSize: '1rem',
};

export const DeleteButton = styled(ButtonMui)`
  background-color: #D32F2F;
  &:hover {
    background-color: #D32F2F;
  }
`;

export const OutlineButton = styled(ButtonMui)`
  color: #B14E24;
  border-color: #B14E24;
  &:hover {
    border-color: #B14E24
  }
`;

export const Autocomplete = styled(AutocompleteMui)`
  .MuiAutocomplete-tag {
      background-color: #F9D0BD;
  }
`;

export const SelectChipStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
};

export const ExtraButtonBox = (theme: any) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    right: '-75px',

    [theme.breakpoints.down('md')]: {
        right: '-55px'
    },

    [theme.breakpoints.down('sm')]: {
        right: '-45px'
    }
});

export const ExtraButton = (theme: any) => ({
    height: '56px',
    padding: '0 15px',

    [theme.breakpoints.down('md')]: {
        minWidth: '40px',
        padding: '0 10px',
    },

    [theme.breakpoints.down('sm')]: {
        minWidth: '40px',
        padding: '0 5px',
    }
});
