const LogoBox = (theme: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  flex: '0 0 33.333%'
});

const FormBox = {
  flex: '1 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1.5rem',
};

const Container = (theme: any) => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column-reverse',

    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        minHeight: '100vh',
        display: 'flex',
    }
});

export {
    LogoBox,
    FormBox,
    Container
};
