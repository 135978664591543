import React, { useEffect, useState } from 'react';
import { TextField, Stack, Chip, AutocompleteRenderGetTagProps, AutocompleteRenderInputParams } from '@mui/material';
import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import uniqWith from 'lodash.uniqwith';
import { getSpecializations } from 'feature/common/commonSlice';
import { getSpecializationsSelector } from 'feature/common/commonSelector';
import { Autocomplete } from './styles';

type NameOption = { name: string };

function stringsEqualIgnoreCase(a: string, b: string) {
  return a.toLowerCase() === b.toLowerCase();
}

function getSpecializationIfExists(strings: string[], str: string) {
  const index = strings.findIndex((item) => stringsEqualIgnoreCase(item, str));
  if (index !== -1) {
    return strings[index];
  }
  return str;
}

function optionsToStringArray(newValues: (string | NameOption)[], allSpecialization: readonly NameOption[]) {
  const allNames = allSpecialization.map(({ name }) => name);
  return newValues.map((item: NameOption | string) => {
    if (typeof item === 'string') {
      return item;
    }
    return item.name;
  })
    .filter((name) => !isEmpty(name))
    .map((name) => name.trim())
    .filter((name) => name.length > 0)
    .map((name) => getSpecializationIfExists(allNames, name));
}

function uniqIgnoreCase(values: string[]) {
  return uniqWith(values, stringsEqualIgnoreCase);
}

type Props = {
  specialisations: string[],
  handleChange: (value: string[]) => void,
  error: string,
};

export default function SpecializationInput({ specialisations, handleChange, error }: Props) {
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const { data: allSpecializations }: { data: readonly NameOption[] } = useSelector(getSpecializationsSelector);
  useEffect(() => {
    dispatch(getSpecializations(''));
  }, []);
  const getSelectedSpecializations = (): NameOption[] => specialisations.map((name: string) => ({ name }));
  const [values, setValues] = useState<readonly NameOption[]>(getSelectedSpecializations());
  useEffect(() => {
    setValues(getSelectedSpecializations());
  }, [specialisations]);
  const updateSpecialization = (event: React.SyntheticEvent, newValues: (NameOption | string)[]) => {
    setShowError(true);
    const uniqueValues: string[] = uniqIgnoreCase(optionsToStringArray(newValues, allSpecializations));
    handleChange(uniqueValues);
    setValues(uniqueValues.map((name) => ({ name })));
  };
  const renderTags = (values: NameOption[], getTagProps: AutocompleteRenderGetTagProps) => values.map(({ name }, index) => (
    <Chip
      variant="outlined"
      label={name}
      {...getTagProps({ index })}
    />
  ));
  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={t('specialisation')}
      error={showError && !!error}
      helperText={showError && error}
    />
  );
  const isOptionEqToValueIgnoreCase = (option: NameOption | string, value: NameOption) => {
    if (typeof option === 'string') {
      return stringsEqualIgnoreCase(option, value.name);
    }
    return stringsEqualIgnoreCase(option.name, value.name);
  };
  return (
    <Stack spacing={3}>
      <Autocomplete
        multiple
        id="tags-outlined"
        value={values}
        options={allSpecializations}
        // @ts-ignore
        getOptionLabel={({ name }: NameOption) => name}
        freeSolo
        // @ts-ignore
        renderTags={renderTags}
        // @ts-ignore
        isOptionEqualToValue={isOptionEqToValueIgnoreCase}
        filterSelectedOptions
        renderInput={renderInput}
        // @ts-ignore
        onChange={updateSpecialization}
        sx={{ mb: 2 }}
      />
    </Stack>
  );
}
