import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import LoginForm from 'components/forms/LoginForm';
import LoginWrapper from 'components/shared/LoginWrapper';

import { ROUTES } from '../configs/routes';

export default function Login({
  isLoggedAdmin,
  isClient,
  isLoading,
  isConsultant
}: any) {
  const navigate = useNavigate();
  const location = useLocation();
  /*
    Functionality that allows to save and return back to "last" active url in case of logout,
    that may happen f.e. because of session end, manual log out, page reload etc
   */
  useEffect(() => {
    // Checks for logged admin/coordinator
    if (isLoggedAdmin && !isLoading) {
      let originURL;
      // If original url was not login
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (location.state?.from?.pathname !== '/login') {
        /*
          We potentially can logout from client and login to admin panel, login from new tab or after page reload
          We use "!/admin/" check instead of "/client/" because admin urls may contain smth like "admin/clients/" string and condition will be true, that will cause an issue.
        */
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        originURL = (!/admin/.test(location.state?.from?.pathname) || !location.state?.from?.pathname)
          ? '/admin/users'
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          : location.state?.from?.pathname;
      }
      navigate(originURL);

      return;
    }
    // Checks for logged client
    if (isClient) {
      let originURL;
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (location.state?.from?.pathname !== '/login') {
        // We potentially can logout from admin and login to client panel, login from new tab or after page reload
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        originURL = (/admin/.test(location.state?.from?.pathname) || /consultant/.test(location.state?.from?.pathname) || !location.state?.from?.pathname)
          ? `/${ROUTES.expertList}`
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          : location.state?.from?.pathname;
      }
      navigate(originURL);

      return;
    }

    if (isConsultant) {
      let originURL;

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (location.state?.from?.pathname !== '/login') {
        // We potentially can logout from admin and login to client panel, login from new tab or after page reload
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        originURL = (/client/.test(location.state?.from?.pathname) || /admin/.test(location.state?.from?.pathname) || !location.state?.from?.pathname)
          ? `/${ROUTES.clientList}`
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          : location.state?.from?.pathname;
      }

      navigate(originURL);
    }
  }, [isLoggedAdmin, isClient, isConsultant]);

  return (
    <LoginWrapper>
      <LoginForm />
    </LoginWrapper>
  );
}

Login.propTypes = {
  isLoggedAdmin: PropTypes.bool,
  isClient: PropTypes.bool,
  isLoading: PropTypes.bool,
  isConsultant: PropTypes.bool,
};

Login.defaultProps = {
  isLoggedAdmin: false,
  isClient: false,
  isLoading: false,
  isConsultant: false,
};
