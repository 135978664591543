import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const ButtonResponsive = styled(Button)((props) => ({
  [props.theme.breakpoints.down('sm')]: {
    paddingLeft: '8px',
    paddingRight: '8px'
  }
}));

const BrandButton = styled(ButtonResponsive)({
  color: '#fff',
  backgroundColor: '#83639E',
});
const CancelButton = styled(ButtonResponsive)({
  color: '#fff',
  backgroundColor: '#D32F2F',
  '&:hover': {
    backgroundColor: '#D32F2F'
  }
});
const OutlineButton = styled(ButtonResponsive)({
  color: '#83639E',
  border: '1px solid #83639E',
});

const ExpertProfileContentStyles = (theme: any) => ({
  flex: '1 0 auto',

  [theme.breakpoints.up('md')]: {
    paddingRight: '0px',
  }
});

const ConsultantMediaStyles = (theme: any) => ({
  display: 'flex',
  width: '100%',
  boxShadow: 'none',
  borderRadius: '12px',
  mb: '16px',

  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'block',
  }
});

const ConsultantPictureStyles = (theme: any) => ({
  maxWidth: 240,

  [theme.breakpoints.down('md')]: {
    width: 'auto',
    margin: '16px auto 0'
  }
});

const ExpertAboutTitleStyles = {
  fontWeight: 600,
  mb: 1,
  fontSize: '1rem'
};

const ExpertAboutContentStyles = {
  color: '#00000099',
  mb: 1,
  WebkitLineClamp: 4,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  wordWrap: 'break-word',
};

const ExpertProfileImgStyles = {
  borderRadius: '12px',
  height: '305px',
};

export {
  BrandButton,
  CancelButton,
  OutlineButton,
  ConsultantMediaStyles,
  ConsultantPictureStyles,
  ExpertAboutTitleStyles,
  ExpertAboutContentStyles,
  ExpertProfileContentStyles,
  ExpertProfileImgStyles,
};
