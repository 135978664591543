import { getClientRequestsList, removeClientRequest, processClientRequest } from 'feature/clientRequests/clientRequestsSlice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { ORDER_BY } from 'components/utils/constants';
import { useAppDispatch, useAppSelector } from 'hooks';
import { UserRequest } from 'models/user';
import { RequestType } from 'enums/request-type.enum';
import { TableMenuItem } from 'enums/table-menu-item.enum';
import { STATUS_TYPES } from 'configs/index';
import { ITEMS_ON_PAGE } from '../configs/index';
import Table from '../components/Table';
import TableHeaderPanel from '../components/TableHeaderPanel';
import { history } from '../history';

const tableColumnsSettings = [
  {
    name: 'sender',
    key: 'username',
    isSortable: true,
  },
  {
    name: 'role',
    key: 'userRole',
    isSortable: true,
  },
  {
    name: 'type',
    key: 'type',
    isSortable: true,
  },
  {
    name: 'status',
    key: 'status',
    isSortable: true,
  },
  {
    name: 'date',
    key: 'createdAt',
    isSortable: true,
  },
  {
    name: '',
    key: 'menu',
  },
];

function RequestList() {
  const { t } = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(window.location.search);

  const [currentPage, setCurrentPage] = useState(
    Number(queryParams.get('currentPage')) || 0
  );
  const [sortBy, setSortBy] = useState(
    queryParams.get('sortBy') || 'createdAt'
  );
  const [order, setOrder] = useState(queryParams.get('order') || 'DESC');
  const [debouncedSearch, setDebouncedSearch] = useState(
    queryParams.get('search') || ''
  );
  const [searchValue, setSearchValue] = useState(debouncedSearch);
  const [activeRowId, setActiveRowId] = useState(0);
  const { requests, total } = useAppSelector((state) => state.requests);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const removeRequest = (data: any) => {
    dispatch(removeClientRequest(data.id))
      .then(() => {
        dispatch(getClientRequestsList({
          limit: ITEMS_ON_PAGE,
          offset: currentPage * ITEMS_ON_PAGE,
          sortBy,
          order,
          search: debouncedSearch,
        }));
        enqueueSnackbar(t('commonSuccess', { variant: 'success' }));
      })
      .catch(() => enqueueSnackbar(t('commonError'), { variant: 'error' })
      );
  };

  const processRequest = (data: UserRequest, action: string) => {
    dispatch(processClientRequest({ id: data.id, action }));
  };

  useEffect(() => {
    dispatch(
      getClientRequestsList({
        limit: ITEMS_ON_PAGE,
        offset: currentPage * ITEMS_ON_PAGE,
        sortBy,
        order,
        search: debouncedSearch,
      })
    );
    navigate(
      {
        search: `${createSearchParams({
          sortBy,
          order,
          currentPage: String(currentPage),
          search: debouncedSearch,
        })}`,
      },
      { replace: true }
    );
  }, [currentPage, sortBy, order, debouncedSearch]);

  const hasNewStatus = (id: number) =>
    requests.find((request: UserRequest) => request.id === id && request.status === STATUS_TYPES.new);

  const sortFieldHandler = (sortField: any) => setSortBy(sortField);
  const orderHandler = () =>
    setOrder(order === ORDER_BY.ASC ? ORDER_BY.DESC : ORDER_BY.ASC);
  const menuItemsSettings = [
    {
      label: TableMenuItem.ViewDetails,
      callback: ({
        id,
        type
      }: { id: string, type: RequestType }) => {
        if ([RequestType.RequestConsultant, RequestType.ChangeConsultant, RequestType.Deactivate, RequestType.Delete].includes(type)) {
          history.push(`/admin/requests/${id}`);
        }
      },
    },
    ...(hasNewStatus(activeRowId) ? [{
      label: TableMenuItem.Agree,
      callback: (data: any) => processRequest(data, STATUS_TYPES.approve)
    }] : []),
    ...(hasNewStatus(activeRowId) ? [{
      label: TableMenuItem.Reject,
      callback: (data: any) => processRequest(data, STATUS_TYPES.reject),
    }] : []),
    {
      label: TableMenuItem.RemoveRequest,
      callback: removeRequest,
    }
  ];

  return (
    <>
      <TableHeaderPanel
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setDebouncedSearch={setDebouncedSearch}
        setPaginationValues={[setCurrentPage]}
        header="Запити"
      />
      <Table
        rows={requests}
        tableColumnsSettings={tableColumnsSettings}
        menuItemsSettings={menuItemsSettings}
        page={currentPage}
        totalRowsCount={total}
        limit={ITEMS_ON_PAGE}
        onPageChange={(page) => setCurrentPage(page)}
        onSortFieldChange={sortFieldHandler}
        order={order}
        sortBy={sortBy}
        setOrder={orderHandler}
        setActiveRowId={setActiveRowId}
      />
    </>
  );
}

export default RequestList;
