import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import { Checkbox, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const minTextLength = 10;
const maxTextLength = 1000;

type ReasonForm = {
  reason: string,
  notify: boolean;
}

type Props = {
  open: boolean;
  cancelHandler: () => void;
  okHandler: (arg: ReasonForm) => Promise<any>;
  inputLabel: string;
  dialogContentText: string | React.ReactNode;
  confirmLabel: string;
  cancelLabel: string;
};

export default function DialogWithReason({
  open,
  cancelHandler,
  okHandler,
  inputLabel,
  dialogContentText,
  confirmLabel,
  cancelLabel
}: Props) {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    reason: Yup.string()
      .required(t('validationRequired'))
      .min(minTextLength, t('deactivationTextMinLength', { minTextLength }))
      .max(maxTextLength, t('deactivationTextMaxLength', { maxTextLength })),
    notify: Yup.boolean()
  });
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    isValid,
    dirty,
    resetForm,
  } = useFormik({
    initialValues: { reason: '', notify: true },
    validationSchema,
    onSubmit: async (data, { resetForm }) => {
      setLoading(true);
      try {
        await okHandler({ reason: data.reason, notify: data.notify });
        resetForm();
      } catch (ignored) {
        //
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <Dialog
      open={open}
      onClose={cancelHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText sx={{ textAlign: 'center', marginBottom: '1rem' }}>
            {dialogContentText}
          </DialogContentText>
          <TextField
            fullWidth
            multiline
            minRows={2}
            maxRows={10}
            id="reason"
            name="reason"
            size="small"
            sx={{ margin: '8px 0' }}
            label={`${inputLabel} (${t('minimumN_symbols', { minTextLength })})`}
            required
            value={values.reason}
            onChange={handleChange}
            error={touched.reason && Boolean(errors.reason)}
            helperText={touched.reason && errors.reason}
            onBlur={handleBlur}
          />
          <FormControlLabel
            control={<Checkbox id="notify" name="notify" checked={values.notify} onChange={handleChange} onBlur={handleBlur} />}
            label={t('informUserAboutDeactivation')}
          />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'center', }}
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid || !dirty || loading}
          >
            {confirmLabel}
          </Button>
          <Button
            fullWidth
            autoFocus
            variant="outlined"
            onClick={() => {
              cancelHandler();
              resetForm();
            }}
          >
            {cancelLabel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

DialogWithReason.propTypes = {
  open: PropTypes.bool.isRequired,
  okHandler: PropTypes.func.isRequired,
  inputLabel: PropTypes.string.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  dialogContentText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  confirmLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
};
