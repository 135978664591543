import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import { responsiveFontSizes } from '@mui/material/styles';

import CustomBrowserRouter from './components/utils/router/CustomBrowserRouter';
import App from './App';
import { store } from './store';
import './i18';
import { theme as customTheme } from './theme';

const ANCHOR_OPTION: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};
const theme = responsiveFontSizes(customTheme);

ReactDOM.render(
  <React.StrictMode>
    <CustomBrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <SnackbarProvider
            anchorOrigin={ANCHOR_OPTION}
            maxSnack={3}
          >
            <App />
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    </CustomBrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
