import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import logo from 'assets/big-colored-logo.svg';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { PrimaryBtn } from 'components/forms/RestorePassword/FormStepComponents';
import { useNavigate } from 'react-router-dom';

const containerSx = {
  height: '70vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: '20px',
};

const textSx = { marginTop: '5em' };

const btnContainerSx = { marginTop: '2em' };

type Props = {
  children: React.ReactNode;
  displayBackToMainBtn?: boolean;
};

export default function DeletionSuccessPage({ displayBackToMainBtn, children }: Props) {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  return (
    <Box sx={containerSx}>
      <img src={logo} alt="logo" />
      <Typography component="h6" variant="h6" sx={textSx}>
        {children}
      </Typography>
      {displayBackToMainBtn
        ? (
          <Box sx={btnContainerSx}>
            <PrimaryBtn
              btnContent={t('backToMain')}
              onClick={() => navigate('/home')}
            />
          </Box>
        )
        : null}
    </Box>
  );
}
DeletionSuccessPage.proTypes = {
  children: PropTypes.node.isRequired,
  displayBackToMainBtn: PropTypes.bool,
};
DeletionSuccessPage.defaultProps = {
  displayBackToMainBtn: false,
};
