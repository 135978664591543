import { MutableRefObject, useEffect, useRef, useState } from 'react';

export const useCountDown = (phone: any, defaultCountdown = 60 * 3) => {
  const [countDown, setCountDown] = useState(defaultCountdown);

  const countDownRef = useRef() as MutableRefObject<number>;

  function updateCountDown() {
    setCountDown(countDown - 1);
  }
  const startCountDown = () => {
    countDownRef.current = window.setTimeout(() => {
      updateCountDown();
    }, 1000);
  };
  const clearCountDown = () => {
    clearTimeout(countDownRef.current);
  };

  useEffect(() => {
    if (phone) {
      startCountDown();
    }
    if (countDown === 0) {
      clearCountDown();
    }

    return () => {
      clearCountDown();
    };
  }, [countDown, phone]);

  return {
    countDown,
    clearCountDown,
    setCountDown,
    startCountDown,
  };
};
