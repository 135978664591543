import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { isProduction } from './configs';
import commonUa from './configs/translations/ua/common.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: !isProduction,
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: { ua: { common: commonUa } },
    lng: 'ua', // if you're using a language detector, do not define the lng option
    fallbackLng: 'ua',
    interpolation: { escapeValue: false },
    ns: ['common'],
    defaultNS: 'common',
  });
