import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography, TextField, Grid, Button, Link } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import formStyles from 'components/forms/Form.styles';
import { REGISTRATION_SUB_PAGES } from 'configs';
import { RegistrationCommonType } from 'pages/RegistrationPage/interface';

export default function ViolenceTypesForm({ values,
                                            errors,
                                            handleBlur,
                                            handleChange,
                                            setFieldValue,
                                            setActiveSubPage,
                                            validateForm,
}: RegistrationCommonType) {
  const { t } = useTranslation(['common']);

  const CHECKBOXES_LIST = [
    {
      name: 'Sexual',
      value: 'sexualViolence'
    },
    {
      name: 'Psychological',
      value: 'psychologicalViolence'
    },
    {
      name: 'Physical',
      value: 'physicalViolence'
    },
    {
      name: 'Other',
      value: 'otherViolence'
    }
  ];

  const KEYS = {
    other: 'Other',
    sexual: 'Sexual'
  };

  const handleCheck = (e: any, name: string) => {
    const tempViolence = [...values.violenceTypes];
    const index = tempViolence.indexOf(name);
    // eslint-disable-next-line no-unused-expressions
    index === -1 ? tempViolence.push(name) : tempViolence.splice(index, 1);

    setFieldValue('violenceTypes', tempViolence, true);
  };

  const navigateNext = () => {
    if (validateForm) validateForm();
    if (errors.other) return;
    if (values.other) {
      const tempViolence = [...values.violenceTypes];
      tempViolence.splice(tempViolence.indexOf(KEYS.other), 1, values.other);
      setFieldValue('violenceTypes', tempViolence, true);
    } else {
      setFieldValue('violenceTypes', values.violenceTypes, true);
    }
    setActiveSubPage(values.violenceTypes.includes(KEYS.sexual) ? REGISTRATION_SUB_PAGES.registration : REGISTRATION_SUB_PAGES.info);
  };

  return (
    <Box>
      <Typography variant="h5">
        {t('preRegistration')}
      </Typography>

      <Typography
        sx={(theme) => ({
          color: theme.palette.text.secondary,
          mb: 3
        })}
        variant="body2"
      >
        {t('preRegistrationSubText')}
      </Typography>
      <Typography
        sx={(theme) => ({
          color: theme.palette.text.primary,
          mb: 1
        })}
        variant="body2"
      >
        {t('selectViolence')}
      </Typography>

      <Grid container>
        <Grid item xs={12}>
          <FormGroup>
            {CHECKBOXES_LIST.map((violence) => (
              <FormControlLabel
                key={violence.name}
                control={<Checkbox />}
                label={t(violence.value)}
                value={violence.name}
                onChange={(e) => handleCheck(e, violence.name)}
              />)
            )}
          </FormGroup>
          {values.violenceTypes.includes(KEYS.other) &&
          <TextField
            id="other"
            name="other"
            variant="outlined"
            sx={formStyles.input}
            fullWidth
            value={values.other}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.other}
            helperText={errors.other}
          />}
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
        <Link href="/login">
          <Button>{t('iHaveAccount')}</Button>
        </Link>
        <Button onClick={navigateNext}>{t('more')}<ArrowForwardIcon sx={{ fontSize: '18px' }} /></Button>
      </Box>
    </Box>
  );
}
