import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RouterLink } from 'components/RouterLink';
import { getConfirmExpertPath } from 'configs/routes';

const btnStyle = {
  position: 'fixed',
  top: '40px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1500,
};

export function BackToConfirmExpertBtn() {
  const { t } = useTranslation(['common']);
  const { consultantId } = useParams();

  if (!consultantId) {
    return null;
  }

  const backPath = getConfirmExpertPath(consultantId);

  return (
    <Button variant="contained" sx={btnStyle} color="info" component={RouterLink} to={backPath}>
      {t('backToConfirmExpert')}
    </Button>
  );
}
