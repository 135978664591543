import React, { useState } from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';
import { VALIDATION_ERROR_MESSAGES } from 'configs/validation/errorMessages';
import formStyles from 'components/forms/Form.styles';

export default function DatePicker({
                      label,
                      value,
                      setFieldValue,
                      isValidated,
                      error }: {
  label: string,
  value: Date,
  setFieldValue: (name: string, e: any, shouldValidate: boolean) => void;
  isValidated: boolean,
  error: boolean
}) {
  const [showError, setShowError] = useState(false);

  const handleDate = (value: string | null) => {
    setShowError(true);
    if (value !== null) {
      setFieldValue('birthday', new Date(value), true);
    } else {
      setFieldValue('birthday', value, true);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        disableFuture
        label={label}
        inputFormat="DD.MM.YYYY"
        value={value}
        data-testid="birthday"
        // @ts-expect-error TS(2322)
        id="birthday"
        name="birthday"
        onBlur={handleDate}
        onChange={handleDate}
        renderInput={({ error: inputError, ...params }) => (
          <TextField
            {...params}
            error={(showError || isValidated) && (inputError || error)}
            helperText={(showError || isValidated) && (inputError || error) && VALIDATION_ERROR_MESSAGES.birthday.required}
            sx={formStyles.input}
            fullWidth
            required
          />
        )}
      />
    </LocalizationProvider>
  );
}
