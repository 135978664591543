import React, { useState, useEffect } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Button } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TableColumnSettings } from 'models/table-column-settings.model';
import { TableMenuItem } from 'enums/table-menu-item.enum';
import { User } from 'models/user';
import { ViolenceType } from 'enums/violence-type.enum';
import DialogWithReason from 'components/modals/DialogWithReason';
import { deactivateUserById, deleteUserById } from 'feature/user/userService';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import { saveUsersListFilter } from 'feature/state/appStateSlice';
import { searchUsers } from 'feature/users/usersSlice';
import { getUsersFromUserStore } from 'feature/users/usersSelectors';
import { Asc, Desc, LIMIT_USER_LIST, ORDER_BY } from 'components/utils/constants';
import Table from '../components/Table';
import TableHeaderPanel from '../components/TableHeaderPanel';
import { history } from '../history';
import FiltersPanel from '../components/Filters/Filters';

export const tableColumnsSettings: TableColumnSettings[] = [
  {
    name: 'ID',
    key: 'externalId',
  },
  {
    name: 'firstName',
    key: 'firstName',
  },
  {
    name: 'lastName',
    key: 'lastName',
    isSortable: true,
  },
  {
    name: 'phone',
    key: 'phone',
  },
  {
    name: 'email',
    key: 'email',
    isSortable: true,
  },
  {
    name: 'role',
    key: 'role',
    isSortable: true,
  },
  {
    name: 'violenceType',
    key: 'violenceTypes',
    isSortable: true,
  },
  {
    name: 'signUpDate',
    key: 'signUpDate',
    isSortable: true,
  },
  {
    name: 'active',
    key: 'state',
    isSortable: true,
  },
  {
    name: '',
    key: 'menu',
  },
];

const defaultFilter = {
  role: [],
  gender: [],
  violenceType: [],
  state: []
};

const userListPage = () => {
  const [params] = useSearchParams();
  const { t } = useTranslation('common');
  const [currentPage, setCurrentPage] = useState(
    Number(params.get('currentPage')) || 0
  );
  const [sortBy, setSortBy] = useState(params.get('sortBy') || 'signUpDate');
  const [order, setOrder] = useState<Asc | Desc>(params.get('order') as any || 'DESC');
  const [offsetSkipUsers, setOffsetSkipUsers] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState(
    params.get('search') || ''
  );
  const [searchValue, setSearchValue] = useState(debouncedSearch);
  const filterHandler = () => setShowFilters(!showFilters);
  const filterState = useAppSelector((state) => state.appState.usersListFilterState || { ...defaultFilter, state: ['Active'] });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const users = useAppSelector(getUsersFromUserStore);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openHardDeleteModal, setOpenHardDeleteModal] = useState(false);
  const [userIdForAction, setUserIdForAction] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const menuItemsSettings = [
    {
      label: TableMenuItem.ReviewAndEdit,
      callback: ({
        id,
        role
      }: any) => {
        history.push(`/admin/users/edit-user/${role}/${id}`);
      },
    },
    {
      label: TableMenuItem.Deactivate,
      callback: ({ id, type, role, clientUserId, consultantUserId }: any) => {
        setUserIdForAction(id);
        setOpenDeactivateModal(true);
      },
    },
    {
      label: TableMenuItem.Remove,
      callback: ({ id }: any) => {
        setUserIdForAction(id);
        setSearchValue('');
        setDebouncedSearch('');
        setOpenHardDeleteModal(true);
      },
    },
  ];
  function refreshUsersList() {
    dispatch(searchUsers({
      ...filterState,
      sortBy,
      order,
      limit: LIMIT_USER_LIST,
      offset: offsetSkipUsers,
      search: debouncedSearch,
    }));
  }
  useEffect(() => {
    refreshUsersList();
    navigate(
      {
        search: `${createSearchParams({
          sortBy,
          order,
          limit: String(LIMIT_USER_LIST),
          offset: String(offsetSkipUsers),
          search: debouncedSearch,
        })}`,
      },
      { replace: true }
    );
  }, [sortBy, order, offsetSkipUsers, debouncedSearch]);
  const onPageChangeHandler = (page: any) => {
    setOffsetSkipUsers(LIMIT_USER_LIST * page);
    setCurrentPage(page);
  };
  const handleFilterChange = (filterState: any) => {
    dispatch(saveUsersListFilter(filterState));
  };
  const resetFilters = () => {
    dispatch(saveUsersListFilter(defaultFilter));
  };
  const sortFieldHandler = (sortField: any) => {
    const validKey = sortField === 'violenceTypes' ? 'violenceType' : sortField;
    setSortBy(validKey);
  };
  const orderHandler = () =>
    setOrder(order === ORDER_BY.ASC ? ORDER_BY.DESC : ORDER_BY.ASC);
  const mapUsers = (): User[] => {
    if (users?.data?.length) {
      return users.data.map((user: User) => ({
        ...user,
        violenceTypes: user.violenceTypes?.map((violenceType: ViolenceType) => {
          if (violenceType.toLowerCase() === 'other') {
            return t('otherViolenceType');
          }
          return t(violenceType.toLowerCase());
        }),
      }));
    }
    return users.data;
  };
  const sessionUserId = Cookies.get('id') as string;
  const deactivateUser = async ({ reason, notify }: any) => deactivateUserById({
      requesterUserId: sessionUserId,
      deactivatedUserId: userIdForAction,
      reason,
      notify
    })
      .then(() => {
        enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
        setOpenDeactivateModal(false);
        setUserIdForAction('');
        refreshUsersList();
      })
      .catch((e) => {
        enqueueSnackbar(t('commonError'), { variant: 'error' });
        throw e;
      });
  const hardDeleteUser = async ({ notify, reason }: { notify: boolean, reason?: string }) => deleteUserById({
      requesterUserId: sessionUserId,
      deleteUserId: userIdForAction,
      notify,
      reason,
    })
    .then(() => {
      enqueueSnackbar(t('commonSuccess'), { variant: 'success' });
      setOpenHardDeleteModal(false);
      setUserIdForAction('');
      refreshUsersList();
    })
    .catch((e) => {
      enqueueSnackbar(t('commonError'), { variant: 'error' });
      throw e;
    });
  const handleCancel = () => {
    setOpenDeactivateModal(false);
    setOpenHardDeleteModal(false);
    setUserIdForAction('');
  };
  return (
    <>
      <TableHeaderPanel
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setDebouncedSearch={setDebouncedSearch}
        setPaginationValues={[setCurrentPage, setOffsetSkipUsers]}
        header="Користувачі"
        rowsCount={users.total || null}
      >
        <Button
          variant="contained"
          size="medium"
          onClick={() => filterHandler()}
          sx={{ ml: 4, mr: 4, pl: 2, pr: 2 }}
        >
          {t('filter')}
        </Button>
        {showFilters && (
          <div>
            <FiltersPanel
              sortBy={sortBy}
              order={order}
              offset={offsetSkipUsers}
              limit={LIMIT_USER_LIST}
              onResetFilters={resetFilters}
              onFilterChange={handleFilterChange}
              filterState={filterState}
              onFilterSubmitCallback={filterHandler}
            />
          </div>
        )}
      </TableHeaderPanel>
      <Table
        rows={mapUsers()}
        tableColumnsSettings={tableColumnsSettings}
        page={currentPage}
        menuItemsSettings={menuItemsSettings}
        totalRowsCount={users.total}
        limit={LIMIT_USER_LIST}
        onPageChange={(page) => onPageChangeHandler(page)}
        onSortFieldChange={sortFieldHandler}
        sortBy={sortBy}
        order={order}
        setOrder={orderHandler}
      />
      <DialogWithReason
        key="deactivateDialog"
        open={openDeactivateModal}
        okHandler={deactivateUser}
        inputLabel={t('addDeactivationReason')}
        cancelHandler={handleCancel}
        dialogContentText={t('deactivateDialogText')}
        confirmLabel={t('deactivate')}
        cancelLabel={t('cancel')}
      />
      <DialogWithReason
        key="hardDeleteDialog"
        open={openHardDeleteModal}
        okHandler={hardDeleteUser}
        cancelHandler={handleCancel}
        inputLabel={t('addDeletionReason')}
        dialogContentText={(
          <Trans
            ns="common"
            i18nKey="hardDeleteUserAccountDescription"
            components={[<strong />, <strong />]}
          />
        )}
        confirmLabel={t('deleteUserAccountAndPersonalDate')}
        cancelLabel={t('cancel')}
      />
    </>
  );
};

export default userListPage;
