import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { DeleteButton } from 'components/shared/styles';
import { deactivateUserById } from 'feature/user/userService';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import isEmpty from 'lodash.isempty';
import { DialogTitle } from '@mui/material';

function SoftDeleteUserDialogContent() {
  const { t } = useTranslation(['common']);
  // TODO: email should not be hardcoded. Think of a way to get it from the API.
  const requestAccountDeletionEmail = 'avrora.help.ua@gmail.com';
  return (
    <>
      {`${t('softDeleteUserAccountDescription', { projectName: t('projectName'), delete: t('delete') })} `}
      <Trans
        ns="common"
        i18nKey="howToRemoveAllPersonalData"
        values={{ requestAccountDeletionEmail }}
        components={[<strong />]}
      />
    </>
  );
}

const btnProps: any = {
  fullWidth: true,
  size: 'large',
  sx: { mr: 2, mb: 2 },
};

const fontWeightAndColor: any = {
  color: '#000000',
  fontWeight: 500,
};

type Props = {
  userId?: string;
  open: boolean;
  navigateTo: string;
  closeHandler: () => void;
  deleteBtnText: React.ReactNode;
}

/**
 * Soft self delete user modal dialog (aka user deactivation).
 * This component is designed for the users that what no deactivate <b>their own</b> profile.
 * @param userId - the ID of the user to be deleted
 * @param open - determines whether display the modal
 * @param closeHandler - callback that handles cancel event
 * @param navigateTo - the path of the page that should be opened on success
 * @param deleteBtnText - content of the delete button
 */
export default function SoftDeleteUserAccount({ userId, open, closeHandler, navigateTo, deleteBtnText }: Props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common']);
  if (!open || isEmpty(userId)) {
    return null;
  }
  const onSoftDeleteClick = async () => {
    setLoading(true);
    try {
      await deactivateUserById({
        deactivatedUserId: userId || '',
        notify: true,
      });
      setLoading(false);
      closeHandler();
      navigate(navigateTo);
    } catch (e) {
      enqueueSnackbar(t('commonError'), { variant: 'error' });
      setLoading(false);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          ...fontWeightAndColor,
          fontSize: {
            xs: '1.5rem',
            md: '1.5rem',
            lg: '1.5rem',
        },
      }}
      >
        {t('softDeleteUserAccount')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ ...fontWeightAndColor }}>
          <SoftDeleteUserDialogContent />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          padding: '16px 24px',
        }}
      >
        <Button
          {...btnProps}
          variant="outlined"
          autoFocus
          onClick={closeHandler}
        >
          {t('cancel2')}
        </Button>
        <DeleteButton
          {...btnProps}
          variant="contained"
          color="primary"
          onClick={onSoftDeleteClick}
          disabled={loading}
        >
          {deleteBtnText}
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
}
SoftDeleteUserAccount.propTypes = {
  userId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  navigateTo: PropTypes.string.isRequired,
  deleteBtnText: PropTypes.node.isRequired,
};
SoftDeleteUserAccount.defaultProps = {
  userId: '',
};
