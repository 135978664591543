import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getConsultantClientListApi, getConsultants } from './consultantsService';
import { getConsultantInfo } from './consultantDetail';

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  consultantsList: [],
  clientList: [],
};

// Get consultants list
export const getConsultantsList = createAsyncThunk(
  'consultants/consultants-list',
  async (_, thunkAPI) => {
    try {
      return await getConsultants();
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getConsultantDetails = createAsyncThunk(
  'consultants',
  async (data: string, thunkAPI) => {
    try {
      return await getConsultantInfo(data);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getConsultantClientsList = createAsyncThunk(
  'consultant/client-list',
  async (id: string, thunkAPI) => {
    try {
      return await getConsultantClientListApi(id);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const consultantsSlice = createSlice({
  name: 'consultants',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
    clearConsultantDetails: (state) => {
      // TODO: check if this is needed
      // @ts-ignore
      state.consultantDetail = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConsultantsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConsultantsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.consultantsList = action.payload;
      })
      .addCase(getConsultantsList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.consultantsList = [];
      })
      .addCase(getConsultantDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConsultantDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // TODO: check if this is needed
        // @ts-ignore
        state.consultantDetail = action.payload;
      })
      .addCase(getConsultantDetails.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        // TODO: check if this is needed
        // @ts-ignore
        state.consultantDetail = {};
      })
      .addCase(getConsultantClientsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConsultantClientsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.clientList = action.payload.data;
      })
      .addCase(getConsultantClientsList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.clientList = [];
      });
  },
});

export const { reset, clearConsultantDetails } = consultantsSlice.actions;
export default consultantsSlice.reducer;
