import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Button, Paper, TextField } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { getConsultantDetails } from 'feature/consultants/consultantDetailSlice';
import BreadcrumbsComponent from 'components/Breadcrumbs/Breadcrumbs';
import { getConsultantDetailSelector } from 'feature/consultants/consultantDetailSelector';
import { ROLE_URLS } from 'api/urls';
import { declineExpert, getUserByIdAndRole } from 'feature/user/userSlice';
import { getUserFromAuthStore } from 'feature/auth/authSelectors';
import { getExpertInfoPath, getSuccessExpertActionPath } from 'configs/routes';
import userIcon from 'assets/user-icon.svg';
import { useAppDispatch } from 'hooks';

import { sxStyles } from './DeclineExpert.styles';

export function DeclineExpert() {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const { consultantId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const consultantDetail = useSelector(getConsultantDetailSelector);
  const authDate = useSelector(getUserFromAuthStore);

  useEffect(() => {
    if (authDate.id && authDate.role) {
      dispatch(
        getUserByIdAndRole({
          userId: authDate.id,
          userRole: authDate.role,
        })
      );
    }
  }, [authDate]);

  useEffect(() => {
    dispatch(getConsultantDetails(consultantId as string));
  }, []);

  const handleDecline = async (date: any) => {
    const result = await dispatch(declineExpert(date));

    if (result.payload.error) {
      enqueueSnackbar(t('commonError'), {
        variant: 'error',
      });
    } else {
      const path = getSuccessExpertActionPath('decline-expert');

      navigate(path);
    }
  };

  const handleBack = () => {
    navigate(getExpertInfoPath(consultantId));
  };

  const {
    values,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      message: ''
    },
    onSubmit: handleDecline
  });

  const breadcrumbsList = [
    {
      link: getExpertInfoPath(consultantId),
      title: t('consultantProfile'),
      isDisabled: false
    },
    {
      link: '',
      title: t('declinedReason'),
      isDisabled: false
    }
  ];

  const imageSrc = consultantDetail?.photoId ?
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    `${ROLE_URLS[consultantDetail.user.role]}/${consultantDetail.user.id}/profile-picture`
    : userIcon;

  return (
    <Grid container sx={{ justifyContent: 'center' }} spacing={2}>
      <Grid item xs={12} sm={8}>
        <BreadcrumbsComponent items={breadcrumbsList} />
      </Grid>

      <Grid item sm={8}>
        <Typography variant="h4" component="h1" sx={{ mb: '2rem' }}>
          {t('titleDeclinedExpert')}
        </Typography>

        <Paper sx={sxStyles.contentBox}>
          <Typography variant="body1" component="div" fontWeight={600}>
            {t('declinedExpertSubtitle')}:
          </Typography>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            margin: '1rem 0 1.5rem'
          }}
          >
            <Avatar
              sx={{
                width: 80,
                height: 80
              }}
              src={imageSrc}
            />

            <Typography
              variant="h6"
              component="span"
              fontWeight={300}
            >
              {consultantDetail?.firstName}
              &nbsp;
              {consultantDetail?.lastName}
            </Typography>
          </Box>

          <Typography variant="body1" component="div" sx={{ mb: '1rem' }} fontWeight={600}>
            {t('declinedReason')}:
          </Typography>

          <Typography
            variant="body1"
            sx={sxStyles.textInfo}
          >
            {t('declineReasonDetails')}
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              name="message"
              label={t('declineLabelField')}
              onChange={handleChange}
              value={values.message}
              rows={8}
              maxRows={8}
              fullWidth
              multiline
            />

            <Typography variant="caption" component="div" sx={{ margin: '0.5rem 0 2rem' }}>
              {values.message.length}
            </Typography>

            <Button
              type="submit"
              disabled={values.message.length <= 40}
              sx={{ marginRight: '1rem' }}
              variant="contained"
              color="primary"
            >
              {t('confirmDecline')}
            </Button>

            <Button variant="outlined" onClick={handleBack}>{t('back')}</Button>
          </form>

        </Paper>
      </Grid>
    </Grid>
  );
}
