export const sxStyles = {
  listTitle: {
    fontWeight: 600,
    mb: '0.75rem'
  },
  listItem: {
    width: '100%',
    mb: '2rem',
  },
  emptyList: {
    borderRadius: '0.5rem',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    color: 'rgba(0, 0, 0, 0.26)',
    p: '1rem'
  },
  container: {
    justifyContent: 'center',
    mt: '4rem',
  }
};
