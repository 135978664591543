import React, { useState } from 'react';
import LoginWrapper from 'components/shared/LoginWrapper';
import EnterPhoneNumFormStep from 'components/forms/RestorePassword/EnterPhoneNumFormStep';
import ChangePasswordFormStep from 'components/forms/RestorePassword/ChangePasswordFormStep';
import PasswordChangedSuccessfullyStep from 'components/forms/RestorePassword/PasswordChangedSuccessfullyStep';

export default function RestorePassword() {
  const [stepIndex, setStepIndex] = useState(0);
  const [phone, setPhone] = useState('');
  const moveToNextStep = () => {
    setStepIndex((prevStep) => {
      // eslint-disable-next-line no-use-before-define
      if (stepIndex < getSteps().length - 1) {
        return prevStep + 1;
      }
      return prevStep;
    });
  };
  function getSteps() {
    return [
      <EnterPhoneNumFormStep key="enterPhoneStep" setPhone={setPhone} moveToNextStep={moveToNextStep} />,
      <ChangePasswordFormStep key="changePasswordStep" phone={phone} moveToNextStep={moveToNextStep} />,
      <PasswordChangedSuccessfullyStep key="successStep" />,
    ];
  }
  function getCurrentStep() {
    return getSteps()[stepIndex];
  }
  return (
    <LoginWrapper>
      {getCurrentStep()}
    </LoginWrapper>
  );
}
