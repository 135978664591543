import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRequestById } from 'feature/clientRequest/clientRequestService';
import { UserRole } from 'enums/user-role.enum';
import { getUser } from './clientRequestService';

interface RequestUserDto {
  id: string;
  userRole: UserRole;
}

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  request: {},
};

export const getClientRequest = createAsyncThunk(
  'request/get-client-request',
  async (id: string, thunkAPI) => {
    try {
      return await getRequestById(id);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getClientData = createAsyncThunk(
  'request/get-client',
  async (id: string, thunkAPI) => {
    try {
      return await getUser(id, UserRole.Client);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getDeactivatorData = createAsyncThunk(
  'request/get-deactivator',
  async ({ id, userRole }: RequestUserDto, thunkAPI) => {
    try {
      return await getUser(id, userRole);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const getDeactivatedUserData = createAsyncThunk(
  'request/get-deactivated-user',
  async ({ id, userRole }: RequestUserDto, thunkAPI) => {
    try {
      return await getUser(id, userRole);
    } catch (error) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return thunkAPI.rejectWithValue(error.data);
    }
  }
);

export const requestsSlice = createSlice({
  name: 'client-request',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
    clearName: (state) => {
      // @ts-expect-error TS(2339): Property 'firstName' does not exist on type 'Writa... Remove this comment to see the full error message
      state.firstName = null;
      // @ts-expect-error TS(2339): Property 'lastName' does not exist on type 'Writab... Remove this comment to see the full error message
      state.lastName = null;
    },
    clearRequest: (state) => {
      state.request = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.request = action.payload;
      })
      .addCase(getClientRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.request = [];
      })
      .addCase(getClientData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // @ts-expect-error TS(2339): Property 'userFirstName' does not exist on type 'W... Remove this comment to see the full error message
        state.request.userFirstName = action.payload.firstName;
        // @ts-expect-error TS(2339): Property 'userLastName' does not exist on type 'Wr... Remove this comment to see the full error message
        state.request.userLastName = action.payload.lastName;
      })
      .addCase(getClientData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.request = [];
      })
      .addCase(getDeactivatedUserData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDeactivatedUserData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // @ts-expect-error TS(2339): Property 'userFirstName' does not exist on type 'W... Remove this comment to see the full error message
        state.request.deactivatedUser.userFirstName = action.payload.firstName;
        // @ts-expect-error TS(2339): Property 'userLastName' does not exist on type 'Wr... Remove this comment to see the full error message
        state.request.deactivatedUser.userLastName = action.payload.lastName;
      })
      .addCase(getDeactivatedUserData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.request = [];
      })
      .addCase(getDeactivatorData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDeactivatorData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // @ts-expect-error TS(2339): Property 'userLastName' does not exist on type 'Wr... Remove this comment to see the full error message
        state.request.userFirstName = action.payload.firstName;
        // @ts-expect-error TS(2339): Property 'userLastName' does not exist on type 'Wr... Remove this comment to see the full error message
        state.request.userLastName = action.payload.lastName;
      })
      .addCase(getDeactivatorData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        state.request = [];
      });
  },
});

export const { reset, clearName, clearRequest } = requestsSlice.actions;
export default requestsSlice.reducer;
