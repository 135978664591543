import axios from 'axios';

const client = axios.create({
  withCredentials: true,
});

export const { isCancel } = axios;

export const request = (options: any) => {
  const onSuccess = (response: any) => response.data;

  const onError = (err: any) => {
    if (isCancel(err)) {
      return Promise.reject(err);
    }
    return Promise.reject(err.response || err.message);
  };

  return client(options).then(onSuccess).catch(onError);
};
